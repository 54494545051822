import { Row } from "react-bootstrap";
import FundamentalCheck from "../../../RecommendedMore/components/FundamentalCheck/FundamentalCheck";
import RequirementCard from "../../../RecommendedMore/components/RequirementCard/RequirementCard";
import FeatureCard from "../../../RecommendedMore/components/FeatureCard/FeatureCard";
import "styled-components/macro";
import Modal from "../../../../components/Common/Modal";
import { useState } from "react";
import { CloseButton } from "../../../Cart/components/SidebarCart/SidebarCart";
import InfoImg from "../../../../assets/images/claim_settle.png";
import * as mq from "../../../../utils/mediaQueries";
import { ListItem } from "../../../ProductDetails/components/AddOnDetails/helpers";
import styled from "styled-components/macro";

const ImportantFeaturesWrap = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
  gap: 1.2em;
`;

const RequirementCardToolTip = styled.div`
  color: #757575;
  font-weight: 900;
`;

function averageClaimSettlement(claimSettlements = []) {
  const claimSettlementSum = claimSettlements.reduce((sum, claimSettlement) => {
    return sum + parseFloat(claimSettlement.percent);
  }, 0);

  return parseFloat(claimSettlementSum / claimSettlements.length).toFixed(2);
}

function InfoPopup({ title, description, handleClose = () => {} }) {
  const handleClick = () => handleClose();
  return (
    <Modal>
      <div
        css={`
          position: fixed;
          height: 100vh;
          width: 100vw;
          top: 0;
          left: 0;
          z-index: 999;
          display: flex;
          align-items: center;
          justify-content: center;
        `}
      >
        <div
          css={`
            background-color: #fff;
            border-radius: 12px;
            padding: 10px;
            padding-bottom: 30px;
            position: relative;
            max-width: 600px;
            width: 90%;
          `}
        >
          <CloseButton
            css={`
              top: 10px;
              right: 10px;
              position: absolute;
            `}
            onClick={handleClick}
          />
          <img
            src={InfoImg}
            alt="info"
            css={`
              margin: auto;
              margin-top: 20px;
            `}
          />
          <h2
            css={`
              text-align: center;
              font-size: 23px;
              margin-top: 20px;
            `}
          >
            {title}
          </h2>
          <p
            css={`
              font-size: 16px;
              line-height: 20px;
              padding: 0px;
              margin-top: 10px;
              color: #999;
              font-weight: 100;
              text-align: center;
              padding: 0 60px;
            `}
          >
            {description}
          </p>
        </div>
      </div>
    </Modal>
  );
}

function RecommendDetails({ recommendedQuote = {} }) {
  const {
    matches_requirements,
    why_recommend: { claim_settlement, claim_incurred, network_hospital },
    important_features,
    logos,
    opd_score,
    maternity_score,
    world_wide_score,
    exercise_score,
  } = recommendedQuote;

  const { exercise, worldwide, maternity, opd } = important_features;

  const claimSettlement = averageClaimSettlement(claim_settlement) + "%";
  const incurredClaim = averageClaimSettlement(claim_incurred) + "%";

  const [showIncurredInfo, setShowIncurredInfo] = useState(false);
  const [showClaimInfo, setShowClaimInfo] = useState(false);

  return (
    <div
      css={`
        max-width: 1000px;
        margin: auto;
      `}
    >
      {showClaimInfo && (
        <InfoPopup
          title="What is Claim Settlement Ratio?"
          description="Claim Settlement Ratio of an Insurer is the number of claims settled against the number of claims filed."
          handleClose={() => {
            setShowClaimInfo(false);
          }}
        />
      )}
      {showIncurredInfo && (
        <InfoPopup
          title="What is Incurred Claim Ratio?"
          description="Incurred Claim Ratio refers to the net claims paid by an insurance company as against the net premiums earned."
          handleClose={() => {
            setShowIncurredInfo(false);
          }}
        />
      )}

      <RecommendDetailsSection title="Why do we recommend">
        <Row>
          <FundamentalCheck
            title={`Last ${claim_settlement.length} years claim settlement ratio?`}
            value={claimSettlement}
            showInfo
            handleInfoClick={() => setShowClaimInfo(true)}
          />
          <FundamentalCheck
            title={`Last ${claim_incurred.length} years incurred claim ratio?`}
            value={incurredClaim}
            showInfo
            handleInfoClick={() => setShowIncurredInfo(true)}
          />
          <FundamentalCheck
            title="Cashless hospitals"
            value={network_hospital}
          />
        </Row>
      </RecommendDetailsSection>

      {matches_requirements ? (
        <RecommendDetailsSection title="Matches your requirements">
          <Row>
            {Object.keys(matches_requirements).map(requirement => {
              if (requirement === "network_hospital") {
                const selectedHospitals = matches_requirements[requirement];
                const hospitalNames = Object.keys(selectedHospitals);
                const networkHospitalsCount = hospitalNames.length;
                const hospitalCoversCount = hospitalNames.reduce(
                  (count, hospital) =>
                    selectedHospitals[hospital].length > 0 ? count + 1 : count,
                  0,
                );
                // if (hospitalCoversCount === 0) return null;
                return (
                  <RequirementCard
                    textEllipsis
                    matches={hospitalCoversCount > 0}
                    title={`Covers ${hospitalCoversCount} out of ${networkHospitalsCount} preferred hospitals:`}
                    value={hospitalNames
                      .reduce(
                        (hospitals, hospital) =>
                          selectedHospitals[hospital].length
                            ? [
                                ...hospitals,
                                selectedHospitals[hospital][0].hospital_name,
                              ]
                            : hospitals,
                        [],
                      )
                      .join(", ")}
                    tooltip={
                      <div>
                        {hospitalNames
                          .reduce(
                            (hospitals, hospital) =>
                              selectedHospitals[hospital].length
                                ? [
                                    ...hospitals,
                                    selectedHospitals[hospital][0]
                                      .hospital_name,
                                  ]
                                : hospitals,
                            [],
                          )
                          .map(hospital => (
                            <p
                              className="matches_p"
                              css={`
                                line-height: 1.6;
                                color: #757575 !important;
                                ${mq.mobile} {
                                  font-size: 12.7px !important;
                                }
                              `}
                              title={hospital}
                            >
                              {hospital}
                            </p>
                          ))}
                      </div>
                    }
                  />
                );
              }
              if (requirement === "Fits your budget")
                return (
                  <RequirementCard
                    title={requirement}
                    value={matches_requirements[requirement]}
                    tooltip={
                      matches_requirements[requirement]?.length > 37 ? (
                        <RequirementCardToolTip>
                          {matches_requirements[requirement]}
                        </RequirementCardToolTip>
                      ) : (
                        ""
                      )
                    }
                  />
                );
              return null;
            })}
            {exercise_score > 0 && (
              <RequirementCard
                title={`${exercise?.display_name}:${exercise?.brief}`}
                value={exercise?.description}
                tooltip={
                  <RequirementCardToolTip>
                    {exercise?.description}
                  </RequirementCardToolTip>
                }
              />
            )}
            {world_wide_score > 0 && (
              <RequirementCard
                title={`${worldwide?.display_name}: ${worldwide?.brief}`}
                value={worldwide?.description}
                tooltip={
                  <RequirementCardToolTip>
                    {worldwide?.description}
                  </RequirementCardToolTip>
                }
              />
            )}
            {maternity_score > 0 && (
              <RequirementCard
                title={`${maternity?.display_name}: ${maternity?.brief}`}
                value={maternity?.description}
                tooltip={
                  <RequirementCardToolTip>
                    {maternity?.description}
                  </RequirementCardToolTip>
                }
              />
            )}
            {opd_score > 0 && (
              <RequirementCard
                title={`${opd?.display_name}: ${opd?.brief}`}
                value={opd?.description}
                tooltip={
                  <RequirementCardToolTip>
                    {opd?.description}
                  </RequirementCardToolTip>
                }
              />
            )}
          </Row>
        </RecommendDetailsSection>
      ) : null}

      {important_features ? (
        <RecommendDetailsSection title="Important features">
          <ListItem
            css={`
              font-weight: 900;
            `}
          >
            Pros:{" "}
            <i
              className="far fa-thumbs-up"
              css={`
                margin-left: 0.3em;
              `}
            />
          </ListItem>
          <ImportantFeaturesWrap>
            {important_features ? (
              Object.keys(important_features).filter(
                ife => important_features[ife].pro_con === "pro",
              ).length === 0 ? (
                <p
                  css={`
                    margin-left: 1em;
                  `}
                >
                  No pros for this plan
                </p>
              ) : (
                Object.keys(important_features)
                  .filter(ife => important_features[ife].pro_con === "pro")
                  .map(important_feature => {
                    let logo;
                    logos.forEach(data => {
                      if (data.feature_code === important_feature)
                        logo = data.logo;
                    });
                    if (logo) {
                      return (
                        <FeatureCard
                          feature={important_features[important_feature]}
                          logo={logo}
                        />
                      );
                    }
                    return null;
                  })
              )
            ) : null}
          </ImportantFeaturesWrap>
          <ListItem
            css={`
              font-weight: 900;
            `}
          >
            Cons:
            <i
              className="far fa-thumbs-down"
              css={`
                margin-left: 0.3em;
              `}
            ></i>
          </ListItem>
          <ImportantFeaturesWrap>
            {important_features ? (
              Object.keys(important_features)
                .filter(ife => important_features[ife].pro_con === "con")
                .map(important_feature => {
                  let logo;
                  logos.forEach(data => {
                    if (data.feature_code === important_feature)
                      logo = data.logo;
                  });
                  if (logo) {
                    if (
                      !matches_requirements[
                        "Early Pre-existing ailment cover"
                      ] &&
                      [
                        "pre_existing_waiting_period",
                        "prepolicy_medical_check_up",
                      ].includes(important_feature)
                    )
                      return null;
                    return (
                      <FeatureCard
                        feature={important_features[important_feature]}
                        logo={logo}
                      />
                    );
                  }
                  return null;
                })
                .filter(item => item !== null).length === 0 ? (
                <p
                  css={`
                    margin-left: 1em;
                  `}
                >
                  No cons for this plan
                </p>
              ) : (
                Object.keys(important_features)
                  .filter(ife => important_features[ife].pro_con === "con")
                  .map(important_feature => {
                    let logo;
                    logos.forEach(data => {
                      if (data.feature_code === important_feature)
                        logo = data.logo;
                    });
                    if (logo) {
                      if (
                        !matches_requirements[
                          "Early Pre-existing ailment cover"
                        ] &&
                        [
                          "pre_existing_waiting_period",
                          "prepolicy_medical_check_up",
                        ].includes(important_feature)
                      )
                        return null;
                      return (
                        <FeatureCard
                          feature={important_features[important_feature]}
                          logo={logo}
                        />
                      );
                    }
                    return null;
                  })
              )
            ) : null}
          </ImportantFeaturesWrap>
        </RecommendDetailsSection>
      ) : null}
    </div>
  );
}

export default RecommendDetails;

function RecommendDetailsSection({ title, children }) {
  return (
    <section
      css={`
        display: block;
        margin: 30px;

        ${mq.mobile} {
          margin: 30px 0;
        }
      `}
    >
      <h2
        css={`
          font-size: 21px;
          font-weight: bold;
          padding: 10px;
          border-radius: 6px;
          width: max-content;
          background-image: linear-gradient(to right, #ffe7e7 0%, #fff 37%);
        `}
      >
        {title}
      </h2>
      <div
        css={`
          margin-top: 1rem;
        `}
      >
        {children}
      </div>
    </section>
  );
}
