import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import "styled-components/macro";
import styled from "styled-components/macro";
import BackgroundPng from "../../../../assets/images/bgg.png";
import { CheckSvg } from "../../../../assets/svg/check";
import * as mq from "../../../../utils/mediaQueries";

function AnalysingScreen({ loaded = false, afterLoading = () => {} }) {
  const name = useSelector(state => state.greetingPage.proposerDetails.name);
  const firstName = name ? name.split(" ")[0] : "";

  const [currentLoading, setCurrentLoading] = useState(0);

  useEffect(() => {
    if (loaded) {
      setTimeout(afterLoading, 6 * 300);
    }
  }, [afterLoading, loaded]);

  useEffect(() => {
    if (loaded) {
      if (currentLoading < 5) {
        setTimeout(() => {
          setCurrentLoading(currentLoading => {
            return currentLoading + 1;
          });
        }, 300);
      }
    }
  }, [currentLoading, loaded]);

  return (
    <Screen
      css={`
        ${currentLoading >= 6 &&
        `transition: 0.37s ease-out;
        transform: translateY(-100%);
        opacity: 0;`}
      `}
    >
      <Container>
        <Heading>
          Hi <Name>{firstName},</Name> Your analysis is in progress hold on
          while we match your requirements
        </Heading>
        {/* <SubHeading>
          Lorem ipsum is simply dummy text in the printing and typesetting
          industry
        </SubHeading> */}
        <Border />
        <div
          css={`
            width: 60%;
            margin-top: 1.6rem;

            ${mq.mobile} {
              width: 96%;
            }
          `}
        >
          <Status status={currentLoading > 0 ? "completed" : "loading"}>
            Plans suiting your budget
          </Status>
          <Status status={currentLoading > 1 ? "completed" : "loading"}>
            Plans suiting your required cover amount
          </Status>
          <Status status={currentLoading > 2 ? "completed" : "loading"}>
            Searching for plans with better Claim Settlement Ratio
          </Status>
          <Status status={currentLoading > 3 ? "completed" : "loading"}>
            Plans suiting your selected features
          </Status>
          <Status status={currentLoading > 4 ? "completed" : "loading"}>
            Searching plans for selected Cashless Network Hospitals
          </Status>
        </div>
      </Container>
    </Screen>
  );
}

function Status({ children, status = "loading" }) {
  return (
    <div
      css={`
        display: flex;
        justify-content: space-between;
        align-items: center;
        width: 100%;
        margin: 1rem 0;

        ${mq.mobile} {
        }
      `}
    >
      <p
        css={`
          font-size: 20px;
          color: ${status === "loading" ? "#adadad" : "#000"};

          ${mq.mobile} {
            font-size: 12px;
          }
        `}
      >
        {children}
      </p>
      <div>
        {status === "loading" ? (
          <i
            className="fa fa-circle-notch rotate"
            css={`
              color: var(--abc-red);
              font-size: 30px;

              ${mq.mobile} {
                font-size: 18px;
              }
            `}
          />
        ) : (
          <div>
            <CheckSvg
              customCss={`
              ${mq.mobile} {
                height: 20px;
                width: 20px;
              }
            `}
            />
          </div>
        )}
      </div>
    </div>
  );
}

const Screen = styled.div`
  min-height: 100vh;
  background-image: url(${BackgroundPng});
  background-position: center;
  background-repeat: no-repeat;
  padding: 0;
  margin: 0;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const Container = styled.main`
  max-width: 1100px;
  width: 90%;
  margin: 0 auto;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
`;

const Heading = styled.h1`
  font-size: 40px;
  font-weight: 900;
  text-align: center;
  ${mq.mobile} {
    font-size: 27px;
  }
`;

const Name = styled.span`
  text-transform: capitalize;
`;

const SubHeading = styled.p`
  font-size: 15px;
  color: #adadad;
  margin-top: 1rem;

  ${mq.mobile} {
    font-size: 14px;
    text-align: center;
  }
`;

const Border = styled.div`
  height: 8px;
  width: 60px;
  background-color: var(--yellow-one);
  border-radius: 10px;
  margin-top: 0.7rem;
`;

export default AnalysingScreen;
