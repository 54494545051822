import React, { useState } from "react";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import "styled-components/macro";
import hospitalImg from "../../../../assets/images/cashless_p.png";
import * as mq from "../../../../utils/mediaQueries";

const renderTooltip = desc => <Tooltip>{desc}</Tooltip>;

function FeatureCard({ logo, feature, ...props }) {
  const { display_name: feature_name, description: tooltip, brief } = feature;

  const [isInfo, setIsInfo] = useState(false);
  const showInfo = () => setIsInfo(true);
  const hideInfo = () => setIsInfo(false);

  if (!feature) return null;

  return (
    // <OverlayTrigger placement="right" overlay={renderTooltip(tooltip)}>
    <div
      {...props}
      css={`
        flex: 0 1 calc(50% - 0.6em);
        position: relative;
        /* border: 1px solid #02BC77; */
        box-shadow: 0px 30px 70px 0px rgb(223 227 234 / 50%);
        border-radius: 6px;
        background-color: #fff;
        padding: 10px 10px;
        display: flex;
        align-items: start;
        /* margin: 10px; */
        /* width: 300px; */
        /* width: 31.3%; */
        /* width: calc(50% - 1em); */
        min-height: 137px;

        /* &:last-child {
          margin-right: auto;
        } */

        /* ${mq.mobile} {
          width: 47%;
          flex-direction: column;
          border-radius: 10px;
          margin: 10px 0;
          &:last-child {
            margin-right: 0;
          }
        } */

        ${mq.mobile} {
          flex: 1 1 100%;
        }
      `}
    >
      {isInfo ? (
        <div
          css={`
            position: absolute;
            left: 100%;
            top: 50%;
            transform: translate(0, -50%);
            min-height: 160%;
            width: 50%;
            background-color: #fff;
            border: 1px solid #ddd;
            z-index: 99;
            box-shadow: 0px 10px 20px rgb(134 156 213 / 25%);
            border-radius: 6px;
            border: 1px solid #ddd;

            ${mq.mobile} {
              left: 0;
              top: 0;
              width: 100%;
              transform: translate(0, -100%);
              min-height: max-content;
            }
          `}
        >
          <div
            css={`
              position: relative;
              height: 100%;
              width: 100%;
              z-index: 99;
              background-color: #fff;
              padding: 10px 0;
              border-radius: 6px;
            `}
          >
            <div
              css={`
                position: absolute;
                top: 50%;
                left: 0;
                height: 20px;
                width: 20px;
                transform: translate(-50%, -50%) rotate(45deg);
                border: 1px solid #ddd;
                z-index: -1;
                background-color: #fff;

                ${mq.mobile} {
                  top: 100%;
                  left: 50%;
                  border-top: none;
                  border-left: none;
                }
              `}
            />
            <div
              css={`
                min-height: 100%;
                width: 100%;
                background: #fff;
                z-index: 99;
                border-radius: 6px;
                padding: 10px;
              `}
            >
              <p>{tooltip}</p>
            </div>
          </div>
        </div>
      ) : null}
      <div
        css={`
          flex: 1;
          display: flex;
          flex-direction: column;
          /* align-items: center; */

          & p {
            line-height: 1.6;
          }
        `}
      >
        <div
          css={`
            display: flex;
            align-items: center;
          `}
        >
          <img
            src={logo}
            alt="feature"
            css={`
              margin-right: 20px;
              align-self: start;
            `}
          />
          <div
            css={`
              display: flex;
              align-items: center;
              flex: 1;
            `}
            title={`${feature_name}: ${brief}`}
          >
            <p
              css={`
                font-size: 18px;
                /* border-bottom: 2px dashed #d6d6d6; */
                /* width: max-content; */
                /* margin: auto; */

                width: calc(100% - 36px);
                overflow: hidden;
                /* white-space: nowrap; */
                line-height: 1.2 !important;
                margin-bottom: 10px;
                text-overflow: ellipsis;
                /* 
                ${mq.mobile} {
                  font-size: 12px;
                  margin-top: 3px;
                } */
              `}
            >
              {feature_name}: {brief}
            </p>
            {/* <p
              css={`
                font-size: 18px;
                line-height: 1;
                margin-left: 0.6em;
              `}
            >
              A Short text about the feature
            </p> */}
          </div>
        </div>
        {/* <i className="fas fa-angle-down" css={`margin-left: .6em;`}/> */}
        <div
          css={`
            /* color: #333; */
            & p {
              font-weight: normal;
            }
          `}
        >
          <p
            css={`
              font-size: 0.9em;
              margin-top: -0.3em;
              color: #666;
              margin-left: 4.2em;
            `}
          >
            {tooltip ? tooltip.slice(0, 100) : ""}
            {/* Sometimes insurance companies ask you to pay from your own pocket a
            certain percentage of the final payable bill amount. For example,
            10% copay on final bill of Rs. 1 lakh means, you pay Rs. 10,000 and
            the insurance */}
            {tooltip
              ? tooltip.length > 100 && (
                  <span
                    css={`
                      color: var(--abc-red);
                      margin-left: 0.6em;
                      cursor: pointer;
                    `}
                    onMouseEnter={showInfo}
                    onMouseLeave={hideInfo}
                  >
                    show more
                  </span>
                )
              : ""}
          </p>
        </div>
      </div>
    </div>
    // </OverlayTrigger>
  );
}

// const FeatureCard = ({ name }) => {
//   return (
//     <Col lg={3}>
//       <div className="feature-block-three-imp active mt-24">
//         <div
//           className="d-flex"
//           css={`
//             align-items: center;
//           `}
//         >
//           <div class="icon-box icon-one">
//             <img src={hospitalImg} alt="" />
//           </div>
//           <div
//             css={`
//               display: flex;
//               align-items: center;
//               height: 100%;
//             `}
//           >
//             <h5
//               // data-toggle="tooltip"
//               // data-placement="right"
//               // title=""
//               // data-original-title="No Room Rent Limit"
//               css={`
//                 font-family: "pf_handbook_proregular" !important;
//                 font-size: 16px !important;
//                 font-weight: 900;
//                 border-bottom: 2px dashed #d6d6d6;
//               `}
//             >
//               {name}
//             </h5>
//           </div>
//         </div>
//       </div>
//     </Col>
//   );
// };

export default FeatureCard;

export const ToolTip = ({ children, content, show = false, ...props }) => {
  // const [visible, setVisible] = useState(show);

  // const showToolTip = () => setVisible(true);
  // const hideToolTip = () => setVisible(false);

  return (
    <div
      css={`
        position: relative;
      `}
      // onMouseOver={showToolTip}
      // onMouseOut={hideToolTip}
      {...props}
    >
      {show ? (
        <div
          css={`
            position: absolute;
            left: 100%;
            top: 50%;
            transform: translate(0, -50%);
            /* min-height: 160%; */
            width: 50%;
            background-color: #fff;
            border: 1px solid #ddd;
            z-index: 99;
            box-shadow: 0px 10px 20px rgb(134 156 213 / 25%);
            border-radius: 6px;
            border: 1px solid #ddd;

            ${mq.mobile} {
              left: 0;
              top: 0;
              width: 100%;
              transform: translate(0, -100%);
              min-height: max-content;
            }
          `}
        >
          <div
            css={`
              position: relative;
              height: 100%;
              width: 100%;
              z-index: 99;
              background-color: #fff;
              padding: 10px 0;
              border-radius: 6px;
            `}
          >
            <div
              css={`
                position: absolute;
                top: 50%;
                left: 0;
                height: 20px;
                width: 20px;
                transform: translate(-50%, -50%) rotate(45deg);
                border: 1px solid #ddd;
                z-index: -1;
                background-color: #fff;

                ${mq.mobile} {
                  top: 100%;
                  left: 50%;
                  border-top: none;
                  border-left: none;
                }
              `}
            />
            <div
              css={`
                min-height: 100%;
                width: 100%;
                background: #fff;
                z-index: 99;
                border-radius: 6px;
                padding: 10px;
              `}
            >
              {content}
            </div>
          </div>
        </div>
      ) : null}
      {children}
    </div>
  );
};
