/* eslint-disable jsx-a11y/anchor-is-valid */
import { useState, useEffect } from "react";
import { Col, Row, OverlayTrigger, Tooltip, Container } from "react-bootstrap";
import SecureLS from "secure-ls";
import seePlan from "../../../../../assets/images/see_plan_more.png";
import {
  ICName,
  ProductCover,
  quoteCardDataset,
  RadioInput,
  RadioLabel,
} from "./QuotesCardComponents";
import { cashless } from "../../../../../assets/index";
import SolidBuyButton from "../../Button/SolidBuyButton";
import { SingleBlock } from "../../QuotesComponents.js";
import { useHistory } from "react-router";
import "styled-components/macro";
import {
  removeQuotesForCompare,
  saveSelectedPlan,
  setQuotesForCompare,
  setQuotesOnCompare,
  saveProductDiscountResponse,
  addSelectedQuote,
  createCartItem,
  updateCartItem,
  setSelectedQuotes,
  setSelectedRiders,
  addSelectedRiders,
} from "../../../quotePage.slice";

import { useDispatch, useSelector } from "react-redux";

const SubContent = ({
  quoteCardData,
  quotesForCompare,
  handleClick,
  handleSeeDetails,
  addProduct,
}) => {
  const {
    product: { name, id },
    premium,
    total_premium,
    logo,
    insurance_id,
    cashlessHospitalsCount,
    sum_insured,
    tax_amount,
    company_alias,
    mandatory_riders,
    features,
  } = quoteCardData;
  const dispatch = useDispatch();
  const history = useHistory();
  const ls = new SecureLS();
  const [isLoading, setIsLoading] = useState(false);
  const [activeCover, setActiveCover] = useState(0);
  const { multiYear } = useSelector(state => state.quotePage.filters);

  const tenure = parseInt(multiYear) === 1 ? 1 : parseInt(multiYear);
  const { selectedGroup, cartItems } = useSelector(state => state.quotePage);

  const { memberGroups } = useSelector(state => state.greetingPage);

  const members = memberGroups[selectedGroup];

  let additionalPremium = 0;

  mandatory_riders[activeCover]?.forEach(element => {
    additionalPremium += element.total_premium;
  });

  const handleSeeDetailsClick = clickedFrom => {
    handleSeeDetails(
      {
        quote: quoteCardData,
        activeSum: activeCover,
      },
      clickedFrom,
    );
    const selectedPlan = {
      company_alias: company_alias,
      logo: logo,
      product: { name, id },
      total_premium: total_premium[activeCover],
      premium: premium[activeCover],
      sum_insured: sum_insured[activeCover],
      tax_amount: tax_amount[activeCover],
      tenure: tenure[activeCover],
    };
    dispatch(saveSelectedPlan(selectedPlan));
  };

  const handleBuyNowClick = () => {
    setIsLoading(true);
    const selectedPlan = {
      // company_alias: mergedQuotes[0]?.company_alias,
      // logo: mergedQuotes[0]?.logo,
      product: { name, id },
      total_premium: total_premium[activeCover],
      // premium: premium[activeCover],
      sum_insured: sum_insured[activeCover],
      tax_amount: tax_amount[activeCover],
      tenure: tenure,
    };


    addProduct({
      ...selectedPlan,
      product_id: selectedPlan.product?.id,
      premium: selectedPlan.total_premium,
      group_id: parseInt(selectedGroup),
      service_tax: selectedPlan.tax_amount,
    }).then(handleClick);
    setIsLoading(false);
  };

  const [checked, setChecked] = useState(
    quotesForCompare.includes(`${id}${sum_insured}`) ? true : false,
  );

  const plansData = [
    ...features?.[activeCover].filter(i => i.is_featured_on_card),
    {
      name: "Cashless Hospital",
      value: cashlessHospitalsCount[activeCover],
      description:
        "A specific percent of claim amount is paid by the Insured person in case the treatment is taken at a hospital not mentioned in the Network Hospitals list.",
      icon: cashless,
    },
  ];

  useEffect(() => {
    if (quotesForCompare.includes(`${id}${sum_insured[activeCover]}`)) {
      setChecked(true);
    } else {
      setChecked(false);
    }
  }, [quotesForCompare, checked, activeCover]);

  return (
    <SingleBlock className="bg_light_pink_more_plan">
      <Row className="margin_top_feature">
        <Col md={5}>
          <Row>
            <Col md={10} className="product_logo_title_more_plan">
              <ICName morePlan style={{ marginLeft: "-10px" }}>
                {name}
              </ICName>
            </Col>
          </Row>
          <Row className="row_cover_premium_product_more_plan">
            <Col md={5} className="no_padding">
              {sum_insured.length > 1 ? (
                <ProductCover className="quotecard__cover">
                  <p className="line_height_p_product">Cover</p>
                  <p
                    className="p_line_sub_product dropdown-product"
                    id="compare_one_cover"
                  >
                    <i class="fa fa-inr" aria-hidden="true"></i>{" "}
                    <select
                      className="p_line_sub_product"
                      id="compare_one_cover"
                      onChange={e => setActiveCover(e.target.value)}
                    >
                      {sum_insured.map((data, i) => {
                        return <option value={i}>{data}</option>;
                      })}
                    </select>
                  </p>
                </ProductCover>
              ) : (
                <ProductCover className="quotecard__cover">
                  <p className="line_height_p_product">Cover</p>
                  <p className="p_line_sub_product" id="compare_one_cover">
                    <i className="fa fa-inr"></i>
                    {sum_insured[activeCover]}
                  </p>
                </ProductCover>
              )}
            </Col>
            <Col md={7} className="no_padding">
              <p className="line_height_p_product">Premium</p>
              <p className="p_line_sub_product">
                <i className="fa fa-inr"></i>{" "}
                {total_premium[activeCover] + additionalPremium}
                <span>
                  /{tenure > 1 ? `${tenure} years` : " year"}
                </span>
              </p>
            </Col>
          </Row>
        </Col>
        <Col md={7} className="margin_internal_card_copay">
          <Row>
            {plansData.map((data, index) =>
              quoteCardDataset(data, index, handleSeeDetailsClick),
            )}
            <Col
              md={4}
              className="no_padding"
              css={`
                position: absolute;
                right: 0;
                bottom: 20px;
              `}
            >
              <SolidBuyButton
                disabled={isLoading}
                value={
                  isLoading ? (
                    <i className="fas fa-circle-notch rotate" />
                  ) : (
                    "Buy Now"
                  )
                }
                onClick={handleBuyNowClick}
              // onClick={() => {
              //   setIsLoading(true);
              //   ls.set("cover", sum_insured[activeCover]);
              //   const selectedPlan = {
              //     company_alias: company_alias,
              //     logo: logo,
              //     product: { id: id, name: name },
              //     gross_premium: total_premium[activeCover],
              //     premium: premium[activeCover],
              //     sum_insured: sum_insured[activeCover],
              //     tax_amount: tax_amount[activeCover],
              //     tenure: tenure[activeCover],
              //   };

              //   if (cartItems.length > 0) {
              //     dispatch(
              //       updateCartItem(
              //         {
              //           members,
              //           cartId: cartItems[0].id,
              //           ...selectedPlan,
              //           product_id: selectedPlan.product?.id,
              //           premium: selectedPlan.total_premium,
              //         },
              //         cartId => {
              //           dispatch(saveSelectedPlan(selectedPlan));
              //           dispatch(
              //             addSelectedQuote({ cartId, ...selectedPlan }),
              //           );
              //           dispatch(saveProductDiscountResponse([]));
              //           dispatch(addSelectedRiders([]));
              //           handleClick();
              //           setIsLoading(false);
              //         },
              //       ),
              //     );
              //     return;
              //   }

              //   dispatch(
              //     createCartItem(
              //       {
              //         members,
              //         ...selectedPlan,
              //       },
              //       cartId => {
              //         dispatch(saveSelectedPlan(selectedPlan));
              //         dispatch(addSelectedQuote({ cartId, ...selectedPlan }));
              //         dispatch(saveProductDiscountResponse([]));
              //         dispatch(addSelectedRiders([]));
              //         handleClick();
              //         setIsLoading(false);
              //       },
              //     ),
              //   );
              // }}
              />
            </Col>
          </Row>
        </Col>
      </Row>
      <Col md={12} className="mt-12">
        <hr className="hr_margin_que" />
      </Col>
      <Container fluid className="box_post_back bg_light_pink_more_plan">
        <Row>
          <Col md={4} xs={5}>
            &nbsp;
          </Col>
          <Col md={5} xs={5} className="text-center padding_see_details_plan">
            <a
              // onClick={e => {
              //   e.preventDefault();
              //   // handleSeeDetails({
              //   //   quote: quoteCardData,
              //   //   activeSum: activeCover,
              //   // });
              // }}
              onClick={handleSeeDetailsClick}
            >
              <p className="p_more_plans_arrow">See Details</p>
              <img className="p_more_plans_arrow_img" src={seePlan} />
            </a>
          </Col>
          <Col md={3} xs={5} className="pad_left text-right">
            <div className="agreement-checkbox margin_top_checkbox_card">
              <div>
                <RadioInput
                  type="checkbox"
                  //dynamic id
                  id={`compare_${id}${sum_insured[activeCover]}`}
                  className="compare-checkbox"
                  checked={checked}
                  onChange={() => {
                    dispatch(setQuotesOnCompare());
                    if (!checked) {
                      const numberOfPlans = window.matchMedia(
                        "(max-width: 1023px)",
                      ).matches
                        ? 2
                        : 3;
                      dispatch(
                        setQuotesForCompare([
                          `${id}${sum_insured[activeCover]}`,
                          numberOfPlans,
                        ]),
                      );
                    } else {
                      dispatch(
                        removeQuotesForCompare(
                          `${id}${sum_insured[activeCover]}`,
                        ),
                      );
                    }
                  }}
                />

                <RadioLabel
                  //dynamic id
                  htmlFor={`compare_${id}${sum_insured[activeCover]}`}
                >
                  Add to Compare
                </RadioLabel>
              </div>
            </div>
          </Col>
        </Row>
      </Container>
    </SingleBlock>
  );
};

export default SubContent;

// /* eslint-disable jsx-a11y/anchor-is-valid */
// import { useState, useEffect } from "react";
// import { Col, Row, OverlayTrigger, Tooltip, Container } from "react-bootstrap";

// import seePlan from "../../../../../assets/images/see_plan_more.png";
// import {
//   ICName,
//   plansData,
//   ProductCover,
//   quoteCardDataset,
//   RadioInput,
//   RadioLabel,
// } from "./QuotesCardComponents";
// import SolidBuyButton from "../../Button/SolidBuyButton";
// import { SingleBlock } from "../../QuotesComponents.js";
// import { useHistory } from "react-router";
// import {
//   removeQuotesForCompare,
//   saveSelectedPlan,
//   setQuotesForCompare,
//   setQuotesOnCompare,
// } from "../../../quotePage.slice";
// import { useDispatch } from "react-redux";

// const SubContent = ({
//   quoteCardData,
//   quotesForCompare,
//   handleClick,
//   handleSeeDetails,
// }) => {
// 	const {
// 		product: { name, id },
// 		premium,
// 		total_premium,
// 		tenure,
// 		insurance_id,
// 		sum_insured,
// 		tax_amount,
// 		logo,
// 		company_alias,
// 	} = quoteCardData;
// 	const dispatch = useDispatch();
// 	const history = useHistory();

//   const [activeCover, setActiveCover] = useState(0);

//   const [checked, setChecked] = useState(
//     quotesForCompare.includes(`${id}${sum_insured}`) ? true : false
//   );

//   useEffect(() => {
//     if (quotesForCompare.includes(`${id}${sum_insured[activeCover]}`)) {
//       setChecked(true);
//     } else {
//       setChecked(false);
//     }
//   }, [quotesForCompare, checked, activeCover]);

// 	return (
// 		<SingleBlock className="bg_light_pink_more_plan">
// 			<Row className="margin_top_feature">
// 				<Col md={5}>
// 					<Row>
// 						<Col md={10} className="product_logo_title_more_plan">
// 							<ICName morePlan style={{ marginLeft: "-10px" }}>
// 								{name}
// 							</ICName>
// 						</Col>
// 					</Row>
// 					<Row className="row_cover_premium_product_more_plan">
// 						<Col md={5} className="no_padding">
// 							{sum_insured.length > 1 ? (
// 								<ProductCover className="quotecard__cover">
// 									<p className="line_height_p_product">Cover</p>
// 									<p
// 										className="p_line_sub_product dropdown-product"
// 										id="compare_one_cover"
// 									>
// 										<i class="fa fa-inr" aria-hidden="true"></i>{" "}
// 										<select
// 											className="p_line_sub_product"
// 											id="compare_one_cover"
// 											onChange={(e) => setActiveCover(e.target.value)}
// 										>
// 											{sum_insured.map((data, i) => {
// 												return <option value={i}>{data}</option>;
// 											})}
// 										</select>
// 									</p>
// 								</ProductCover>
// 							) : (
// 								<ProductCover className="quotecard__cover">
// 									<p className="line_height_p_product">Cover</p>
// 									<p className="p_line_sub_product" id="compare_one_cover">
// 										<i className="fa fa-inr"></i>
// 										{sum_insured[activeCover]}
// 									</p>
// 								</ProductCover>
// 							)}
// 						</Col>
// 						<Col md={7} className="no_padding">
// 							<p className="line_height_p_product">Premium</p>
// 							<p className="p_line_sub_product">
// 								<i className="fa fa-inr"></i> {total_premium[activeCover]}
// 								<span>/ year</span>
// 							</p>
// 						</Col>
// 					</Row>
// 				</Col>
// 				<Col md={7} className="margin_internal_card_copay">
// 					<Row>
// 						{plansData.map((data, index) => quoteCardDataset(data, index))}
// 						<Col md={4} className="no_padding">
// 							<a
// 								data-toggle="modal"
// 								data-target="#m-md-review"
// 								onClick={() => {
// 									handleClick();
// 									dispatch(saveSelectedPlan(quoteCardData));
// 								}}
// 							>
// 								{" "}

// 								<SolidBuyButton
// 									value={"Buy Now"}
// 									onClick={() => {
// 										// handleClick();
// 										// dispatch(saveSelectedPlan(quoteCardData));

// 										handleClick();
// 										const SelectedPlan = {
// 											company_alias: company_alias,
// 											logo: logo,
// 											product: { id: id, name: name },
// 											total_premium: total_premium[activeCover],
// 											premium: premium[activeCover],
// 											sum_insured: sum_insured[activeCover],
// 											tax_amount: tax_amount[activeCover],
// 											tenure: tenure[activeCover],
// 										};

// 										dispatch(saveSelectedPlan(SelectedPlan));
// 									}}
// 								/>
// 							</a>
// 						</Col>
// 					</Row>
// 				</Col>
// 			</Row>
// 			<Col md={12} className="mt-12">
// 				<hr className="hr_margin_que" />
// 			</Col>
// 			<Container fluid className="box_post_back bg_light_pink_more_plan">
// 				<Row>
// 					<Col md={4} xs={5}>
// 						&nbsp;
// 					</Col>
// 					<Col md={5} xs={5} className="text-center padding_see_details_plan">
// 						<a
// 							onClick={(e) => {
// 								e.preventDefault();
// 								handleSeeDetails();
// 							}}
// 						>
// 							<p className="p_more_plans_arrow">See Details</p>
// 							<img className="p_more_plans_arrow_img" src={seePlan} />
// 						</a>
// 					</Col>
// 					<Col md={3} xs={5} className="pad_left text-right">
// 						<div className="agreement-checkbox margin_top_checkbox_card">
// 							<div>
// 								<RadioInput
// 									type="checkbox"
// 									//dynamic id
// 									id={`compare_${id}${sum_insured[activeCover]}`}
// 									className="compare-checkbox"
// 									checked={checked}
// 									onChange={() => {
// 										dispatch(setQuotesOnCompare());
// 										if (!checked) {
// 											const numberOfPlans = window.matchMedia(
// 												"(max-width: 1023px)"
// 											).matches
// 												? 2
// 												: 3;
// 											dispatch(
// 												setQuotesForCompare([
// 													`${id}${sum_insured[activeCover]}`,
// 													numberOfPlans,
// 												])
// 											);
// 										} else {
// 											dispatch(
// 												removeQuotesForCompare(
// 													`${id}${sum_insured[activeCover]}`
// 												)
// 											);
// 										}
// 									}}
// 								/>

//                 <RadioLabel
//                   //dynamic id
//                   htmlFor={`compare_${id}${sum_insured[activeCover]}`}
//                 >
//                   Add to Compare
//                 </RadioLabel>
//               </div>
//             </div>
//           </Col>
//         </Row>
//       </Container>
//     </SingleBlock>
//   );
// };

// export default SubContent;
