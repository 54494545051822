import styled from "styled-components";

import searchImg from "../../../../assets/images/searchInput.png";

export const Title = styled.h2`
  font-size: 38px;
  color: #000;
  /* margin-bottom: 51px; */
  margin-bottom: 16px;
  font-weight: 900;
  text-align: left;
  /* font-family: PFEncoreSansProblck; */
  margin-top: 6px;
  padding: 0 12px;
  padding-right: 0px;
  max-width: 760px;
`;

export const Paragraph = styled.p`
  margin: 25px 114px 0px -2px;
  padding: 0px;
  color: #9d9ead;
  font-size: 20px;
  font-weight: 400;
`;

export const ContinueButton = styled.button`
  background: #c7222a;

  color: white;
  border: 0 none;
  border-radius: 2px;
  cursor: pointer;
  padding: 4px 4px 0px;
  text-align: center;
  /* margin: 35px 0px; */

  width: 200px;
  height: 70px;
  font-size: 24px;
  font-weight: 400;
  text-indent: -3px;
  line-height: 48px;
  margin-bottom: 30px;
`;

export const SearchInput = styled.input`
  &,
  &:focus,
  &:hover {
    padding: 17px 15px 18px 68px;
    border: 1px solid #edeef5;
    border-radius: 20px;
    margin-bottom: 25px;
    margin-top: 2px;
    width: 86%;
    box-sizing: border-box;
    color: #9497b8;
    background-color: #fff;
    font-size: 21px;
    letter-spacing: 1px;
    text-align: left;
    /* margin: 0 113px; */
    box-shadow: 1px 6px 4px 1px #eaf0f4;
    height: 94px;
  }
  background: url(${searchImg}) no-repeat 3% 53% !important;
  &:focus {
    box-shadow: none !important;
    border: 1px solid #d68d87;
    outline-width: 0;
  }
`;
