import React from "react";
import { useSelector } from "react-redux";
import { useHistory, useParams } from "react-router";
import EditPencil from "../../../../assets/images/pencil_m.png";
import {
  selectAnswers,
  selectQuestions,
  selectSelectedHospitals,
} from "../../recommendedPage.slice";
import "styled-components/macro";
import useUrlQuery from "../../../../customHooks/useUrlQuery";

function Title({
  question = {},
  handleEdit = () => {},
  children,
  css = ``,
  column,
  ...props
}) {
  const { name: title } = question;
  const handleEditClick = () => {
    handleEdit(question);
  };
  return (
    <div
      css={`
        display: flex;
        align-items: center;
        justify-content: space-between;
        ${css}
      `}
      {...props}
    >
      <div
        css={`
          display: flex;
          flex-direction: ${column ? "column" : "row"};
          width: 300px;
          overflow: hidden;
          white-space: nowrap;
          text-overflow: ellipsis;
        `}
      >
        <div
          css={`
            text-transform: capitalize;
            color: #000;
            margin-right: 0.6em;
            font-weight: 900;
          `}
        >
          {title?.replace("_", " ")}:
        </div>
        <div>{children}</div>
      </div>
      <button
        onClick={handleEditClick}
        css={`
          height: 30px;
          width: 30px;
          display: flex;
          align-items: center;
          justify-content: center;
          /* box-shadow: 0 3px 6px 0 rgb(16 24 48 / 12%); */
          border-radius: 50%;
          align-self: start;
        `}
      >
        <img src={EditPencil} alt="edit" />
      </button>
    </div>
  );
}

const Summary = ({ setCurrentForm = () => {}, resultPage, children }) => {
  const { groupCode } = useParams();
  const history = useHistory();
  const selectedHospitals = useSelector(selectSelectedHospitals(groupCode));
  const answers = useSelector(selectAnswers(groupCode));
  const questions = useSelector(selectQuestions);

  const urlQueries = useUrlQuery();

  const enquiryId = urlQueries.get("enquiryId");

  const handleEdit = idx => {
    if (resultPage) {
      history.push(`/recommend/${groupCode}?enquiryId=${enquiryId}&qno=${idx}`);
      return;
    }
    setCurrentForm(idx);
  };

  return (
    <div
      className="your_details_re border_right_effect_religare "
      css={`
        background-color: #fff;
      `}
    >
      <p className="your_select_t">Your Selected Details</p>
      <div className="your_select_t_sub">
        {/* {selectedHospitals?.length ? <Title title="Hospitals Name" /> : null}
        <p
          css={`
            margin-bottom: 6px;
          `}
        >
          {selectedHospitals.map(selectedHospital => (
            <p>{selectedHospital.name}</p>
          ))}
        </p> */}
        {/* {Object.keys(answers).map(name => (
          <>
            <Title title={name} />
            <p
              css={`
                margin-bottom: 6px;
              `}
            >
              {Array.isArray(answers[name])
                ? answers[name].join(", ")
                : answers[name]}
            </p>
          </>
        ))} */}

        <div>{children}</div>

        {questions.map((question, idx) =>
          question.name === "network_hospital" && selectedHospitals?.length ? (
            <>
              <Title
                question={{ ...question, name: "Hospitals Name" }}
                handleEdit={() => handleEdit(idx)}
                column
              >
                <div
                  css={`
                    /* margin-bottom: 6px; */
                    /* overflow: hidden;
                    text-overflow: ellipsis;
                    white-space: nowrap; */
                    /* width: 200px; */
                    margin-bottom: 6px;
                  `}
                >
                  {selectedHospitals &&
                    selectedHospitals.length > 0 &&
                    selectedHospitals.map(selectedHospital => (
                      <div
                        css={`
                          width: 17em;
                          /* overflow: hidden;
                        white-space: nowrap;
                        text-overflow: ellipsis;
                        display: inline-block; */
                          font-weight: 900;
                          overflow: hidden;
                          text-overflow: ellipsis;
                        `}
                        title={
                          selectedHospital.hospital_name ||
                          selectedHospital.name
                        }
                      >
                        -{" "}
                        {selectedHospital.hospital_name ||
                          selectedHospital.name}
                      </div>
                    ))}
                </div>
              </Title>
            </>
          ) : answers[question.name] ? (
            <>
              <Title
                question={question}
                handleEdit={() => handleEdit(idx)}
                css={`
                  margin-bottom: 6px;
                `}
              >
                <div
                  css={`
                    /* margin-bottom: 6px; */
                    font-weight: 900;
                  `}
                >
                  {Array.isArray(answers[question.name])
                    ? answers[question.name].join(", ")
                    : answers[question.name]}
                </div>
              </Title>
            </>
          ) : null,
        )}

        {/* <span className="float_right text-black">
          <img
            src={EditPencil}
            className="edit_img_pencil_re img_pencil"
            alt="edit"
          />
        </span> */}
      </div>
    </div>
  );
};

export default Summary;
