import React, { useEffect, useState } from "react";
import { Col } from "react-bootstrap";
import { Link, useParams } from "react-router-dom";
import { SingleBlock } from "../QuotesComponents";
import useUrlQuery from "../../../../customHooks/useUrlQuery";
import "styled-components/macro";
import { useDispatch, useSelector } from "react-redux";
import {
  selectRecommenedQuotes,
  setRecommendedQuotes,
} from "../../../RecommendedPage/recommendedPage.slice";
import { getRecommendedQuotes } from "../../../RecommendedPage/serviceApi";
import CardSkeletonLoader from "../../../../components/Common/card-skeleton-loader/CardSkeletonLoader";
import { selectCompany } from "../../../../FrontendBoot/reducer/frontendBoot.slice";
import { amount } from "../../../../utils/helper";

const RecomendationCard = () => {
  const { groupCode } = useParams();
  const urlQuery = useUrlQuery();
  const enquiryId = urlQuery.get("enquiryId");

  const recommendedQuotes = useSelector(selectRecommenedQuotes(groupCode));

  const [loading, setLoading] = useState(false);

  const dispatch = useDispatch();

  useEffect(() => {
    setLoading(true);
    getRecommendedQuotes({ groupCode })
      .then(data => {
        dispatch(
          setRecommendedQuotes({
            groupCode: groupCode,
            recommendedQuotes: data?.data?.data,
          }),
        );
      })
      .finally(() => setLoading(false));
  }, [dispatch, groupCode]);

  const hasRecommendedQuotes = recommendedQuotes.length > 0;

  return (
    <SingleBlock
      css={`
        margin-bottom: -10px;
        margin-top: 40px;
        padding-top: 10px;
      `}
      // style={{ marginTop: 0 }}
      className="recommend_product_block"
    >
      {loading && (
        <div
          css={`
            margin: 10px;
          `}
        >
          <CardSkeletonLoader noOfCards={1} />
        </div>
      )}
      {!loading && (
        <div
          className="feature-offer-box access-feature js-tilt"
          style={{
            willChange: "transform",
            transform: "perspective(300px) rotateX(0deg) rotateY(0deg)",
          }}
        >
          <div className="icon-box">&nbsp;</div>
          <div className="product_title_p_bor_modal_recommed">
            <h4
              className="title"
              style={{
                fontSize: "20px",
                margin: "-28px 17px 8px",
                paddingBottom: "20px",
              }}
            >
              {hasRecommendedQuotes
                ? "Recommended Plan"
                : "Get your personalised Plan"}
            </h4>
          </div>
          {hasRecommendedQuotes ? (
            <div>
              <RecommendDisplayCard recommendedQuote={recommendedQuotes[0]} />
            </div>
          ) : (
            <p
              style={{
                lineHeight: "18px",
                fontWeight: "400",
                fontSize: "14px",
              }}
            >
              Answer a few simple questions to get a personalized plan which
              best suits you ranked by what's important to you
            </p>
          )}
        </div>
      )}
      {!hasRecommendedQuotes && !loading && (
        <Col md={12} xs={5} style={{ textAlign: "center" }}>
          <Link
            className="solid-button-buy_m"
            css={`
              color: #c72229 !important;
              border: 1px solid #c72229 !important;
              background: #fff !important;
              box-shadow: unset !important;
              &:hover {
                color: #fff !important;
                border: 1px solid #c72229 !important;
                background: #c72229 !important;
              }
            `}
            style={{ margin: "13px 12px" }}
            to={`/recommend/${groupCode}?enquiryId=${enquiryId}`}
          >
            Recommend
          </Link>
        </Col>
      )}
    </SingleBlock>
  );
};

export default RecomendationCard;

function RecommendDisplayCard({ recommendedQuote = {} }) {
  const {
    gross_premium,
    sum_insured,
    product: {
      name,
      company: { alias },
    },
  } = recommendedQuote;

  const { logo: logoSrc } = useSelector(selectCompany(alias));

  const { groupCode } = useParams();

  const urlQuery = useUrlQuery();

  const enquiryId = urlQuery.get("enquiryId");

  return (
    <div>
      <div
        css={`
          background-color: #cdcece;
          display: flex;
          align-items: center;
          justify-content: start;
          padding: 10px 0;
          margin: 10px;
          border-radius: 10px;
        `}
      >
        <div
          css={`
            width: 70px;
          `}
        >
          <img
            src={logoSrc}
            alt={alias}
            css={`
              max-width: 100%;
            `}
          />
        </div>
        <div
          css={`
            margin-left: 10px;
            font-weight: 900;
          `}
        >
          {name}
        </div>
      </div>
      <div
        css={`
          display: flex;
          padding: 0 10px;

          & > div {
            flex: 1;
          }
        `}
      >
        <div>
          <div
            css={`
              font-weight: 900;
            `}
          >
            Cover:
          </div>
          <div
            css={`
              color: var(--abc-red);
              font-weight: 900;
            `}
          >
            {amount(sum_insured)}
          </div>
        </div>
        <div>
          <div
            css={`
              font-weight: 900;
            `}
          >
            Premium:
          </div>
          <div
            css={`
              color: var(--abc-red);
              font-weight: 900;
            `}
          >
            {amount(gross_premium)} / Year
          </div>
        </div>
      </div>
      <div
        css={`
          display: flex;
          justify-content: center;
          border-top: 1px solid #cdcece;
          margin: 10px;
        `}
      >
        <Link
          to={`/recommendation-result/${groupCode}?enquiryId=${enquiryId}&source=cache`}
        >
          <div
            css={`
              display: flex;
              align-items: center;
              margin: 10px;
              margin-bottom: 0;
            `}
          >
            <div
              css={`
                color: #000;
                font-weight: 900;
              `}
            >
              View More
            </div>
            <i
              className="fa fa-angle-down"
              css={`
                color: gray;
                margin-left: 1em;
                background-color: #cdcece;
                height: 20px;
                width: 20px;
                display: flex;
                align-items: center;
                justify-content: center;
                border-radius: 50%;
                font-weight: 900;
              `}
            />
          </div>
        </Link>
      </div>
    </div>
  );
}
