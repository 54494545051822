import Score0 from "./0.svg";
import Score1 from "./1.svg";
import Score2 from "./2.svg";
import Score3 from "./3.svg";
import Score4 from "./4.svg";
import Score5 from "./5.svg";
import Score6 from "./6.svg";
import Score7 from "./7.svg";
import Score8 from "./8.svg";
import Score9 from "./9.svg";
import Score10 from "./10.svg";

export const ScoreSvgs = [
  Score0,
  Score1,
  Score2,
  Score3,
  Score4,
  Score5,
  Score6,
  Score7,
  Score8,
  Score9,
  Score10,
];
