/* eslint-disable jsx-a11y/anchor-is-valid */
import { useEffect, useState } from "react";
// import dummyLogo from "../../../../assets/logos/Chola logo.png"
import { Col, Collapse, Container, Row } from "react-bootstrap";
import { SingleBlock } from "../QuotesComponents";
import SecureLS from "secure-ls";
import {
  downArrow,
  hidePlans,
  seePlan,
  cashless,
} from "../../../../assets/index";
import "./QuoteCard.scss";
import {
  ICName,
  ProductCover,
  quoteCardDataset,
  RadioInput,
  RadioLabel,
} from "./QuoteContent/QuotesCardComponents";
import SolidBuyButton from "../Button/SolidBuyButton";
import {
  removeQuotesForCompare,
  saveSelectedPlan,
  setQuotesForCompare,
  setQuotesOnCompare,
  saveProductDiscountResponse,
  addSelectedQuote,
  createCartItem,
  updateCartItem,
  setSelectedQuotes,
  setSelectedRiders,
  addSelectedRiders,
} from "../../quotePage.slice";
import SubContent from "./QuoteContent/SubContent";
import useQuoteCard from "./useQuoteCard";
import { useSelector } from "react-redux";
import { useCartProduct } from "../../../Cart";
import { useParams } from "react-router";
import "styled-components/macro";
const QuoteCard = ({
  handleClick,
  item,
  handleSeeDetails,
  isRecommended,
  lastQuote,
}) => {

  const {
    dispatch,
    show,
    setShow,
    checked,
    mergedQuotes,
    quotesForCompare,
    // activeCover,
    // setActiveCover,
  } = useQuoteCard({ item });
  console.log('test102',mergedQuotes)
  const [activeCover, setActiveCover] = useState(0);
  const { multiYear } = useSelector(state => state.quotePage.filters);


  let additionalPremium = 0;

  mergedQuotes[0]?.mandatory_riders[activeCover]?.forEach(element => {
    additionalPremium += element.total_premium;
  });

  const [isLoading, setIsLoading] = useState(false);

  const { cartItems } = useSelector(state => state.quotePage);

  const { groupCode: selectedGroup } = useParams();

  

  const { memberGroups } = useSelector(state => state.greetingPage);

  const members = memberGroups[selectedGroup];
  const ls = new SecureLS();

  const { addProduct, isCartProductLoading } = useCartProduct(selectedGroup);

  const handleSeeDetailsClick = clickedFrom => {
    handleSeeDetails(
      {
        quote: mergedQuotes[0],
        activeSum: activeCover,
      },
      clickedFrom,
    );
    const selectedPlan = {
      company_alias: mergedQuotes[0]?.company_alias,
      logo: mergedQuotes[0]?.logo,

      product: mergedQuotes[0]?.product,
      total_premium: mergedQuotes[0]?.total_premium[activeCover],
      premium: mergedQuotes[0]?.premium[activeCover],
      sum_insured: mergedQuotes[0]?.sum_insured[activeCover],
      tax_amount: mergedQuotes[0]?.tax_amount[activeCover],
      tenure: mergedQuotes[0]?.tenure[activeCover],
    };
    console.log({
      ...selectedPlan,
      product_id: selectedPlan.product?.id,
    }, "=================");
    dispatch(saveSelectedPlan({
      ...selectedPlan,
      product_id: selectedPlan.product?.id,
    }));
  };

  const handleBuyNowClick = () => {
    setIsLoading(true);
    const selectedPlan = {
      // company_alias: mergedQuotes[0]?.company_alias,
      // logo: mergedQuotes[0]?.logo,
      product: mergedQuotes[0]?.product,
      total_premium: mergedQuotes[0]?.total_premium[activeCover],
      // premium: mergedQuotes[0]?.premium[activeCover],
      sum_insured: mergedQuotes[0]?.sum_insured[activeCover],
      tax_amount: mergedQuotes[0]?.tax_amount[activeCover],
      tenure: mergedQuotes[0]?.tenure[activeCover],
    };

    addProduct({
      ...selectedPlan,
      product_id: selectedPlan.product?.id,
      premium: selectedPlan.total_premium,
      group_id: parseInt(selectedGroup),
      service_tax: selectedPlan.tax_amount,
      riders: mergedQuotes[0]?.mandatory_riders[activeCover].map(rider => ({
        ...rider,
        rider_id: rider.id,
      })),
    }).then(handleClick);
    setIsLoading(false);
  };

  const tenure = parseInt(multiYear) === 1 ? "" : parseInt(multiYear);

  if (!mergedQuotes?.[0]?.features?.[activeCover]) return null;

  return (
    <div
      className="quotecard"
      css={`
        position: relative;
      `}
    >
      <div
        css={`
          display: ${isRecommended ? "block" : "none"};
          position: absolute;
          right: 9px;
          padding: 4px 20px;
          top: -29px;
          border-radius: 20px 20px 0px 0px;
          background: #f5bc00;
          color: #000;
          font-weight: 900;
          font-size: 14px;
          & span {
            border-radius: 13px;
            padding: 3px 7px;
            background: white;
            position: relative;
          }
        `}
      >
        Recommended: <span> {isRecommended}/10</span>
      </div>
      <SingleBlock
        css={`
          margin-top: ${isRecommended ? "45px !important" : "19px !important"};
          margin-bottom: ${isRecommended && "-8px !important"};
          margin-bottom: ${isRecommended && lastQuote && "-32px !important"};
        `}
      >
        <Row className="margin_top_feature">
          {/* Logo and title */}
          <Col md={5}>
            <Row>
              <Col md={10} className="product_logo_title d-flex align-items-center">
              <div className="fit_logo_common">
              <img
                  // src={mergedQuotes[0]?.logo}
                 
                  src={mergedQuotes[0]?.logo}
                  id="compare_one_logo"
                  alt="icon_company"
                />
              </div>
                
                <ICName>{mergedQuotes[0]?.product.name}</ICName>
              </Col>
            </Row>
            {/* Logo and title end */}
            {/* Product cover  */}
            <Row className="row_cover_premium_product">
              <Col
                md={5}
                className="no_padding"
                css={`
                  position: relative;
                  top: 4px;
                `}
              >
                {mergedQuotes[0]?.sum_insured.length > 1 ? (
                  <ProductCover className="quotecard__cover">
                    <p className="line_height_p_product">Cover</p>
                    <p
                      className="p_line_sub_product dropdown-product"
                      id="compare_one_cover"
                    >
                      <i class="fa fa-inr" aria-hidden="true"></i>{" "}
                      <select
                        className="p_line_sub_product"
                        id="compare_one_cover"
                        onChange={e => setActiveCover(e.target.value)}
                      >
                        {mergedQuotes[0]?.sum_insured.map((data, i) => {
                          return (
                            <option value={i} key={i}>
                              {parseInt(data).toLocaleString("en-In")}
                            </option>
                          );
                        })}
                      </select>
                    </p>
                  </ProductCover>
                ) : (
                  <ProductCover className="quotecard__cover">
                    <p className="line_height_p_product">Cover</p>
                    <p className="p_line_sub_product" id="compare_one_cover">
                      <i className="fa fa-inr"></i>{" "}
                      {parseInt(
                        mergedQuotes[0]?.sum_insured[activeCover],
                      ).toLocaleString("en-In")}
                    </p>
                  </ProductCover>
                )}
              </Col>
              <Col
                md={7}
                className="no_padding"
                css={`
                  position: relative;
                  top: 4px;
                `}
              >
                <p className="line_height_p_product">Premium</p>
                <p className="p_line_sub_product">
                  <i className="fa fa-inr"></i>{" "}
                  {parseInt(
                    mergedQuotes[0]?.total_premium[activeCover] + additionalPremium,
                  ).toLocaleString("en-In")}
                  <span>
                    /{tenure} {tenure > 1 ? "years" : "year"}
                  </span>
                </p>
              </Col>
              {/* Product cover end */}
            </Row>
          </Col>
          {/* plans data end */}
          <Col md={7} className="margin_internal_card_copay">
            <Row>
              {mergedQuotes.length > 0 &&
                [
                  ...mergedQuotes?.[0]?.features?.[activeCover],
                  {
                    name: "Cashless Hospital",
                    value: "16+",
                    description:
                      "A specific percent of claim amount is paid by the Insured person in case the treatment is taken at a hospital not mentioned in the Network Hospitals list.",
                    icon: cashless,
                  },
                ]?.filter((data) => data.is_featured_on_card || data.name === "Cashless Hospital").map((data, index) => {
                  if (data.name === "Cashless Hospital") {
                    return quoteCardDataset(
                      {
                        ...data,
                        value:
                          mergedQuotes[0]?.cashlessHospitalsCount[activeCover],
                      },
                      index,
                      handleSeeDetailsClick,
                    );
                  }

                  if (data.is_featured_on_card)
                    return quoteCardDataset(data, index, handleSeeDetailsClick);
                  return null;
                })}
              <Col
                md={4}
                className="no_padding"
                css={`
                  position: absolute;
                  right: 0;
                  bottom: 20px;
                `}
              >
                <SolidBuyButton
                  disabled={isCartProductLoading}
                  value={
                    isLoading ? (
                      <i className="fas fa-circle-notch rotate" />
                    ) : (
                      "Buy Now"
                    )
                  }
                  onClick={handleBuyNowClick}
                // onClick={() => {
                //   setIsLoading(true);
                //   ls.set("cover", mergedQuotes[0]?.sum_insured[activeCover]);
                //   const selectedPlan = {
                //     company_alias: mergedQuotes[0]?.company_alias,
                //     logo: mergedQuotes[0]?.logo,
                //     product: mergedQuotes[0]?.product,
                //     gross_premium:
                //       mergedQuotes[0]?.gross_premium[activeCover],
                //     premium: mergedQuotes[0]?.premium[activeCover],
                //     sum_insured: mergedQuotes[0]?.sum_insured[activeCover],
                //     tax_amount: mergedQuotes[0]?.tax_amount[activeCover],
                //     tenure: mergedQuotes[0]?.tenure[activeCover],
                //   };

                //   if (cartItems.length > 0) {
                //     dispatch(
                //       updateCartItem(
                //         {
                //           members,
                //           cartId: cartItems[0].id,
                //           ...selectedPlan,
                //           product_id: selectedPlan.product?.id,
                //           premium: selectedPlan.gross_premium,
                //         },
                //         cartId => {
                //           dispatch(saveSelectedPlan(selectedPlan));
                //           dispatch(
                //             addSelectedQuote({ cartId, ...selectedPlan }),
                //           );
                //           dispatch(saveProductDiscountResponse([]));
                //           dispatch(addSelectedRiders([]));
                //           handleClick();
                //           setIsLoading(false);
                //         },
                //       ),
                //     );
                //     return;
                //   }

                //   dispatch(
                //     createCartItem(
                //       {
                //         members,
                //         ...selectedPlan,
                //       },
                //       cartId => {
                //         dispatch(saveSelectedPlan(selectedPlan));
                //         dispatch(
                //           addSelectedQuote({ cartId, ...selectedPlan }),
                //         );
                //         dispatch(saveProductDiscountResponse([]));
                //         dispatch(addSelectedRiders([]));
                //         handleClick();
                //         setIsLoading(false);
                //       },
                //     ),
                //   );
                // }}
                />
              </Col>
            </Row>
          </Col>
          {/* plans data end */}
        </Row>
        <Col md={12} className="mt-12">
          <hr className="hr_margin_que" />
        </Col>
        <Container fluid className="box_post_back margin">
          <Row>
            <Col
              md={4}
              xs={5}
              id="accordionExample"
              style={{ visibility: mergedQuotes.length < 2 && "hidden" }}
            >
              <div id="headingOne">
                <p
                  className="p_more_plans_arrow"
                  onClick={() => {
                    setShow(!show);
                  }}
                  aria-controls="collapseOne"
                  aria-expanded={show}
                >
                  {mergedQuotes?.slice(1).length}{" "}{mergedQuotes?.slice(1).length >= 2 ? "More Plans" : "More Plan"}
                  <img
                    className="p_more_plans_arrow_img_four"
                    src={downArrow}
                    alt="downArrow"
                    css={`
                      transform: ${show && "rotate(180deg)"};
                    `}
                  />
                </p>
              </div>
            </Col>
            <Col md={5} xs={5} className="text-center padding_see_details_plan">
              <a onClick={handleSeeDetailsClick}>
                <p className="p_more_plans_arrow">See Details</p>
                <img
                  className="p_more_plans_arrow_img"
                  src={seePlan}
                  alt="seePlan"
                />
              </a>
            </Col>
            <Col md={3} xs={5} className="pad_left text-right">
              <div className="agreement-checkbox margin_top_checkbox_card">
                <div>
                  <RadioInput
                    type="checkbox"
                    id={`compare_${mergedQuotes[0]?.product.id}${mergedQuotes[0]?.sum_insured[activeCover]}`}
                    className="compare-checkbox"
                    checked={checked}
                    onChange={() => {
                      dispatch(setQuotesOnCompare());

                      if (!checked) {
                        const numberOfPlans = window.matchMedia(
                          "(max-width: 1023px)",
                        ).matches
                          ? 2
                          : 3;
                        dispatch(
                          setQuotesForCompare([
                            `${mergedQuotes[0]?.product.id}${mergedQuotes[0]?.sum_insured[activeCover]}`,
                            numberOfPlans,
                          ]),
                        );
                      } else {
                        dispatch(
                          removeQuotesForCompare(
                            `${mergedQuotes[0]?.product.id}${mergedQuotes[0]?.sum_insured[activeCover]}`,
                          ),
                        );
                      }
                    }}
                  />

                  <RadioLabel
                    //dynamic id
                    htmlFor={`compare_${mergedQuotes[0]?.product.id}${mergedQuotes[0]?.sum_insured[activeCover]}`}
                  >
                    Add to Compare
                  </RadioLabel>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
        <Collapse in={show}>
          <div id="collapseOne">
            <div className="card-body card_body_padding margin_bottom_more_plan_card">
              <Col md={12}>
                {/* plans will be clubbed here */}
                {mergedQuotes?.slice(1).map((item, index) => (
                  <SubContent
                    addProduct={addProduct}
                    key={index}
                    id={index}
                    quoteCardData={item}
                    quotesForCompare={quotesForCompare}
                    handleClick={handleClick}
                    handleSeeDetails={handleSeeDetails}
                  />
                ))}
              </Col>
            </div>
            <Row className="text-center margin_bottom_hide_plan_btn_row">
              <a className="btn btn_hide_plans" onClick={() => setShow(false)}>
                {mergedQuotes?.slice(1).length >= 2 ? "Hide Plans" : "Hide Plan"}
                <img
                  src={hidePlans}
                  className="img_hide_btn"
                  alt="img_hide_btn"
                />
              </a>
            </Row>
          </div>
        </Collapse>
      </SingleBlock>
    </div>
  );
};

export default QuoteCard;

// /* eslint-disable jsx-a11y/anchor-is-valid */
// import { useEffect, useState } from "react";

// import { Col, Collapse, Container, Row } from "react-bootstrap";
// import { SingleBlock } from "../QuotesComponents";

// import { downArrow, hidePlans, seePlan } from "../../../../assets/index";
// import "./QuoteCard.scss";
// import {
//   ICName,
//   plansData,
//   ProductCover,
//   quoteCardDataset,
//   RadioInput,
//   RadioLabel,
// } from "./QuoteContent/QuotesCardComponents";
// import SolidBuyButton from "../Button/SolidBuyButton";
// import {
//   removeQuotesForCompare,
//   saveSelectedPlan,
//   setQuotesForCompare,
//   setQuotesOnCompare,
//   saveProductDiscountResponse,
//   addSelectedQuote,
// } from "../../quotePage.slice";
// import SubContent from "./QuoteContent/SubContent";
// import useQuoteCard from "./useQuoteCard";
// import { useSelector } from "react-redux";

// const QuoteCard = ({ handleClick, item, handleSeeDetails }) => {
//   const {
//     dispatch,
//     show,
//     setShow,
//     checked,
//     mergedQuotes,
//     quotesForCompare,
//     activeCover,
//     setActiveCover,
//   } = useQuoteCard({ item });

//   const { selectedGroup } = useSelector((state) => state.quotePage);

// 	return (
// 		<Col md={12} className="quotecard">
// 			<SingleBlock>
// 				<Row className="margin_top_feature">
// 					{/* Logo and title */}
// 					<Col md={5}>
// 						<Row>
// 							<Col md={10} className="product_logo_title">
// 								<img
// 									src={mergedQuotes[0]?.logo}
// 									className="icon img_IC_width"
// 									id="compare_one_logo"
// 									alt="icon_company"
// 								/>
// 								<ICName>{mergedQuotes[0]?.product.name}</ICName>
// 							</Col>
// 						</Row>
// 						{/* Logo and title end */}
// 						{/* Product cover  */}
// 						<Row className="row_cover_premium_product">
// 							<Col md={5} className="no_padding">
// 								{mergedQuotes[0]?.sum_insured.length > 1 ? (
// 									<ProductCover className="quotecard__cover">
// 										<p className="line_height_p_product">Cover</p>
// 										<p
// 											className="p_line_sub_product dropdown-product"
// 											id="compare_one_cover"
// 										>
// 											<i class="fa fa-inr" aria-hidden="true"></i>{" "}
// 											<select
// 												className="p_line_sub_product"
// 												id="compare_one_cover"
// 												onChange={(e) => setActiveCover(e.target.value)}
// 											>
// 												{mergedQuotes[0]?.sum_insured.map((data, i) => {
// 													return <option value={i}>{data}</option>;
// 												})}
// 											</select>
// 										</p>
// 									</ProductCover>
// 								) : (
// 									<ProductCover className="quotecard__cover">
// 										<p className="line_height_p_product">Cover</p>
// 										<p className="p_line_sub_product" id="compare_one_cover">
// 											<i className="fa fa-inr"></i>
// 											{mergedQuotes[0]?.sum_insured[activeCover]}
// 										</p>
// 									</ProductCover>
// 								)}
// 							</Col>
// 							<Col md={7} className="no_padding">
// 								<p className="line_height_p_product">Premium</p>
// 								<p className="p_line_sub_product">
// 									<i className="fa fa-inr"></i>{" "}
// 									{mergedQuotes[0]?.total_premium[activeCover]}
// 									<span>/ year</span>
// 								</p>
// 							</Col>
// 							{/* Product cover end */}
// 						</Row>
// 					</Col>
// 					{/* plans data end */}
// 					<Col md={7} className="margin_internal_card_copay">
// 						<Row>
// 							{plansData.map((data, index) => quoteCardDataset(data, index))}
// 							<Col md={4} className="no_padding">

// 								<a href="#" data-toggle="modal" data-target="#m-md-review">
// 									<SolidBuyButton
// 										value={"Buy Now"}
// 										onClick={() => {
// 											handleClick();
// 											const SelectedPlan = {
// 												company_alias: mergedQuotes[0]?.company_alias,
// 												logo: mergedQuotes[0]?.logo,
// 												product: mergedQuotes[0]?.product,
// 												total_premium:
// 													mergedQuotes[0]?.total_premium[activeCover],
// 												premium: mergedQuotes[0]?.premium[activeCover],
// 												sum_insured: mergedQuotes[0]?.sum_insured[activeCover],
// 												tax_amount: mergedQuotes[0]?.tax_amount[activeCover],
// 												tenure: mergedQuotes[0]?.tenure[activeCover],
// 											};

// 											dispatch(saveSelectedPlan(SelectedPlan));
// 											dispatch(addSelectedQuote(SelectedPlan));
// 											dispatch(saveProductDiscountResponse([]));
// 										}}
// 									/>
// 								</a>
// 							</Col>
// 						</Row>
// 					</Col>
// 					{/* plans data end */}
// 				</Row>
// 				<Col md={12} className="mt-12">
// 					<hr className="hr_margin_que" />
// 				</Col>
// 				<Container fluid className="box_post_back margin">
// 					<Row>
// 						<Col
// 							md={4}
// 							xs={5}
// 							id="accordionExample"
// 							style={{ visibility: mergedQuotes.length < 2 && "hidden" }}
// 						>
// 							<div id="headingOne">
// 								<p
// 									className="p_more_plans_arrow"
// 									onClick={() => {
// 										setShow(!show);
// 									}}
// 									aria-controls="collapseOne"
// 									aria-expanded={show}
// 								>
// 									{item?.slice(1).length} More Plans
// 									<img
// 										className="p_more_plans_arrow_img_four"
// 										src={downArrow}
// 										alt="downArrow"
// 									/>
// 								</p>
// 							</div>
// 						</Col>
// 						<Col md={5} xs={5} className="text-center padding_see_details_plan">
// 							<a
// 								onClick={(e) => {
// 									e.preventDefault();
// 									handleSeeDetails();
// 								}}
// 							>
// 								<p className="p_more_plans_arrow">See Details</p>
// 								<img
// 									className="p_more_plans_arrow_img"
// 									src={seePlan}
// 									alt="seePlan"
// 								/>
// 							</a>
// 						</Col>
// 						<Col md={3} xs={5} className="pad_left text-right">
// 							<div className="agreement-checkbox margin_top_checkbox_card">
// 								<div>
// 									<RadioInput
// 										type="checkbox"
// 										id={`compare_${mergedQuotes[0]?.product.id}${mergedQuotes[0]?.sum_insured[activeCover]}`}
// 										className="compare-checkbox"
// 										checked={checked}
// 										onChange={() => {
// 											dispatch(setQuotesOnCompare());

//                       if (!checked) {
//                         const numberOfPlans = window.matchMedia(
//                           "(max-width: 1023px)"
//                         ).matches
//                           ? 2
//                           : 3;
//                         dispatch(
//                           setQuotesForCompare([
//                             `${mergedQuotes[0]?.product.id}${mergedQuotes[0]?.sum_insured[activeCover]}`,
//                             numberOfPlans,
//                           ])
//                         );
//                       } else {
//                         dispatch(
//                           removeQuotesForCompare(
//                             `${mergedQuotes[0]?.product.id}${mergedQuotes[0]?.sum_insured[activeCover]}`
//                           )
//                         );
//                       }
//                     }}
//                   />

//                   <RadioLabel
//                     //dynamic id
//                     htmlFor={`compare_${mergedQuotes[0]?.product.id}${mergedQuotes[0]?.sum_insured[activeCover]}`}
//                   >
//                     Add to Compare
//                   </RadioLabel>
//                 </div>
//               </div>
//             </Col>
//           </Row>
//         </Container>
//         <Collapse in={show}>
//           <div id="collapseOne">
//             <div className="card-body card_body_padding margin_bottom_more_plan_card">
//               <Col md={12}>
//                 {/* plans will be clubbed here */}
//                 {mergedQuotes?.slice(1).map((item, index) => (
//                   <SubContent
//                     id={index}
//                     quoteCardData={item}
//                     quotesForCompare={quotesForCompare}
//                     handleClick={handleClick}
//                     handleSeeDetails={handleSeeDetails}
//                   />
//                 ))}
//               </Col>
//             </div>
//             <Row className="text-center margin_bottom_hide_plan_btn_row">
//               <a className="btn btn_hide_plans" onClick={() => setShow(false)}>
//                 Hide Plans
//                 <img
//                   src={hidePlans}
//                   className="img_hide_btn"
//                   alt="img_hide_btn"
//                 />
//               </a>
//             </Row>
//           </div>
//         </Collapse>
//       </SingleBlock>
//     </Col>
//   );
// };

// export default QuoteCard;
