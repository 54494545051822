import styled from "styled-components";

export const Label = styled.p`
	color: #000;
	text-align: left !important;
	margin-bottom: 10px;
	padding-bottom: 9px;
	padding-top: 10px;
	display: inline-block;
	fontSize: "25px"
	font-weight: 900;
	line-height: 31px;

	@media (max-width: 767px) {
		// margin-top: -20px;
		margin-bottom: 25px;
		color: #000;
		font-size: 20px;
    line-height: 26px;
	}
`;

export const SubLabel = styled.p`
  margin: -18px 12px 12px 5px;
  position: relative;
  color: #000;
  font-size: 23px;
  font-weight: 900;
`;

export const ErrorMessage = styled.div`
  position: relative;
  margin-top: 17px;
  right: -5px;
  display: block;
  color: #d43d3d;
  font-size: 14px;
  top: -20px;
`;
