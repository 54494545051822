import { Dropdown } from "react-bootstrap";
import styled from "styled-components";

export const EditMembersWrap = styled.div`
  background-color: #fff;
  position: absolute;
  top: 0;
  left: 0;

  min-height: 100vh;
  width: 100%;

  z-index: 999;

  display: flex;
  flex-direction: column;
  align-items: center;

  padding: 10px;
`;

export const LogoWrap = styled.div`
  width: calc(100% - 30px);
  border-bottom: 1px solid #ccc;
  padding-bottom: 10px;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const Title = styled.div`
  font-size: 19px;
  font-weight: 900;
  background-image: linear-gradient(to right, #ffe7e7 26%, #fff 45%);
  padding: 12px;
  border-radius: 8px;
  color: #202026;
`;

export const CloseButton = styled.button`
  background: #f8f8f8;
  height: 36px;
  width: 36px;
  border-radius: 50px;
  border: 1px solid #eaedf2;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const CloseImage = styled.img``;

export const StyledDropDownToggle = styled(Dropdown.Toggle)`
  border: 1px solid #f4f6fa;
  border-radius: 4px;
  background: #f4f6fa;
  padding: 6px 10px;
  padding-right: 27px;
  height: 37px;
  transition: all 0.2s;
  color: #7c7f97;

  font-size: 12px;

  width: 123px;
  position: relative;

  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;

  &::after {
    vertical-align: 2px;

    border-left: 5px solid transparent;
    border-right: 5px solid transparent;
    border-top: 5px solid #bbbbbb;

    position: absolute;
    top: 46%;
    right: 10%;
  }

  &:hover {
    box-shadow: 0 2px 12px 5px rgb(35 61 99 / 11%);
    border-radius: 4px;
  }

  &:focus {
    box-shadow: none;
  }

  &:active {
    box-shadow: 1px 2px 1px 0 rgb(0 0 0 / 30%);
  }
`;

export const StyledDropDownMenu = styled(Dropdown.Menu)`
  width: 123px;
  min-width: 0px;
  inset: -63px 0 0 0 !important;
  overflow: auto;
  background: #f4f6fa;
`;

export const StyledDropDownItem = styled(Dropdown.Item)`
    height: 37px;
    padding-left: 12px;
    color: #7c7f97;
    font-size: 12px;
    display: flex;
    align-items: center;
`;
