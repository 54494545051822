import styled from "styled-components";

export const UnorderedList = styled.ul`
  list-style: none;
  position: relative;
  display: block;
  overflow: hidden;
  margin: 0;
  padding: 0;
  border: 0;
  outline: none;
  font-size: 13px;

  text-align: left;

  border-radius: 5px;

  height: 130px;
  overflow: hidden;
  & li {
    height: 130px;
    overflow: hidden;
    position: absolute;
    width: 100%;
    overflow: auto;
    padding: 8px 18px 2px;
    box-sizing: border-box;

    opacity: 0;
    transition: all 0.5s;
 
  }
  & .active {
    z-index: 1;
    top: 0;
    left: 0;
    opacity: 1;
    -webkit-transform: scale(1, 1);
    -webkit-transform: rotate(0deg);
  }

  & .typography {
    color: #666;

    & h1 {
      font-size: 25px;
      line-height: 60px;
      /* text-shadow: 3px 3px rgb(0 0 0 / 10%); */
      font-weight: 900;
      margin: 0 27px 1px;
      color: #000 !important;
      @media (max-width: 767px) {
        font-size: 20px;
        line-height: 22px;
        margin-left: 32px;
        margin-bottom: 12px;
      }
    }

    & p {
      margin: -1px 0px 0 28px;
      font-weight: 400 !important;
      padding: 0;
      line-height: 20px;
      text-align: left;
      font-size: 16px;
      color: #3e3737;
      @media (max-width: 767px) {
        margin: -3px 0px 0 32px;
        padding: 0;
        line-height: 20px;
        text-align: left;
        font-size: 14px;
        color: #7b7b7b;
        width: 100%;
      }
    }
  }
`;

export const RadioInput = styled.input`
  &:not(:checked),
  &:checked {
    position: absolute;
    left: -9999px;
    width: 0;
    height: 0;
    visibility: hidden;
  }
  &:checked + label {
    margin-bottom: 0;
    color: #000;
    opacity: 1;
    border: 2px solid #c7222a;
    font-size: 15px;
    background-color: #fffafa;
  }
`;

export const RadioLabel = styled.label`
  transition: all 0.3s linear 0s;
  border-radius: 5px;
  background: #fff;
  box-shadow: 5px 5px 10px rgb(0 75 131 / 9%);
  border: #dfe1f2 0.8px solid;
  color: #000;
  opacity: 1;
  font-size: 15px;
  /* padding: 11px 25px; */
  border-radius: 10px;
  width: 247px;
  height: 66px;
  margin-top: -12px;
  margin-bottom: unset !important;
  margin-left: 42px;
  position: relative;
  & span {
    position: relative;
    font-size: 18px;
    left: -49px;
    left: ${props => props.okay && "-75px"};

    font-weight: 900;
  }
  ${props =>
    props.itemsCentered &&
    "display: inline-flex;align-items: center; justify-content: center;"};

  @media (max-width: 767px) {
    margin-bottom: 25px !important;
    width: 100% !important;
    padding: 9px 25px !important;
    display: flex;
    justify-content: space-between;
    // flex-direction: row-reverse;
    & span {
      left: unset !important;
    }
  }
`;

export const RadioLabelImage = styled.img`
  /* float: left; */
  /* margin: 4px 3px 0 -4px; */
  position: absolute;
  height: 51px;
  right: 11px;
`;
