import React, { useState } from "react";
import styled from "styled-components/macro";

const Form3CheckBox = ({
  code,
  title,
  type,
  handleChange,
  checked,
  disabled,
  form3,
}) => {
  const handleChecked = () => {
    handleChange(code, checked, type);
  };
  return (
    <>
      <input
        className="inp-cbx"
        id={code}
        type="checkbox"
        checked={checked || undefined}
        onClick={handleChecked}
        onChange={() => {}}
        disabled={disabled || undefined}
      />
      <label
        className="cbx"
        htmlFor={code}
        css={`
          @media (max-width: 767px) {
            margin: 4px -9px 0px !important;
            padding: unset !important;
          }
        `}
      >
        <span
          css={`
            width: ${form3 && "20px !important"};
            height: ${form3 && "20px !important"};
          `}
          // css={`
          //   @media (max-width: 767px) {
          //     ${title === "Daughter" &&
          //     code !== "daughter" &&
          //     "visibility: hidden;"}
          //     ${title === "Son" && code !== "son" && "visibility: hidden;"}
          //   }
          // `}
        >
          <svg width="12px" height="10px">
            <use xlinkHref="#check"></use>
          </svg>
        </span>
        <span
          className="cbx__title"
          css={`
            @media (max-width: 767px) {
              position: relative !important;
              top: 2px !important;
            }
          `}
        >
          {title}
        </span>
      </label>

      <svg className="inline-svg">
        <symbol id="check" viewBox="0 0 12 10">
          <polyline points="1.5 6 4.5 9 10.5 1"></polyline>
        </symbol>
      </svg>
    </>
  );
};

export default Form3CheckBox;
