import React from "react";
import { Col } from "react-bootstrap";
import styled from "styled-components/macro";
import claim from "../../../../assets/images/claim_r.png";
import * as mq from "../../../../utils/mediaQueries";

function FundamentalCheck({
  title,
  value,
  handleInfoClick = () => {},
  showInfo,
}) {
  return (
    <Col
      css={`
        padding-right: 6px;
        ${mq.mobile} {
          min-width: 100%;
          margin-bottom: 1rem;
        }
      `}
    >
      <div
        css={`
          position: relative;
          font-size: 16px;
          display: flex;
          justify-content: center;
          flex-direction: column;
          box-shadow: 0px 10px 40px 0px rgb(60 67 113 / 5%);
          /* padding: 30px 0 30px 20px; */
          padding: 0px 0 0px 20px;
          background-color: #fff;

          &::before {
            content: "";
            position: absolute;
            left: 0;
            top: 0;
            height: 100%;
            width: 6px;
            border-radius: 10px;
            background-color: var(--yellow-one);
          }

          ${mq.mobile} {
            padding: 15px 0 15px 21px;
          }
        `}
      >
        {showInfo && (
          <button
            css={`
              position: absolute;
              /* right: 1rem;
              top: 1rem; */
              right: 0.2em;
              top: 0.1em;
            `}
            onClick={handleInfoClick}
          >
            <i
              className="fa fa-exclamation"
              css={`
                height: 20px;
                width: 20px;
                background-color: var(--abc-red);
                display: flex;
                align-items: center;
                justify-content: center;
                border-radius: 50%;
                color: #fff;
                font-size: 11px;
              `}
            />
          </button>
        )}

        <p
          css={`
            color: var(--font-gray-five);
          `}
        >
          {title}
        </p>
        <p
          css={`
            /* font-size: 36px; */
            font-size: 28px;
            /* margin-top: 1rem; */
            margin-top: 0.3em;

            ${mq.mobile} {
              font-size: 30px;
            }
          `}
        >
          {value}
        </p>
        <div
          css={`
            position: absolute;
            right: 0;
            bottom: 0;
            width: 90px;
            z-index: -1;
          `}
        >
          {/* <img
            src={claim}
            alt="claim"
            css={`
              max-width: 100%;
            `}
          /> */}
        </div>
      </div>
    </Col>
  );
}

// const FundamentalCheck = ({ delayAnimation, value, title }) => {
//   return (
//     <Col
//       lg={4}
//       className="mb_30_re_m_i aos-init aos-animate"
//       data-aos="fade-up"
//       data-aos-delay={delayAnimation}
//     >
//       <SinglePr>
//         <div className="pr-header">
//           <img
//             src={claim}
//             alt=""
//             className="icon iconFundamental aos-init aos-animate"
//             data-aos="fade-right"
//           />
//           <Title className="p_we_re_m">
//             {title}
//             <button>
//               <i className="fa fa-exclamation exclamation_i"></i>
//             </button>
//           </Title>
//           <Price className="price p_we_re_m_s">{value}</Price>
//         </div>
//       </SinglePr>
//     </Col>
//   );
// };

export default FundamentalCheck;

export const SinglePr = styled.div`
  background: #fff;
  box-shadow: 0px 10px 40px 0px rgb(60 67 113 / 5%);
  position: relative;
  text-align: center;
  border-radius: 5px 5px 0 0;
  margin-top: 50px;
  overflow: hidden;

  &:before {
    content: "";
    width: 50px;
    height: 6px;
    border-radius: 5px 5px 0 0;
    background: #fff;
    position: absolute;
    top: 0;
    left: -50px;
    z-index: 1;
    transition: all 0.8s ease-out;
  }
  & .icon {
    transform: scale(1.3) translateX(80px);
    left: 192px;
    top: 77px;
    width: 78px;
    height: 72px;
  }
  & .icon {
    opacity: 1;
    position: absolute;
    left: -60px;
    top: 17%;
    z-index: -1;
  }

  & .iconFundamental {
    transform: scale(1.3) translateX(80px) !important;
    left: 192px;
    top: 99px;
    width: 78px;
    height: 72px;
  }

  & .pr-header {
    position: relative;
    z-index: 1;
    padding: 40px 0 32px;
    border-radius: 4px;
  }
  & .pr-header {
    border-left: 6px solid #f5bc00;
  }
  & .exclamation_i {
    background: #c72229;
    color: #fff;
    width: 20px;
    height: 20px;
    font-size: 11px;
    border-radius: 50px;
    padding: 6px 8px;
    position: absolute;
    right: 13px;
    top: 30px;
    cursor: pointer;
  }

  @media (max-width: 575px) {
    & .pr-header {
      position: relative;
      z-index: 1;
      padding: 20px 0 8px;
      border-radius: 4px;
    }
  }
`;

const Price = styled.p`
  text-align: left;
  margin: 25px 35px 0px;
  font-size: 43px;
  /* font-family: "PFEncoreSansPromed"; */
  color: #010101;

  @media (max-width: 575px) {
    text-align: left;
    margin: 16px 12px 0px;
    font-size: 21px;
    /* font-family: "PFEncoreSansPromed"; */
  }
`;
const Title = styled.h4`
  font-size: 18px;
  text-transform: uppercase;
  color: #202020;
  // margin-bottom: -15px;
  font-weight: 900;
  line-height: 24px;
  width: 90%;
  & i {
    right: 26px !important;
    top: 42px !important;
  }
`;
