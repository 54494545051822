import React, { useState, useRef } from "react";
import useOutsiteClick from "../../../../customHooks/useOutsiteClick";
import { v4 as uuidv4 } from "uuid";
import {
  Header,
  HeaderTitle,
  List,
  ListItem,
  Wrapper,
} from "../../../../components/Common/DropDownComponents/DropDownComponents";
import "styled-components/macro";
const GreetingFormDropdown = ({
  disabled,
  title,
  list,
  handleChange,
  type,
  selected,
  code,
  editM,
  redBorder,
}) => {
  const [isOpen, setIsOpen] = useState(false);

  const dropdownRef = useRef(null);
  useOutsiteClick(isOpen && dropdownRef, () => setIsOpen(false));

  const toggleList = () => {
    setIsOpen(!isOpen);
  };

  const handleSelect = value => {
    let modifiedValue;
    
    // else if(value.includes("months")) modifiedValue = 0.;
    handleChange(code, modifiedValue || value , type);
    setIsOpen(!isOpen);
  };

  const perfectDisplayName = (num) => {
    {console.log("selected",num)}
    if(num.includes("Select")) return num
    if(num.includes("Years") || num.includes("Year") || num.includes("months")) return num

    return num.includes(".")?`${num.slice(num.indexOf(".")+1,num.length)} months`:num===1?`${num} Year`:`${num} Years`
    
  }
  
  return (
    <label htmlFor={disabled && title}>
      <Wrapper editM ref={dropdownRef} className="GreetingDD__Wrapper">
        <Header
          css={`
            border: ${redBorder && "1px solid red !important"};
          `}
          onClick={toggleList}
          className={`${isOpen && "active"} GreetingDD__Header ${
            disabled && "disabled"
          }`}
        >
          <HeaderTitle
            className={`${isOpen && "active"} GreetingDD__Title ${
              disabled && "font-gray"
            }`}
          >

          {
            perfectDisplayName(selected.toString())
          }
            {/* {selected?.toString().startsWith("0")
              ? selected?.split(" ")[0].split(".")[1] + ` Month${selected?.split(" ")[0].split(".")[1] > 1 ? "s":""}`
              : selected === 1? selected + " Year" :selected + " Years"} */}
                </HeaderTitle>
        </Header>
        {isOpen && (
          <List
            style={{
              visibility: "visible",
              width: "174.562px",
              top: "44px",
            }}
            className="GreetingDD__List"
            css={`
              left: 0px;
              @media (max-width: 767px) {
                left: -1px;
              }
            `}
          >
            {list.map(data => {
              return (
                <ListItem
                  className={`${
                    data.title === selected && "active"
                  } GreetingDD__ListItem`}
                  onClick={() => handleSelect(data.title.toString())}
                  key={uuidv4()}
                >
                  {data.title}
                </ListItem>
              );
            })}
          </List>
        )}
      </Wrapper>
    </label>
  );
};
export default GreetingFormDropdown;
