import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import styled from "styled-components";
import "styled-components/macro";
import ViewPlanDetailModal from "./ViewPlanDetailModal";
import { setActiveIndex, setPlanDetails } from "../ProposalSections.slice";
import arrow from "./../../../../assets/images/arrow.png";
import care from "./../../../../assets/images/Care_Health.png";
import correct from "./../../../../assets/images/correct_icon.png";
import "./ProductSummary.scss";
const removeTotalPremium = cart => {
  let { totalPremium, ...y } = cart;
  return y;
};
const numToString = value => value.toLocaleString("en-IN");
const ProductSummary = ({ cart, setActive }) => {
  const [show, setShow] = useState(false);
  const { frontendData } = useSelector(state => state.frontendBoot);
  const { proposerDetails } = useSelector(state => state.greetingPage);
  const { planDetails } = useSelector(state => state.proposalPage);
  const dispatch = useDispatch();
  useEffect(() => {
    setShow(planDetails.show);
  }, [planDetails]);

  const prevCart = Object.values(removeTotalPremium(planDetails.prevCart));

  const content = (
    <>
      <div className="row">
        <div className="col-md-12 padding-none">
          <section
            className="margin_top_14 light"
            css={`
              padding: 10px !important;
            `}
          >
            {Object.values(removeTotalPremium(cart)).map((item, index) => (
              <>
                <div className="row" key={index}>
                  <div className="col-md-9">
                    <h5
                      className="text_title_filter p_modal_title_bg_filters_product product_summary__title"
                      style={{ textTransform: "capitalize" }}
                    >
                  
                      {item.group.members.map(member => member.replace(/_/g,"-") ).join(" + ")}
                    </h5>
                  </div>
                  <div className="col-md-3 text-right"></div>
                </div>
                <div className="rider-box_product_pro_medical">
                  <div
                    className="row_display_pro_review"
                    css={`
                      @media (max-width: 767px) {
                        margin-bottom: 10px !important;
                      }
                    `}
                  >
                    <div className="logo_add_review float_left_addon_c">
                      <img
                     src={
                      frontendData?.data?.companies?.[
                        item?.product?.company?.alias
                      ]?.logo
                        }
                        className="img_top_m_custom_medical"
                        alt="logo"
                      />
                    </div>

                    <div className="float_left_addon_c ">
                      <p className="paln_name_t_product_pro_medical">
                        {item.product.name}
                      </p>
                    </div>
                  </div>
                  <hr />
                  <div className="row">
                    <div className="col-md-6">
                      <p className="p_cover_medical_pop">Cover: </p>
                      <span className="p_cover_medical_pop_span addon_plan_d_inter_1_product_pro_f_mediacl">
                        <i className="fa fa-inr"></i>{" "}
                        {numToString(item.sum_insured)}
                      </span>
                    </div>
                    <div className="col-md-6">
                      <p className={`p_cover_medical_pop `}>Premium: </p>
                      <span
                        className={`p_cover_medical_pop_span ${
                          planDetails.isRenewed ? "revised-premium" : ""
                        }`}
                      >
                        <i className="fa fa-inr"></i>{" "}
                        {planDetails.isRenewed
                          ? numToString(prevCart[index].total_premium)
                          : numToString(item.total_premium)}{" "}
                        / {item.tenure > 1 ? item.tenure + " years" : "year"}{" "}
                      </span>
                    </div>
                    {planDetails.isRenewed ? (
                      <>
                        <div className="col-md-6"></div>
                        <div className="col-md-6">
                          <p className="p_cover_medical_pop revised-premium-title">
                            Revised Premium:{" "}
                          </p>
                          <span className="p_cover_medical_pop_span ">
                            <i className="fa fa-inr"></i>{" "}
                            {numToString(item.total_premium)} /{" "}
                            {item.tenure > 1 ? item.tenure + " years" : "year"}{" "}
                          </span>
                        </div>
                      </>
                    ) : (
                      <></>
                    )}

                    {/* <div className="col-md-6">
                  <p className="p_cover_medical_pop color_green">
                    Revised Premium:{" "}
                  </p>
                  <span className="p_cover_medical_pop_span">
                    <i className="fa fa-inr"></i> 15,225 / year
                  </span>
                </div> */}
                  </div>
                  {item.health_riders.length ? (
                    <div className="row bg_medical_box_row">
                      {item.health_riders.map(riders => (
                        <div className="col-md-6">
                          <img
                            src={correct}
                            className="display_in_m_medical"
                            alt="correct"
                          />{" "}
                          <span className="font_weight_normal_mediacl">
                            {riders.name}
                          </span>
                          <p></p>
                        </div>
                      ))}
                    </div>
                  ) : (
                    <></>
                  )}
                </div>
                {/* <br /> */}
                {item.addons.length ? (
                  <div class="row">
                    <div class="col-md-12  text-center">
                      <p class="bottom_addon_cover_medical">
                        Add-ons Coverages
                      </p>
                      <hr />
                    </div>
                  </div>
                ) : (
                  <></>
                )}

                {/* <br /> */}
                {item.addons.map((addOns, addOnIndex) => (
                  <div className="rider-box_product_pro_medical">
              
                    <div
                      className="row_display_pro_review"
                      css={`
                        @media (max-width: 767px) {
                          margin-bottom: 10px !important;
                        }
                      `}
                    >
                      <div className="logo_add_review float_left_addon_c">
                        <img
                          src={
                            frontendData.data.companies[
                              addOns.product.company.alias
                            ].logo
                          }
                          className="img_top_m_custom_medical"
                          alt="logo"
                        />
                      </div>

                      <div className="float_left_addon_c ">
                        <p className="paln_name_t_product_pro_medical">
                          {addOns.product.name}
                        </p>
                      </div>
                    </div>
                    <hr />
                    <div className="row">
                      <div className="col-md-6">
                        <p className="p_cover_medical_pop">Cover: </p>
                        <span className="p_cover_medical_pop_span addon_plan_d_inter_1_product_pro_f_mediacl">
                          <i className="fa fa-inr"></i>{" "}
                          {numToString(addOns.sum_insured)}
                        </span>
                      </div>
                      <div className="col-md-6">
                        <p className="p_cover_medical_pop">Premium: </p>
                        <span
                          className={`p_cover_medical_pop_span text_decoration_line_through ${
                            planDetails.isRenewed &&
                            prevCart[index]?.addons[addOnIndex]
                              .total_premium !== addOns.total_premium
                              ? "revised-premium"
                              : ""
                          }`}
                        >
                          <i className="fa fa-inr"></i>{" "}
                          
                          {numToString(
                            prevCart[index]
                              ? prevCart[index].addons[addOnIndex].total_premium
                              : addOns.total_premium,
                          )}{" "}
                          / year
                        </span>
                      </div>
                      {prevCart[index]?.addons[addOnIndex]?.total_premium !==
                        addOns.total_premium && planDetails.isRenewed ? (
                        <>
                          <div className="col-md-6"></div>
                          <div className="col-md-6">
                            <p className="p_cover_medical_pop revised-premium-title">
                              Revised Premium:{" "}
                            </p>
                            <span className="p_cover_medical_pop_span ">
                              <i className="fa fa-inr"></i>{" "}
                              {numToString(addOns.total_premium)} /{" "}
                              {item.tenure > 1
                                ? item.tenure + " years"
                                : "year"}{" "}
                            </span>
                          </div>
                        </>
                      ) : (
                        <></>
                      )}
                      {addOns.product.insurance_type.alias === "top_up" && (
                        <div className="col-md-6">
                          <p className="p_cover_medical_pop">Deductable: </p>
                          <span className="p_cover_medical_pop_span">
                            <i className="fa fa-inr"></i>{" "}
                            {numToString(addOns.deductible)}
                          </span>
                        </div>
                      )}
                    </div>
                  </div>
                ))}
              </>
            ))}
            {planDetails.isRenewed ? (
              <>
                <div class="rider-box_product_pro_medical_box">
                  <div class="row padding_filter_footer_medical">
                    <div class="col-md-12">
                      <p class="font_grey">Total Premium</p>
                    </div>
                    <div class="col-md-4">
                      <button
                        type="button"
                        name="Continue"
                        class="next text-left"
                        value="Continue"
                      >
                        <span class="color_span_total_revise">
                          Previous Premium
                        </span>
                        <br />
                        <span
                          class="color_span_total_blck_medical text_decoration_line_through addon_plan_d_inter_1_product_pro_f_mediacl_btn revised-premium"
                          css={`
                            @media (max-width: 767px) {
                              font-size: 16px !important;
                              font-weight: bold !important;
                            }
                          `}
                        >
                          <i class="fa fa-inr"></i>{" "}
                          {planDetails.prevCart.totalPremium} / year
                        </span>
                      </button>
                    </div>
                    <div class="col-md-4">
                      <button
                        type="button"
                        name="Continue"
                        class="next text-left"
                        value="Continue"
                      >
                        <span class="color_span_total_revise revised-premium-title">
                          Revised Premium
                        </span>
                        <br />
                        <span
                          class="color_span_total_blck_medical"
                          css={`
                            @media (max-width: 767px) {
                              font-size: 16px !important;
                              font-weight: bold !important;
                            }
                          `}
                        >
                          <i class="fa fa-inr"></i> {cart.totalPremium} / year
                        </span>
                      </button>
                    </div>
                    <div class="col-md-4" style={{ float: "right" }}>
                      <button
                        type="button"
                        name="Continue"
                        class="btn btn_continue_medi_revise_pop next"
                        value="Continue"
                        onClick={() => {
                          setActive(prev => prev + 1);
                          dispatch(
                            setPlanDetails({
                              title: "Your Plan Details",
                              show: false,
                              prevCart: {},
                              isRenewed: false,
                            }),
                          );
                        }}
                      >
                        Continue
                      </button>
                    </div>
                  </div>
                </div>
              </>
            ) : (
              <></>
            )}
          </section>
        </div>
      </div>
    </>
  );
  return (
    <>
      <Wrapper className="col-lg-3 product__summary hideOnMobile">
        <Button onClick={() => setShow(true)}>
          <div>
            Total Premium
            <Price>
              <i class="fa fa-inr"></i> {cart?.totalPremium}
            </Price>
          </div>
          <ViewPlanDetails>
            View Plan Details <img alt="arrow" src={arrow} />
          </ViewPlanDetails>
        </Button>
      </Wrapper>
      <ViewPlanDetailModal
        show={show}
        title={planDetails.title}
        showButton={false}
        content={content}
        handleClose={() => {
          setShow(false);
          dispatch(
            setPlanDetails({
              title: "Your Plan Details",
              show: false,
              prevCart: {},
              isRenewed: false,
            }),
          );
        }}
        customClass="customClassModalDialog"
        revised={true}

      />
    </>
  );
};

export default ProductSummary;

const Wrapper = styled.div`
  width: 22%;
  display: inline-block;
  float: right;
  border: 1px solid #dfdfdf;
  border-radius: 8px;
  padding-left: 10px;
  @media (min-width: 768px) and (max-width: 1200px) {
    display: none;
  }
  /*position: absolute;
   right: 68px;
   top: 240px;*/
`;

const Button = styled.button`
  margin-top: 10px;
  font-size: 18px !important;
  font-weight: 600;
  color: #000;
  font-family: "pf_handbook_proregular";
  margin-top: 2px !important;
  display: inline-block;

  text-align: left !important;
  white-space: nowrap;
  vertical-align: middle;
  user-select: none;

  padding: 10px 0px;
  font-size: 1rem;
  line-height: 1.5;
  border-radius: 0.25rem;
  transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out,
    box-shadow 0.15s ease-in-out;
  background: #fff;
`;
const Price = styled.span`
  display: block;
  font-size: 21px;
  font-weight: 600;
  color: #c72229;
  font-family: "pf_handbook_proregular";
  margin-top: -3px;
`;
const ViewPlanDetails = styled.span`
  cursor: pointer;
  position: absolute;

  top: 50%;
  right: 10px;
  transform: translateY(-50%);
  & img {
    display: inline-block;
    margin-left: 2px;
    cursor: pointer;
  }
`;
