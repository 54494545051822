import React, { useState } from "react";
import { Col, OverlayTrigger, Tooltip } from "react-bootstrap";
import validIcon from "../../../../assets/images/recommend_more_correct.png";
import invalidIcon from "../../../../assets/images/exclamation_red.png";
import "styled-components/macro";
import * as mq from "../../../../utils/mediaQueries";
import { ToolTip } from "../FeatureCard/FeatureCard";

const renderTooltip = (desc, props) => <Tooltip {...props}>{desc}</Tooltip>;

const RequirementCard = ({
  title,
  value,
  matches = true,
  textEllipsis = true,
  tooltip = "",
}) => {
  const [trigger, setTrigger] = useState(false);

  const show = () => {
    if (!!tooltip) {
      setTrigger(!trigger);
    }
  };

  const hide = () => setTrigger(false);

  return (
    <Col
      lg={6}
      css={`
        ${mq.mobile} {
          min-width: 100%;
          margin-bottom: 1.6rem;
        }
      `}
      onMouseEnter={show}
      onMouseLeave={hide}
    >
      {/* <OverlayTrigger
        show={trigger}
        overlay={renderTooltip(tooltip)}
        placement="right"
      > */}
      <ToolTip show={trigger} content={tooltip}>
        <div
          className="feature-block-three active mb-24"
          css={`
            display: flex;
            ${mq.mobile} {
              padding: 10px;
              height: 80px;
            }
          `}
        >
          <div
            className="d-flex"
            css={`
              align-items: center;
            `}
          >
            <div
              css={`
                width: 90px;
                ${mq.mobile} {
                  width: 60px;
                }
              `}
            >
              <img
                src={matches ? validIcon : invalidIcon}
                alt={matches ? "matching" : "not-matching"}
              />
            </div>
            <div className="text">
              <h5
                css={`
                  font-family: "pf_handbook_proregular" !important;
                  font-weight: 900;

                  ${mq.mobile} {
                    font-size: 16px !important;
                  }
                `}
              >
                {title}
              </h5>
              <p
                className="matches_p"
                css={`
                  /* width: 376px; */
                  width: ${textEllipsis ? "260px" : "260px"};
                  white-space: ${textEllipsis ? "nowrap" : "normal"};
                  overflow: hidden;
                  text-overflow: ellipsis;
                  line-height: 1.6;

                  ${mq.mobile} {
                    font-size: 12.7px !important;
                    width: calc(100% - 37px);
                    overflow: hidden;
                    white-space: nowrap;
                    text-overflow: ellipsis;
                  }
                `}
                title={value}
              >
                {value}
              </p>
            </div>
          </div>
        </div>
      </ToolTip>
      {/* </OverlayTrigger> */}
    </Col>
  );
};

export default RequirementCard;
