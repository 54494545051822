import exercise from "./new-svg/1.png";
import past_disease from "./new-svg/7.png";
import managing_diabetes from "./new-svg/4.png";
import monthly_diseases from "./new-svg/5.png";
import child_birth from "./new-svg/6.png";
import network_hospital from "./new-svg/8.png";
import annual_income from "./new-svg/9.png";
import travel from "./new-svg/10b.png";
import premium from "./new-svg/11.png";
import smoking from "./new-svg/10a.png";

export const recommendationIcons = {
  exercise,
  child_birth,
  past_disease,
  network_hospital,
  managing_diabetes,
  monthly_diseases,
  premium,
  annual_income,
  travel,
  smoking,
};
