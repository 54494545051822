import React from "react";
import "./SecondaryNavbar.scss";
import "styled-components/macro";

const SecondaryNavbar = () => {
  return (
    <div className="secondaryNavbar"
      css={`
    height: 24px;
    `}
    >
      <h1
        className="secondaryNavbar__header"
        css={`
        font-size: 20px !important;
          @media (max-width: 767px) {
            font-size: 16px !important;
            padding-left: 18px !important;
          }
        `}
      >
        Insurance Advisory
      </h1>
      {/* <ul className="secondaryNavbar__container"
        css={`
      @media (max-width: 991px) {
        display:none !important;
      }
      `}
      >
        <li className="secondaryNavbar__header1"> Key benefits</li>
        <li className="secondaryNavbar__header1">Eligibility & Coverage</li>
      </ul> */}
    </div>
  );
};

export default SecondaryNavbar;
