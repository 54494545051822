import React, { useCallback, useEffect, useReducer, useState } from "react";
import { setCurrentSchema } from "../ProposalPage/schema.slice";
import "./Generator.scss";
const getType = current => {
  switch (current) {
    case "Text":
      return "text";
    case "Select":
      return "select";
    case "Custom Toggle":
      return "custom_toggle";
    case "Title":
      return "title";
    case "Custom Medical":
      return "custom_medical";
    case "Custom Table":
      return "custom_table";
    default:
      break;
  }
};
const PrettyPrint = schema => {
  return JSON.stringify(schema, undefined, 8);
};
const Generator = () => {
  const [current, setCurrent] = useState("New Form");

  const [currentForm, setCurrentForm] = useState("ProposerDetails");

  const [currentComp, setCurrentComp] = useState({});
  const [schema, setSchema] = useState({});

  useEffect(() => {
    if (current !== "New Form" && !Object.keys(currentComp).length) {
      setCurrentComp({ ...currentComp, type: getType(current) });
    }
  }, [current, currentComp]);

  const _onClick = e => {
    setCurrent(e.target.innerText);
    setCurrentComp({});
  };
  const _onClickNewForm = e => {
    setCurrentForm(e.target.innerText);
    setSchema({
      ...schema,
      [e.target.innerText]: [
        ...(schema[e.target.innerText] ? schema[e.target.innerText] : []),
      ],
    });
  };
  return (
    <div>
      <div className="container">
        <div className="row">
          <div className="col-lg-3">
            <button className="btn btn-primary fillArea" onClick={_onClick}>
              Text
            </button>
          </div>
          <div className="col-lg-3">
            <button className="btn btn-primary fillArea" onClick={_onClick}>
              Select
            </button>
          </div>
          <div className="col-lg-3">
            <button className="btn btn-primary fillArea" onClick={_onClick}>
              Title
            </button>
          </div>
          <div className="col-lg-3">
            <button className="btn btn-primary fillArea" onClick={_onClick}>
              Custom Medical
            </button>
          </div>
        </div>
        <div className="row">
          <div className="col-lg-4">
            <button className="btn btn-primary fillArea" onClick={_onClick}>
              Custom Toggle
            </button>
          </div>

          <div className="col-lg-4">
            <button className="btn btn-success fillArea" onClick={_onClick}>
              New Form
            </button>
          </div>
        </div>
      </div>
      <div className="container">
        {" "}
        <h3> {current}:- Fill details</h3>
      </div>
      {current === "New Form" && (
        <>
          {" "}
          <div className="container">
            <div className="row">
              <div className="col-lg-3">
                <button
                  className="btn btn-primary fillArea"
                  onClick={_onClickNewForm}
                >
                  Proposer Details
                </button>
              </div>
              <div className="col-lg-3">
                <button
                  className="btn btn-primary fillArea"
                  onClick={_onClickNewForm}
                >
                  Insured Details
                </button>
              </div>
              <div className="col-lg-3">
                <button
                  className="btn btn-primary fillArea"
                  onClick={_onClickNewForm}
                >
                  Medical Details
                </button>
              </div>
              <div className="col-lg-3">
                <button
                  className="btn btn-primary fillArea"
                  onClick={_onClickNewForm}
                >
                  Other Details
                </button>
              </div>
            </div>
          </div>
        </>
      )}
      {current === "Text" && (
        <>
          <div className="container">
            <div className="row">
              <div className="col-lg-3">
                <div className="form-group">
                  <label htmlFor="name">Variable Name</label>
                  <input
                    type="text"
                    className="form-control"
                    name="name"
                    placeholder=" Variable Name"
                    value={currentComp.name ? currentComp.name : ""}
                    onChange={e => {
                      setCurrentComp({
                        ...currentComp,
                        name: e.target.value,
                      });
                    }}
                  ></input>
                </div>
              </div>
              <div className="col-lg-3">
                <div className="form-group">
                  <label htmlFor="label">Label</label>
                  <input
                    type="text"
                    className="form-control"
                    name="label"
                    placeholder="Label"
                    value={
                      currentComp.additionalOptions
                        ? current.additionalOptions?.label
                        : ""
                    }
                    onChange={e => {
                      setCurrentComp({
                        ...currentComp,
                        additionalOptions: {
                          ...(currentComp.additionalOptions
                            ? currentComp.additionalOptions
                            : {}),
                          label: e.target.value,
                        },
                      });
                    }}
                  ></input>
                </div>
              </div>
              <div className="col-lg-3">
                <div className="form-group">
                  <label htmlFor="Placeholder">Placeholder</label>
                  <input
                    type="text"
                    className="form-control"
                    name="Placeholder"
                    placeholder="Placeholder"
                    value={
                      currentComp?.additionalOptions
                        ? currentComp.additionalOptions?.placeholder
                        : ""
                    }
                    onChange={e => {
                      setCurrentComp({
                        ...currentComp,
                        additionalOptions: {
                          ...(currentComp.additionalOptions
                            ? currentComp.additionalOptions
                            : {}),
                          placeholder: e.target.value,
                        },
                      });
                    }}
                  ></input>
                </div>
              </div>
              <div className="col-lg-3">
                <button
                  className="btn btn-success fillArea"
                  onClick={() => {
                    setSchema({
                      ...schema,
                      [currentForm]: [...schema[currentForm], currentComp],
                    });
                    setCurrentComp({});
                  }}
                >
                  Insert
                </button>
              </div>
            </div>
          </div>
        </>
      )}
      {current === "Select" && (
        <>
          <div className="container">
            <div className="row">
              <div className="col-lg-3">
                <div className="form-group">
                  <label htmlFor="name">Variable Name</label>
                  <input
                    type="text"
                    className="form-control"
                    name="name"
                    placeholder=" Variable Name"
                    value={currentComp.name ? currentComp.name : ""}
                    onChange={e => {
                      setCurrentComp({
                        ...currentComp,
                        name: e.target.value,
                      });
                    }}
                  ></input>
                </div>
              </div>
              <div className="col-lg-3">
                <div className="form-group">
                  <label htmlFor="label">Label</label>
                  <input
                    type="text"
                    className="form-control"
                    name="label"
                    placeholder="Label"
                    value={
                      currentComp?.additionalOptions
                        ? currentComp.additionalOptions?.label
                        : ""
                    }
                    onChange={e => {
                      setCurrentComp({
                        ...currentComp,
                        additionalOptions: {
                          ...(currentComp.additionalOptions
                            ? currentComp.additionalOptions
                            : {}),
                          label: e.target.value,
                          options: { key: "value" },
                        },
                      });
                    }}
                  ></input>
                </div>
              </div>

              <div className="col-lg-3">
                <button
                  className="btn btn-success fillArea"
                  onClick={() => {
                    setSchema({
                      ...schema,
                      [currentForm]: [...schema[currentForm], currentComp],
                    });
                    setCurrentComp({});
                  }}
                >
                  Insert
                </button>
              </div>
            </div>
          </div>
        </>
      )}
      {current === "Title" && (
        <>
          <div className="container">
            <div className="row">
              <div className="col-lg-3">
                <div className="form-group">
                  <label htmlFor="name">Title</label>
                  <input
                    type="text"
                    className="form-control"
                    name="name"
                    placeholder=" Variable Name"
                    value={currentComp.name ? currentComp.name : ""}
                    onChange={e => {
                      setCurrentComp({
                        ...currentComp,
                        name: e.target.value,
                        width: "100%",
                      });
                    }}
                  ></input>
                </div>
              </div>

              <div className="col-lg-3">
                <button
                  className="btn btn-success fillArea"
                  onClick={() => {
                    setSchema({
                      ...schema,
                      [currentForm]: [...schema[currentForm], currentComp],
                    });
                    setCurrentComp({});
                  }}
                >
                  Insert
                </button>
              </div>
            </div>
          </div>
        </>
      )}
      {current === "Custom Medical" && (
        <>
          <div className="container">
            <div className="row">
              <div className="col-lg-3">
                <div className="form-group">
                  <label htmlFor="name">variable name</label>
                  <input
                    type="text"
                    className="form-control"
                    name="name"
                    placeholder=" Variable Name"
                    value={currentComp.name ? currentComp.name : ""}
                    onChange={e => {
                      setCurrentComp({
                        ...currentComp,
                        name: e.target.value,
                        width: "100%",
                      });
                    }}
                  ></input>
                </div>
              </div>
              <div className="col-lg-3">
                <div className="form-group">
                  <label htmlFor="label">Label</label>
                  <input
                    type="text"
                    className="form-control"
                    name="label"
                    placeholder="Label"
                    value={
                      currentComp?.additionalOptions
                        ? currentComp.additionalOptions?.label
                        : ""
                    }
                    onChange={e => {
                      setCurrentComp({
                        ...currentComp,
                        additionalOptions: {
                          ...(currentComp.additionalOptions
                            ? currentComp.additionalOptions
                            : {}),
                          label: e.target.value,
                          additionalQuestions: [],
                        },
                      });
                    }}
                  ></input>
                </div>
              </div>

              <div className="col-lg-3">
                <button
                  className="btn btn-success fillArea"
                  onClick={() => {
                    setSchema({
                      ...schema,
                      [currentForm]: [...schema[currentForm], currentComp],
                    });
                    setCurrentComp({});
                  }}
                >
                  Insert
                </button>
              </div>
            </div>
          </div>
        </>
      )}
      {current === "Custom Toggle" && (
        <>
          <div className="container">
            <div className="row">
              <div className="col-lg-3">
                <div className="form-group">
                  <label htmlFor="name">variable name</label>
                  <input
                    type="text"
                    className="form-control"
                    name="name"
                    placeholder=" Variable Name"
                    value={currentComp.name ? currentComp.name : ""}
                    onChange={e => {
                      setCurrentComp({
                        ...currentComp,
                        name: e.target.value,
                        width: "100%",
                      });
                    }}
                  ></input>
                </div>
              </div>
              <div className="col-lg-3">
                <div className="form-group">
                  <label htmlFor="label">Label</label>
                  <input
                    type="text"
                    className="form-control"
                    name="label"
                    placeholder="Label"
                    value={
                      currentComp?.additionalOptions
                        ? currentComp.additionalOptions?.label
                        : ""
                    }
                    onChange={e => {
                      setCurrentComp({
                        ...currentComp,
                        additionalOptions: {
                          ...(currentComp.additionalOptions
                            ? currentComp.additionalOptions
                            : {}),
                          label: e.target.value,
                        },
                      });
                    }}
                  ></input>
                </div>
              </div>

              <div className="col-lg-3">
                <button
                  className="btn btn-success fillArea"
                  onClick={() => {
                    setSchema({
                      ...schema,
                      [currentForm]: [...schema[currentForm], currentComp],
                    });
                    setCurrentComp({});
                  }}
                >
                  Insert
                </button>
              </div>
            </div>
          </div>
        </>
      )}
      {current === "Custom Table" && (
        <>
          <div className="container">
            <div className="row">
              <div className="col-lg-3">
                <div className="form-group">
                  <label htmlFor="name">variable name</label>
                  <input
                    type="text"
                    className="form-control"
                    name="name"
                    placeholder=" Variable Name"
                    value={currentComp.name ? currentComp.name : ""}
                    onChange={e => {
                      setCurrentComp({
                        ...currentComp,
                        name: e.target.value,
                        width: "100%",
                      });
                    }}
                  ></input>
                </div>
              </div>
              <div className="col-lg-3">
                <div className="form-group">
                  <label htmlFor="label">Label</label>
                  <input
                    type="text"
                    className="form-control"
                    name="label"
                    placeholder="Label"
                    value={
                      currentComp?.additionalOptions
                        ? currentComp.additionalOptions?.label
                        : ""
                    }
                    onChange={e => {
                      setCurrentComp({
                        ...currentComp,
                        additionalOptions: {
                          ...(currentComp.additionalOptions
                            ? currentComp.additionalOptions
                            : {}),
                          label: e.target.value,
                          questions: [{ name: "", label: "" }],
                        },
                      });
                    }}
                  ></input>
                </div>
              </div>

              <div className="col-lg-3">
                <button
                  className="btn btn-success fillArea"
                  onClick={() => {
                    setSchema({
                      ...schema,
                      [currentForm]: [...schema[currentForm], currentComp],
                    });
                    setCurrentComp({});
                  }}
                >
                  Insert
                </button>
              </div>
            </div>
          </div>
        </>
      )}
      <div className="container">
        <textarea
          id="myTextArea"
          className="form-control"
          cols={50}
          rows={25}
          value={PrettyPrint(schema)}
          onChange={e => {
            setCurrentSchema(JSON.parse(e.target.value));
          }}
        ></textarea>
      </div>
    </div>
  );
};

export default Generator;
