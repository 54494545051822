import React from "react";
import styled from "styled-components";

const StyledButton = ({
  type,
  value,
  noIcon,
  onClick,
  children,
  laptopLg,
  style,
  customClass,
  mobileSm,
  showOnMobile,
  mobileWidth,
  disable,
  inverseColor,
}) => {
  return (
    <Button
      disable={disable}
      style={style}
      type={type || undefined}
      data-page="1"
      name="next"
      value={value}
      onClick={onClick}
      className={`styled__button ${customClass}`}
      laptopLg={laptopLg || undefined}
      mobileSm={mobileSm || undefined}
      mobileWidth={mobileWidth || undefined}
      showOnMobile={showOnMobile || undefined}
      inverseColor={inverseColor || undefined}
    >
      {value ? value : children}{" "}
      {/* {!noIcon && <i className="icon flaticon-next"></i>} */}
    </Button>
  );
};

export default StyledButton;

const Button = styled.button.attrs(props => ({
  type: props.type,
}))`
  box-sizing: border-box;
  user-select: none;
  -webkit-tap-highlight-color: transparent;
  touch-action: manipulation;
  font-family: inherit;
  overflow: visible;
  text-transform: none;
  outline: none;
  box-shadow: none;
  width: 217px !important;
  background: #c7222a;
  color: white;
  border: 0 none;
  cursor: pointer;
  padding: 5px 10px;
  margin: 10px auto;
  transition: all 0.3s linear 0s;
  display: block;
  border-radius: 2px;
  font-size: 20px;
  font-weight: 400;
  line-height: 35px;
  height: 56px;
  z-index: 9999;
  float: left;
  left: 4px;
  position: inherit;
  display: ${props => props.showOnMobile && "none"};
  @media (max-width: 1140px) {
    width: ${props => props.laptopLg && "100% !important"};
  }
  @media (max-width: 767px) {
    display: ${props => props.showOnMobile && "block"};
    background: ${props => props.inverseColor && "white"};
    color: ${props => props.inverseColor && "#c7222a"};
    border: ${props => props.inverseColor && "1px solid #c7222a"};
     width: ${props => props.mobileSm && "48% !important"};
    width: ${props => props.mobileWidth && "91% !important"};
    height: 54px;
  }
`;
