import { useEffect, useState } from "react";
import { Row } from "react-bootstrap";
import {
  Title,
  Paragraph,
  ContinueButton,
} from "../fieldsetComponents/fieldsetComponents";
import RadioButton from "../../components/RadioButton/RadioButton";
import { v4 as uuidv4 } from "uuid";
import "styled-components/macro";
import Checkbox from "../../components/Checkbox/Checkbox";
import * as mq from "../../../../utils/mediaQueries";
import useWindowSize from "../../../../customHooks/useWindowSize";
import { useSelector } from "react-redux";
import { selectAnswers, selectQuestions } from "../../recommendedPage.slice";
import { useParams } from "react-router";

function Fieldset({
  currentForm,
  question,
  setCurrentForm,
  index,
  handleContinue = () => {},
}) {
  const { label, logo, type, options, when } = question;

  const [radioValue, setRadioValue] = useState(
    type === "multiselect" ? [] : undefined,
  );

  const handleSubmit = () => {
    handleContinue(radioValue, question);
  };

  const handleChange = value => {
    if (type === "multiselect" && options.includes("None")) {
      if (radioValue.includes("None")) {
        if (value !== "None") {
          setRadioValue(radioValue.filter(val => val !== "None").concat(value));
          return;
        }
      }
      if (value === "None") {
        setRadioValue(["None"]);
        return;
      }
      if (radioValue.includes(value)) {
        setRadioValue(radioValue.filter(val => val !== value));
        return;
      }
      setRadioValue([...radioValue, value]);
      return;
    }
    const tempArray = [...radioValue];
    if (tempArray.includes(value)) {
      const index = tempArray.indexOf(value);
      tempArray.splice(index, 1);
    } else {
      tempArray.push(value);
    }

    setRadioValue(tempArray);
  };

  const { groupCode } = useParams();

  const questions = useSelector(selectQuestions);

  const previousAnswers = useSelector(selectAnswers(groupCode));

  if (when && currentForm === index && when[0].question_id) {
    const render = when.some(condition => {
      const { question_id, answers } = condition;
      // const { name } = questions[question_id - 1];
      const { name } = questions.find(
        q => q.sequence === parseInt(question_id),
      );
      const prevAnswer = previousAnswers[name];
      if (Array.isArray(prevAnswer)) {
        return prevAnswer.some(a => answers.includes(a));
      }
      return answers.includes(prevAnswer);
    });
    if (!render) {
      setCurrentForm(currentForm + 1);
      return null;
    }
  }

  return (
    <fieldset
      style={{ display: currentForm !== index && "none" }}
      css={`
        ${mq.mobile} {
          width: 90%;
          margin: auto;
        }
      `}
    >
      <div
        css={`
          ${mq.mobile} {
            margin: auto;
            padding: 0 15px;
          }
        `}
      >
        <Row
          css={`
            ${mq.mobile} {
              width: 100%;
              margin: auto;
            }
          `}
        >
          <img src={logo} className="img_recommend_icon" alt={label} />
          <Title
            className="fs-title font-reset"
            css={`
              ${mq.mobile} {
                font-size: 21px;
                text-align: center;
                margin: auto;
              }
            `}
          >
            {label}
          </Title>
        </Row>
      </div>
      <section
        className="section_r pad_s"
        css={`
          justify-content: flex-start;

          ${mq.mobile} {
            align-items: center;
            margin-bottom: 30px !important;
            ${type === "multiselect" &&
            `flex-flow: ${options.length > 3 ? "row wrap" : ""};
            justify-content: ${options.length > 3 ? "space-between" : ""};
            padding: ${options.length > 3 ? "0 15px" : "0"};
            & .check {
              width: calc(50% - 6px);
              margin: 6px 0 !important;

              & .check-value {
                width: 100%;
              }
            }
            `}
          }
        `}
      >
        {options.map((data, i) =>
          type === "multiselect" ? (
            <Checkbox
              onChange={e => handleChange(e.target.value)}
              checked={radioValue.includes(data)}
              value={data}
              id={uuidv4()}
              sm
            />
          ) : (
            <RadioButton
              css={`
                flex: 0;
                margin-right: 10px !important;
                & label {
                  border-radius: 12px;
                }
                /* & h2 {
                  font-weight: normal;
                } */
                section & {
                  padding: 10px;
                }
                .radio_re {
                  margin: 0 !important;
                }

                ${mq.mobile} {
                  section & {
                    margin: 0;
                    width: 100%;
                    & .h2_g_r {
                      margin: 0;
                    }
                  }

                  .radio_re {
                    margin: auto !important;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                  }
                }
              `}
              key={i}
              id={uuidv4()}
              value={
                typeof data === "object" ? data[Object.keys(data)[0]] : data
              }
              onChange={e => {
                if (typeof data === "object") {
                  setRadioValue(Object.keys(data)[0]);
                  setTimeout(() => {
                    handleContinue(Object.keys(data)[0], question);
                  }, 600);
                  return;
                }
                setRadioValue(e.target.value);
                setTimeout(() => {
                  handleContinue(e.target.value, question);
                }, 600);
              }}
              checked={
                typeof data === "object"
                  ? data[Object.keys(data).find(key => key === radioValue)]
                  : radioValue
              }
            />
          ),
        )}
      </section>
      {/* <Paragraph>
        Say "Yes" only if you have your own personal health cover apart from
        cover provided by your employer coz cover given by your employer
      </Paragraph> */}
      {type !== "toggle" && (
        <ContinueButton
          onClick={handleSubmit}
          css={`
            margin-left: -10px;

            ${mq.mobile} {
              width: 120px !important;
              height: 3rem;
              display: flex;
              justify-content: center;
              align-items: center;
              font-size: 18px;
              border-radius: 2px;
              margin: auto;
              margin-bottom: 3rem;
            }
          `}
        >
          Continue
        </ContinueButton>
      )}
    </fieldset>
  );
}

export default Fieldset;
