import styled from "styled-components";

const RadioGroup = styled.div`
  display: flex;
  align-items: center;
  padding: 0px 0px 8px 3px;
  flex-wrap: wrap;
  @media (max-width: 767px) {
    margin-top: -15px;
    width: 100%;
    padding: 0px 0px 6px 3px;
    justify-content: space-between;
    & .radioContainer {
      width: 48%;
      & img {
        left: unset !important;
        right: 7% !important;
      }
    }
  }
`;

const RadioInput = styled.input.attrs(props => ({
  type: "radio",
}))`
  &:not(:disabled) ~ label {
    cursor: pointer;
  }
  & + label {
    padding-left: 1em;
    color: #000000;
    border: 2px solid #e1e2ea;
    border-radius: 8px;
    padding: 10px 19px !important;
    font-size: 17px;
    font-weight: 900;
    width: 204px;
    text-align: center !important;
    box-shadow: 0 12px 12px -11px #004b8347;
  }

  &:checked + label {
    padding-left: 1em;
    color: #000000;
    border: 1px solid #c7222a;
    border-radius: 8px;
    padding: 16px 19px;
    font-size: 17px;
    font-weight: 900;
    width: 204px;
    text-align: left !important;
  }
  & label:before {
    box-sizing: border-box;
    content: " ";
    position: absolute;
    top: 0.3em;
    left: 0;
    display: block;
    width: 1.4em;
    height: 1.4em;
    border: 2px solid white;
    border-radius: 0.25em;
    z-index: -1;
  }
  & label::before {
    transition: 0.25s all ease;
  }
  & + label::before {
    border-radius: 1em;
  }

  &:checked,
  &:not(:checked) {
    position: absolute;
    left: -9999px;
    width: 0;
    height: 0;
    visibility: hidden;
  }
  opacity: 0;
  z-index: -1;
  @media (max-width: 767px) {
    & + label {
      width: 100% !important;
    }
  }
`;

const RadioLabel = styled.label`
  position: relative;
  margin-right: 1em;
  padding-left: 2em;
  padding-right: 1em;
  line-height: 2;
  cursor: pointer;
`;

export { RadioLabel, RadioInput, RadioGroup };
