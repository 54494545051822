import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { Alert, Col, Container, Row } from "react-bootstrap";
import BackIcon from "../../components/BackIcon/BackIcon";
import Form3CheckBox from "../../components/CheckBox/Form3CheckBox";
import GreetingFormDropdown from "../../components/Dropdown/GreetingForm/GreetingFormDropdown";
import { Label } from "../../components/FormComponents/FormComponents";
import addothermember from "../../../../assets/images/addothermember.png";
import addBtn from "../../../../assets/images/addred.png";
import addBtndisabled from "../../../../assets/images/addgrey.png";
import { useSelector } from "react-redux";

import { getAge, dataSet } from "./data/Fieldset3Data/Data";
import styled from "styled-components/macro";
import StyledButton from "../../components/Button/StyledButton";
import GreetingFormModal from "../../components/Modals/GreetingsForm/GreetingFormModal";
import { v4 as uuidv4 } from "uuid";
import "./Fieldset.scss";
import {
  saveForm1UserDetails,
  saveForm2UserDetails,
  saveForm3UserDetails,
  saveForm4UserDetails,
} from "../../reducer/greetingPage.slice";
import BackBtn from "../../components/Button/BackBtn";
import { ErrorMessage2 } from "./Fieldset5";
import { array } from "prop-types";
import backButton from "../../../../assets/images/backbutton.png";

const Fieldset3 = ({ currentForm, handleChange, value }) => {
  const { error } = useSelector(state => state.greetingPage);
  const { frontendData } = useSelector(state => state.frontendBoot);
  const { data } = frontendData || [""];
  const { members } = data || [""];

  const [membersArray, setMembersArray] = useState([]);
  const [childCount, setChildCount] = useState(0);
  const [ageError, setAgeError] = useState([]);

  const [showModal, setShow] = useState(false);
  const [errors, setErrors] = useState(false);

  const dispatch = useDispatch();

  useEffect(() => {
    if (members?.length > 0) {
      setMembersArray([...members]);
    }
  }, [members]);

  // Will contain list of insurer names that are checked
  const [insurerCBXArray, setInsurerCBXArray] = useState([]);
  // Will contain list of insurer Dropdown values if checkbox is checked
  const [insurerDDArray, setInsurerDDArray] = useState([]);

  useEffect(() => {
    if (window.matchMedia("(max-width: 767px)")) {
      if (!insurerCBXArray.includes("daughter")) {
        const tempArray = insurerCBXArray.filter(
          element => element.slice(0, 8) !== "daughter",
        );
        setInsurerCBXArray(tempArray);
      }
      if (!insurerCBXArray.includes("son")) {
        const tempArray = insurerCBXArray.filter(
          element => element.slice(0, 3) !== "son",
        );
        setInsurerCBXArray(tempArray);
      }
    }
  }, [membersArray]);

  useEffect(() => {
    let count = 0;
    insurerCBXArray.forEach(element => {
      if (element.slice(0, 8) === "daughter" || element.slice(0, 3) === "son") {
        count += 1;
      }
    });

    setChildCount(count);
  }, [insurerCBXArray]);

  const addChild = name => {
    const code = name.toLowerCase();
    if (insurerCBXArray.includes(code)) {
      if (childCount < 4) {
        const { max_age, min_age } = membersArray.filter(
          item => item.code === code,
        )[0];
        setChildCount(childCount + 1);
        const genCode = `${code + uuidv4()}`;
        const tempArray = [...membersArray];
        const index = tempArray.findIndex(x => x.display_name === name);
        tempArray.splice(index + 1, 0, {
          [`code`]: genCode,
          [`display_name`]: name,
          [`min_age`]: `${min_age}`,
          [`max_age`]: `${max_age}`,
          ["is_primary"]: true,
          ["hasClose"]: true,
        });
        handleinsurerCBXArray(genCode);

        setMembersArray(tempArray);
      }
    }
  };

  const handleinsurerCBXArray = insurer => {
    const tempArray = [...insurerCBXArray];

    if (!tempArray.includes(insurer)) {
      tempArray.push(insurer);
    } else {
      const index = tempArray.indexOf(insurer);

      if (index > -1) {
        tempArray.splice(index, 1);
        handleinsurerDDArray(insurer, "Select Age");
      }
    }
    setInsurerCBXArray(tempArray);
  };

  const handleinsurerDDArray = (insurer, value) => {
    const tempArray = [...insurerDDArray];
    var index = tempArray.map(o => o.insurer).indexOf(insurer);
    if (value !== "Select Age") {
      if (index > -1) {
        tempArray[index].value = value;
        if (!insurerCBXArray.includes(insurer)) {
          handleinsurerCBXArray(insurer);
        }
      } else {
        tempArray.push({ insurer: `${insurer}`, value: `${value}` });
        if (!insurerCBXArray.includes(insurer)) {
          handleinsurerCBXArray(insurer);
        }
      }
    } else if (index > -1) {
      tempArray.splice(index, 1);
    }
    if (tempArray.length > 0) {
      setErrors(false);
    } else {
      setErrors(true);
    }
    setInsurerDDArray(tempArray);
  };

  const handleInput = (insurer, value, type) => {
    if (type === "checkbox") {
      if (
        (insurer.slice(0, 3) === "son" && insurer !== "son") ||
        (insurer.slice(0, 8) === "daughter" && insurer !== "daughter")
      ) {
        const tempArray = [...membersArray];
        var index = tempArray.map(o => o.code).indexOf(insurer);
        if (index > -1) {
          tempArray.splice(index, 1);
          setMembersArray(tempArray);
        }
      }
      if (
        (insurer.slice(0, 3) === "son" || insurer.slice(0, 8) === "daughter") &&
        insurerCBXArray.includes(insurer)
      ) {
        if (insurer === "son") {
          const tempArray = membersArray.filter(element => {
            return (
              element.display_name !== "Son" ||
              (element.display_name === "Son" && element.code === "son")
            );
          });
          setMembersArray(tempArray);
        } else if (insurer === "daughter") {
          const tempArray = membersArray.filter(
            element =>
              element.display_name !== "Daughter" ||
              (element.display_name === "Daughter" &&
                element.code === "daughter"),
          );
          setMembersArray(tempArray);
        }
      }
      handleinsurerCBXArray(insurer, value);
    } else if (type === "dropdown") {
      handleinsurerDDArray(insurer, value);
    }
  };

  return (
    <fieldset style={{ display: currentForm === value ? "block" : "none" }}>
      <div>
        <BackIcon
          onClick={() => {
            handleChange("form2");
          }}
        />
      </div>
      <a
        onClick={() => handleChange("form2")}
        className="showOnMObile"
        css={`
          display: none !important;
          @media (max-width: 767px) {
            display: inline-block !important;
            height: 32px;
            z-index: 999;
            margin-left: -12px;
          }
        `}
      >
        <img
          src={backButton}
          css={`
            height: 58px;
          `}
        />
      </a>
      <Label>Who all would you like to insure?</Label>
      <form>
        <Container>
          <Row id="proposal_members" className="proposal_members__memberp">
            {membersArray &&
              membersArray.map(
                ({
                  display_name,
                  min_age,
                  max_age,
                  is_primary,
                  is_primary_modal,
                  hasClose,
                  code,
                }) => {
                  const age = getAge(min_age, max_age);

                  return (
                    (is_primary || is_primary_modal) &&
                    dataSet(
                      display_name,
                      age,
                      insurerCBXArray,
                      insurerDDArray,
                      handleInput,
                      hasClose,
                      code,
                      ageError,
                      childCount,
                      addChild,
                    )
                  );
                },
              )}
            <span
              css={`
                width: 100%;
                @media (max-width: 767px) {
                  display: none;
                }
              `}
            >
              <StyledHr />
              <div
                css={`
                  display: flex;
                  justify-content: space-between;
                  width: 100%;
                `}
              >
                <Col
                  md={4}
                  lg={4}
                  xs={6}
                  sm={4}
                  style={{ borderRight: "1px solid #ddd" }}
                  css={`
                    display: flex;
                    justify-content: center;
                    width: 100%;
                  `}
                >
                  <AddMemberButton
                    onClick={event => {
                      event.preventDefault();
                      addChild("Son");
                    }}
                  >
                    {childCount === 4 || !insurerCBXArray.includes("son") ? (
                      <img
                        src={addBtndisabled}
                        css={`
                          height: 23px;
                          margin-right: 5px;
                        `}
                      />
                    ) : (
                      <img
                        src={addBtn}
                        css={`
                          height: 23px;
                          margin-right: 5px;
                        `}
                      />
                    )}
                    {/* <StyledIcon className="fa fa-plus-circle"></StyledIcon>{" "} */}
                    <span
                      css={`
                        color: ${(childCount === 4 ||
                          !insurerCBXArray.includes("son")) &&
                        "#000000a1  !important"};
                      `}
                    >
                      Add Son
                    </span>
                  </AddMemberButton>
                </Col>
                <Col
                  md={4}
                  lg={4}
                  xs={6}
                  sm={4}
                  style={{ borderRight: "1px solid #ddd" }}
                  css={`
                    display: flex;
                    justify-content: center;
                    width: 100%;
                  `}
                >
                  <AddMemberButton
                    onClick={event => {
                      event.preventDefault();
                      addChild("Daughter");
                    }}
                  >
                    {/* <StyledIcon className="fa fa-plus-circle"></StyledIcon>{" "} */}
                    {childCount === 4 ||
                    !insurerCBXArray.includes("daughter") ? (
                      <img
                        src={addBtndisabled}
                        css={`
                          height: 23px;
                          margin-right: 5px;
                        `}
                      />
                    ) : (
                      <img
                        src={addBtn}
                        css={`
                          height: 23px;
                          margin-right: 5px;
                        `}
                      />
                    )}
                    <span
                      css={`
                        color: ${(childCount === 4 ||
                          !insurerCBXArray.includes("daughter")) &&
                        "#000000a1  !important"};
                      `}
                    >
                      Add Daughter
                    </span>
                  </AddMemberButton>
                </Col>
                <Col
                  md={4}
                  lg={4}
                  sm={4}
                  xs={6}
                  css={`
                    display: flex;
                    justify-content: center;
                    width: 100%;
                  `}
                >
                  <AddMemberButton
                    onClick={event => {
                      event.preventDefault();
                      setShow(true);
                    }}
                  >
                    {/* <img src={add} /> */}
                    <img
                      src={addBtn}
                      css={`
                        height: 23px;
                        margin-right: 5px;
                      `}
                    />
                    <span>Other Members</span>
                  </AddMemberButton>
                </Col>
              </div>

              <StyledHr />
            </span>
          </Row>
        </Container>
     
        {errors && (
          <ErrorMessage2
            css={`
              @media (max-width: 767px) {
                text-align: center;
              }
            `}
            style={{ fontSize: "15px" }}
          >
            {errors}
          </ErrorMessage2>
        )}

        {error &&
          error.map(msg => (
            <ErrorMessage2
              css={`
                @media (max-width: 767px) {
                  text-align: center;
                }
              `}
              style={{ fontSize: "15px" }}
            >
              {msg}
            </ErrorMessage2>
          ))}
        <span
          css={`
            display: none;

            @media (max-width: 767px) {
              display: flex;
              width: 100%;
              justify-content: center;
            }
          `}
        >
          <AddMemberButton
            onClick={event => {
              event.preventDefault();
              setShow(true);
            }}
            css={`
              display: none;
              @media (max-width: 767px) {
                display: flex;
                justify-content: space-between;
                width: 123px;
              }
            `}
          >
            {/* <img src={add} /> */}
            <img
              src={addothermember}
              css={`
                height: 12px;
                }
              `}
            />{" "}
            <span>Other Member</span>
          </AddMemberButton>
        </span>
        <span
          css={`
            @media (max-width: 767px) {
              display: flex;
            }
          `}
        >
          <StyledButton
            value={"Continue"}
            onClick={e => {
              e.preventDefault();
              const ageErrorArray = [];
              insurerCBXArray.forEach(data => {
                const hasAge = insurerDDArray.some(
                  item => item.insurer === data,
                );
                if (!hasAge) {
                  ageErrorArray.push(data);
                }
              });

              if (insurerCBXArray.length < 1) {
                setErrors("Select at least one Insured");
              } else if (
                insurerDDArray.length < 1 ||
                ageErrorArray.length > 0
              ) {
                setErrors("Select age for Insured");
                setAgeError(ageErrorArray);
              } else {
                setErrors(false);
              }
              if (
                ageErrorArray.length < 1 &&
                !errors &&
                insurerDDArray.length > 0
              ) {
                const dataArray = [];
                insurerDDArray.forEach(data => {
                  const i = membersArray.findIndex(
                    x => x.code === data.insurer,
                  );
                  if (membersArray[i]?.is_primary) {
                    dataArray.push({
                      type: `${membersArray[i].code}`,
                      age: data.value.endsWith("months") ? `0.${data.value.split(" ")[0]}`: `${data.value.split(" ")[0]}` ,
                    });
                  }
                });
                dispatch(saveForm3UserDetails(dataArray, handleChange));
              }
            }}
            laptopLg
            mobileSm
          />
        </span>
      </form>

      <GreetingFormModal
        ageError={ageError}
        show={showModal}
        setShow={setShow}
        setMembersArray={setMembersArray}
        membersArray={membersArray}
        handleChange={handleInput}
        modalTitle={"All your family members"}
        insurerCBXArray={insurerCBXArray}
        insurerDDArray={insurerDDArray}
        handleInput={handleInput}
      />
    </fieldset>
  );
};

export default Fieldset3;

const ErrorMessage = styled.div`
  position: relative;
  margin-top: 4px;
  display: block;
  color: #d43d3d;
  font-size: 15px;
`;
const StyledHr = styled.hr`
  background: #f5f6f9;
  height: 0px;
  width: 100%;
`;

const AddMemberButton = styled.button`
  font-size: 17px;
  line-height: 55px;
  color: #000;
  padding: 0px 8px;
  width: 150px;
  height: 23px;
  line-height: 18px;
  font-weight: 900;
  display: flex;
  flex-direction: row;
  align-content: center;
  justify-content: center;
  align-items: center;
  & span {
    position: relative;
    // top: -3px;
  }
  @media (max-width: 767px) {
    justify-content: unset;
    height: 42px;
  }
`;

const StyledIcon = styled.i`
  font-size: 25px !important;
  color: #c72229 !important;
  margin-top: -1px;
  margin: 0 5px;
`;
