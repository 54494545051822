import max_bupa from "./Maxbupa.png";
import care from "./Care.png";
import star from "./Star.png";
import tata from "./tata-aig.png";
import manipal from "./manipal.png";
import aditya from "./aditya.png";
import hdfc from "./hdfc.png";
import icici_lombard from "./icici_lombard.png";
import digit from "./digit.png";
import universal_sompo from './universal_n.png'
import sbi_g from "./sbi_general.png";
import kotak_g from "./KGI logo.png";
import raheja_log from "./Raheja logo.png";
import future_generl from "./FG logo.png"
import edilwiese from "./edelewaisee_logo.jpeg";
import reliance_group from "./Reliance logo.png"
import chola_ms_logo from "./Chola logo.png";
import royalSunderLogo from "./royal-sundaram-logo.png";

const images = {
  go_digit: digit,
  max_bupa: max_bupa,
  care_health: care,
  star: star,
  tata_aig: tata,
  aditya_birla: aditya,
  manipal_cigna: manipal,
  hdfc_ergo: hdfc,
  icici_lombard: icici_lombard,
  universal_sompo: universal_sompo,
  sbi_general:sbi_g,
  kotak_mahindra:kotak_g,
   raheja_qbe_general:raheja_log, 
  future_generali:future_generl,
  reliance_general:reliance_group,
  edelweiss_general:edilwiese,
  cholmandalam_ms:chola_ms_logo,
  royal_sundaram: royalSunderLogo,
};

export { images };
