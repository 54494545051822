import React from "react";
import styled from "styled-components";

const SolidBuyButton = ({ value, onClick, ...otherProps }) => {
  return (
    <StyledSolidBuyButton {...otherProps} onClick={onClick}>
      {value}
    </StyledSolidBuyButton>
  );
};

export default SolidBuyButton;

const StyledSolidBuyButton = styled.button`
  width: 135px;
  line-height: 32px;
  border-radius: 2px;
  padding: 7px 21px;
  text-align: center;
  color: #fff;
  text-transform: capitalize;
  box-shadow: 0px 13px 27px 0px rgb(163 48 53 / 25%);
  margin: 14px 17px 0;
  font-size: 18px;
  background: #c72229;
  &:hover {
    box-shadow: 0 2px 16px -2px #c72229;
  }
  @media (max-width: 1023px) {
    margin: 14px 7px 0;
  }
`;
