import React from "react";

const Checkbox = ({ value, onChange, checked, sm }) => {
  return (
    <label
      className="check"
      style={{ backgroundColor: "#fff" }}
      // style={{ backgroundColor: checked ? "#fff1f1" : "#fff" }}
    >
      <input
        type="checkbox"
        value={value}
        onChange={onChange}
        checked={checked}
      />
      <span
        className="check-value"
        style={{
          fontWeight: "900",
          color: "#000",
        }}
      >
        {value}
      </span>
    </label>
  );
};

export default Checkbox;
