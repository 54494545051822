export const medicalHistoryRadioArr = [
  {
    type: "radio",
    id: "hasMedicalYes",
    value: "Yes",
    image: "Yes",
    name: "medicalHistory",
    itemsCentered: true,
    label: "Yes",
  },
  {
    type: "radio",
    id: "hasMedicalNo",
    value: "No",
    image: "Yes",
    name: "medicalHistory",
    itemsCentered: true,
    label: "No",
  },
];
