import { createSlice } from "@reduxjs/toolkit";
import { getRecommendedQuotes } from "./serviceApi";

const recommendedPageSlice = createSlice({
  name: "recommendedPage",
  initialState: {
    questions: [],
    selectedHospitals: {},
    recommendedQuotes: {},
    answers: {},
    loading: false,
  },
  reducers: {
    setSelectedHospitals: (state, action) => {
      const { groupCode, selectedHospitals } = action.payload;
      state.selectedHospitals[groupCode] = selectedHospitals;
    },
    setQuestions: (state, action) => {
      state.questions = action.payload;
    },
    setRecommendedQuotes: (state, action) => {
      const { groupCode, recommendedQuotes } = action.payload;
      state.recommendedQuotes[groupCode] = recommendedQuotes;
    },
    setAnswers: (state, action) => {
      const { groupCode, answers } = action.payload;
      state.answers[groupCode] = answers;
    },
    setLoading: (state, action) => (state.loading = action.payload),
  },
});

export const {
  setSelectedHospitals,
  setQuestions,
  setAnswers,
  setRecommendedQuotes,
  setLoading,
} = recommendedPageSlice.actions;

export default recommendedPageSlice.reducer;

export const getRecommendedQuotesOnMount = groupCode => {
  return async dispatch => {
    try {
      dispatch(setLoading(true));
      const data = await getRecommendedQuotes({ groupCode });
      dispatch(setLoading(false));
      dispatch(
        setRecommendedQuotes({
          groupCode: groupCode,
          recommendedQuotes: data?.data?.data,
        }),
      );
    } catch (err) {
      console.error(err);
    }
  };
};

export const selectQuestions = state => state.recommendedPage.questions || [];

export const selectAnswers = groupCode => state =>
  state.recommendedPage.answers[groupCode] || {};

export const selectRecommenedQuotes = groupCode => state =>
  state.recommendedPage.recommendedQuotes[groupCode] || [];

export const selectSelectedHospitals = groupCode => state =>
  state.recommendedPage.selectedHospitals[groupCode] || [];

export const selectIsRecommendedQuotesLoading = () => state => state.loading;
