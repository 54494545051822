import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { setAsyncOptions } from "../../../components/FormBuilder/FormBuilder.slice";

const DropDown = ({
  label,
  onChange,
  reference,
  options,
  value,
  error,
  asyncOptions,
}) => {
  const [selectOption, setSelectOption] = useState({});
  useEffect(() => {
    if (asyncOptions) {
      setSelectOption(asyncOptions);
    } else setSelectOption(options);
  }, [asyncOptions]);
  return (
    <SelectContainer>
      <Select onChange={onChange} value={value}>
        <option>- Select -</option>
        {Object.keys(options)?.map(item => (
          <option key={item} value={item}>
            {options[item]}
          </option>
        ))}
      </Select>
      <Label>{label}</Label>
      {error && <p className="formbuilder__error">{error}</p>}
    </SelectContainer>
  );
};

export default DropDown;
const SelectContainer = styled.div`
  margin-top: 0.2rem !important;
  position: relative;
  z-index: 1;
  margin-bottom: 1.5rem !important;
`;

const Select = styled.select`
  list-style: none;
  list-style-type: none;
  user-select: none;
  -webkit-tap-highlight-color: transparent;
  box-sizing: border-box;
  margin: 0;
  font-family: inherit;
  line-height: inherit;
  overflow: visible;
  outline: none;
  box-shadow: none;
  transition: all 0.3s ease-in-out;
  touch-action: manipulation;
  width: 100%;
  border: 1px solid #ced4da;
  border-radius: 8px;
  background: transparent;
  height: 54px;
  font-size: 16px;
  color: #939393;
  position: relative;
  padding: 0 25px;
  &:focus {
    border-color: #393939;
    color: black;
  }
`;
const Label = styled.label`
  text-align: left;
  list-style: none;
  list-style-type: none;
  user-select: none;
  box-sizing: border-box;
  touch-action: manipulation;
  display: inline-block;
  font-size: 15px;
  color: #000;
  line-height: 14px;
  position: absolute;
  left: 20px;
  top: -6px;
  margin: 0;
  background: #fff;
  transition: all 0.3s ease-in-out;
  font-weight: 900;
  padding: 0 5px;
  font-weight: 900;
  padding: 0 5px;
  font-family: 'pf_handbook_proregular';
}
`;
