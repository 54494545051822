const { createSlice } = require("@reduxjs/toolkit");

const proposerSlice = createSlice({
  name: "proposer",
  initialState: null,
  reducers: {
    updateProposerData: (state, action) => {
      state = action.payload;
    },
  },
});

export const { updateProposerData } = proposerSlice.actions;

export default proposerSlice.reducer;
