import React from "react";
import styled from "styled-components";

const BackBtn = ({ onClick }) => {
	return (
		<Button
			type="button"
			onClick={onClick}
			className="next action-button showOnMobile"
			value="Get Started"

			//	style="background: transparent; color: #c7222a; float: left; /*position: relative; */top: 325px;"
		>
			<i className="icon flaticon-back"></i> Back
		</Button>
	);
};

export default BackBtn;

const Button = styled.button`
	background: transparent !important;
	color: #c7222a;
	float: left;
	top: -4px;
	position: relative;
`;
