import React, { useState } from "react";
import { Row, Col, Alert } from "react-bootstrap";
import BackIcon from "../../components/BackIcon/BackIcon";
import { Label } from "../../components/FormComponents/FormComponents";
import StyledButton from "../../components/Button/StyledButton";
import styled from "styled-components/macro";

import { UnorderedList } from "../../components/Radio/MedicalHistoryRadio";
import { medicalHistoryRadioArr } from "../fieldsets/data/fieldSet5Data/data";
import { fifthFormSchema } from "../../../../utils/validationSchema";
import { useDispatch, useSelector } from "react-redux";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers";
import {
  saveForm5UserDetails,
  updateUserDetails,
} from "./../../reducer/greetingPage.slice";
import {
  RadioInput,
  RadioLabel,
  RadioLabelImage,
} from "../../components/Radio/FamilyRadio";
import CheckBox from "../../components/CheckBox/CheckBox";
import check from "../../../../assets/images/checkmark.png";
import check2 from "../../../../assets/images/checkmarkRadio.png";
import wrong from "../../../../assets/images/wrong.png";
import { ErrorMessage } from "../../components/TextInput/GreetingPage/GreetingTextInput";
import { useHistory } from "react-router-dom";
import SecureLS from "secure-ls";
import BackBtn from "../../components/Button/BackBtn";
import backButton from "../../../../assets/images/backbutton.png";

const Fieldset5 = ({ currentForm, handleChange, value, isIndividualPlan }) => {
  const ls = new SecureLS();
  const history = useHistory();
  const dispatch = useDispatch();
  const [selected, setSelected] = useState("");
  const [diseaseArray, setDiseaseArray] = useState([]);

  const { frontendData } = useSelector(state => state.frontendBoot);

  const { data } = frontendData || [""];
  const { existingdiseases } = data || [""];

  const { register, handleSubmit, errors } = useForm({
    resolver: yupResolver(fifthFormSchema),
    mode: "onChange",
  });

  const pushToQuotes = (groupCode) => {
    history.push({
      pathname: `/quotes/${groupCode}`,
      search: `enquiryId=${ls.get("enquiryId")}`,
    });
  };

  const handleDiseases = disease => {
    const tempArray = [...diseaseArray];

    if (!tempArray.includes(disease)) {
      tempArray.push(disease);
    } else {
      const index = tempArray.indexOf(disease);

      if (index > -1) {
        tempArray.splice(index, 1);
      }
    }
    setDiseaseArray(tempArray);
  };

  const diseaseDataSet = diseases => {
    return (
      diseases &&
      diseases.map(({ display_name, code }, i) => {
        return (
          <div key={i} style={{ display: "inline" }}>
            <RadioInputM
              className="checkbox-tools"
              type={"radio"}
              value={code}
              id={code}
              checked={(diseaseArray.includes(code) && true) || undefined}
              onClick={() => handleDiseases(code)}
              onChange={() => {}}
            />
            <RadioLabelM
              className="for-checkbox-tools"
              css={`
                margin-bottom: ${diseaseArray.includes(code) &&
                "12px !important"};
              `}
              htmlFor={code}
              style={{
                display: diseaseArray.includes(code) && "flex",
                flexDirection: diseaseArray.includes(code) && "row",
                justifyContent: diseaseArray.includes(code) && "space-between",
              }}
            >
              <span
                style={{ marginRight: diseaseArray.includes(code) && "5px" }}
              >
                {display_name}
              </span>
              {diseaseArray.includes(code) && <img src={check2} />}
            </RadioLabelM>
          </div>
        );
      })
    );
  };

  const memberGroups = useSelector(
    ({ greetingPage }) => greetingPage.memberGroups,
  );

  const onSubmit = data => {
    const medical_history = [...diseaseArray];
    dispatch(saveForm5UserDetails(medical_history,pushToQuotes));
  };
  return (
    <fieldset style={{ display: currentForm === value ? "block" : "none" }}>
      <div>
        <BackIcon
          onClick={() => {
            if (isIndividualPlan) {
              handleChange("form3");
            } else {
              handleChange("form4");
            }
          }}
        />
      </div>
      <span
        css={`
          @media (max-width: 767px) {
            display: flex;
            align-items: center;
            align-content: center;
          }
        `}
      >
        <a
          onClick={() => {
            if (isIndividualPlan) {
              handleChange("form3");
            } else {
              handleChange("form4");
            }
          }}
          className="showOnMObile"
          css={`
            display: none !important;
            @media (max-width: 767px) {
              display: inline-block !important;
              height: 79px;
              z-index: 999;
              margin-left: -12px;
            }
          `}
        >
          <img
            src={backButton}
            css={`
              height: 58px;
            `}
          />
        </a>
        <Label
          css={`
            @media (max-width: 767px) {
              width: 85%;
            }
          `}
        >
          {isIndividualPlan
            ? "Do you have "
            : "Do you or family member to be insured has "}{" "}
          any medical history?
        </Label>
      </span>
      <div>
        <Label
          css={`
            font-weight: 100;
            font-size: 16px;
            margin-top: 7px !important;
            line-height: 1;
            padding: 0px !important;
            position: relative;
            top: -24px;
          `}
        >
          Select YES if you are suffering or suffered from any ailments; or were
          hospitalized in the past due to any disease
        </Label>
      </div>
      <form autoComplete="off" noValidate onSubmit={handleSubmit(onSubmit)}>
        <Page style={{ marginBottom: selected !== "Yes" && "64px" }}>
          <div className="">
            {medicalHistoryRadioArr.map(
              ({ id, value, image, name, itemsCentered, label }) => (
                <div
                  // onMouseEnter={e => setSelected(value)}
                  key={id}
                  style={{ display: "inline" }}
                >
                  <RadioInput
                    type="radio"
                    name={name}
                    id={id}
                    value={value}
                    checked={selected === value}
                    onChange={e => {
                      if (value === "No") {
                        const medical_history = [...diseaseArray];
                        dispatch(saveForm5UserDetails(medical_history,pushToQuotes));
                      } else {
                        setSelected(e.target.value);
                      }
                    }}
                    ref={register}
                  />
                  <RadioLabel
                    htmlFor={id}
                    okay
                    itemsCentered={true}
                    // itemsCentered={itemsCentered ? true : undefined}
                    css={`
                      @media (max-width: 767px) {
                        margin-bottom: ${value === "No" && "10px !important"};
                      }
                    `}
                  >
                    {selected === value && <RadioLabelImage src={check} />}
                    <span>{label}</span>
                  </RadioLabel>
                </div>
              ),
            )}
            {selected === "Yes" && (
              <ContainerMedi>
                <Label
                  css={`
                    @media (max-width: 767px) {
                      margin-bottom: 14px;
                    }
                  `}
                >
                  Let us know if it is any of the following?
                </Label>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    position: "relative",
                    left: "-7px",
                    marginBottom: "-11px",
                  }}
                  css={`
                    @media (max-width: 767px) {
                      flex-wrap: wrap;
                    }
                  `}
                >
                  {diseaseDataSet(existingdiseases)}
                </div>
              </ContainerMedi>
            )}
            {/* <UnorderedList activedList style={{ height: selected === "Yes" && "180px" }}>
              <li className={`typography2 ${selected === "Yes" && "active"}`}>
                <h1>Let us know if it is any of the following?</h1>
                <Row style={{ margin: "0px 24px", width: "70%" }}>
                  {diseaseDataSet(existingdiseases)}
                 
                </Row>
              </li>

              <li className={`typography ${selected === "No" && "active"}`}>
                <h1>Great, nice to know that</h1>
              </li>
            </UnorderedList> */}
          </div>
        </Page>
        {Object.keys(errors)[0] && (
          <ErrorMessage2 style={{ fontSize: "15px" }}>
            {errors[Object.keys(errors)[0]].message}
          </ErrorMessage2>
        )}{" "}
        <span
          css={`
            @media (max-width: 767px) {
              display: flex;
            }
          `}
        >
          <StyledButton value={"View Quotes"} type="submit" laptopLg mobileSm />
        </span>
      </form>
    </fieldset>
  );
};

export default Fieldset5;

const Page = styled.div`
  position: relative;
  max-width: 980px;
  margin: 0 -44px;
  @media (max-width: 767px) {
    width: 100%;
  }
`;

export const ErrorMessage2 = styled.p`
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.2;
  letter-spacing: normal;
  text-align: left;
  color: #c7222a;
`;

const RadioInputM = styled.input`
  &:checked,
  &:not(:checked) {
    position: absolute;
    left: -9999px;
    width: 0;
    height: 0;
    visibility: hidden;
  }

  &:not(:disabled) ~ label {
    cursor: pointer;
  }
`;
const RadioLabelM = styled.label`
  font-weight: 900;
  position: relative;
  top: -11px;

  & img {
    position: relative;
    left: 11px;
    @media (max-width: 767px) {
      left: 3px;
    }
  }
`;

const ContainerMedi = styled.div`
  display: flex;
  flex-direction: column;
  padding: 8px 18px 2px;
  margin-left: 28px;
  @media (max-width: 767px) {
    width: 107%;
  }
`;
