import React, { useRef, useState, useEffect } from "react";
import { Col, Container, Row } from "react-bootstrap";
import { useParams } from "react-router-dom";
import CardSkeletonLoader from "../../components/Common/card-skeleton-loader/CardSkeletonLoader";
import CardModal from "../../components/Common/Modal/CardModal";
import CardModalM from "../../components/Common/Modal/CardModelM";
import useWindowSize from "../../customHooks/useWindowSize";
import GoBack from "./components/GoBackBtn/GoBack";
import MobileHeader from "./components/MobileComp/Header";
import ShowDiffMobile from "./components/MobileComp/ShowDiffMobile";
import PlanContainerMobile from "./components/MobileComp/PlanContainerM";
import TBodyM from "./components/MobileComp/TBodyM";
import styled from "styled-components";
import THeadM from "./components/MobileComp/THeadM";
import useUrlQuery from "../../customHooks/useUrlQuery";
import TBlank from "./components/tables/TBlank";
import TBody from "./components/tables/TBody";
import THead from "./components/tables/THead";
import useComparePage from "./useComparePage";
import "./Compare.scss";
import mail from "./../../assets/images/whatsapp.png";
import whatsapp from "./../../assets/images/whatsapp01.png";
import BuyNowModal from "../QuotesPage/components/BuyNowModal/BuyNowModal";
import {
  AddPlan,
  CompanyName,
  CompareQuotes,
  CrossWrapper,
  DetailWrapper,
  DropDownWrapper,
  EmailSent,
  Line,
  LogoWrapper,
  MergedQuotes,
  NameWrapper,
  PlanDetails,
  PlanName,
  PlusWrapper,
  PopupWrapper,
  PopupWrapperM,
  QuoteName,
  QuoteNameM,
  QuoteWrapper,
  QuoteWrapperM,
  RemoveCross,
  SelectedProduct,
  Title,
  Value,
  ErrorAddPlan,
} from "./ComparePage.style";
import DropDown from "./components/DropDown";
import { useDispatch, useSelector } from "react-redux";
import { clearFilterQuotes } from "../QuotesPage/quotePage.slice";

// desktop whtsapp and email share popups
const sendContent = (
  type,
  name,
  imageSend,
  email,
  setEmail,
  number,
  setNumber,
  emailStatus,
  sendRef,
  whtsappError,
  setwhtsappError,
  emailError,
  setEmailError,
  
) => {



  console.log(number,"+++++")

  const handleChange = e => {
    
    if (type !== "email" && e.target.value.length <= 10) {
      setEmail("");
      return setNumber(e.target.value);
    }

    if (type === "email") {
      setNumber("");
      return setEmail(e.target.value);
    }
  };

  const handleClick = () => {

    setwhtsappError("");
    setEmailError("");

    if (type === "email") {
      
      const forbiddedSymbols = [
        "#",
        "!",
        "%",
        "$",
        "&",
        "=",
        "^",
        "*",
        "(",
        ")",
        "{",
        "}",
        "?",
        "|",
        "~",
        "`",
        "'",
        "/",
      ];
      for (let i in email) {
        if (forbiddedSymbols.includes(email[i])) {
          return setEmailError("Please enter a valid email.");
        }
      }
      if (
        !email.match(
          /^(([^<>()\[\]\.,;:\s@\"]+(\.[^<>()\[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i,
        )
      ) {
        return setEmailError("Enter a valid email");
      } else if (!emailError) {
        return imageSend(email);
      }
    }

    if (type !== "email") {
      if (number.length !== 10) {
        return setwhtsappError("Please enter 10 digit number.");
      }
      if (
        number[0] === "0" ||
        number[0] === "1" ||
        number[0] === "2" ||
        number[0] === "3" ||
        number[0] === "4" ||
        number[0] === "5"
      ) {
        return setwhtsappError("Please enter valid number.");
      } else if (whtsappError === "") {
        return sendRef.current.click();
      }
    }
  };

  return (
    <div className="text-center p-lg mb-50 sm-50 xs-50">
      <img
        src={type === "email" ? mail : whatsapp}
        alt="mail"
        className="img_whatsapp"
      ></img>
      <p className="mb-15 font_24">
        Hey {name}, please share your{" "}
        {type === "email" ? "e-mail address" : "phone number"} to get your
        compared plans
      </p>
      <form className="form ">
        <div className="position-relative my-2">
          <input
            type={type === "email" ? "email" : "number"}
            class="form__field"
            onChange={e => handleChange(e)}
            placeholder={
              type === "email"
                ? "Enter Your Email Address"
                : "Enter Your Phone Number"
            }
            value={type === "email" ? email : number}
          />
          <br />
          {
            whtsappError?(
            <span
              className="position-absolute"
              style={{
                bottom: "-3px",
                left: "50%",
                transform: "translateX(-50%)",
                color: "#c7222a",
              }}
            >
              *{type === "email" ? emailError : whtsappError}
            </span>
          ):<></>
          }
          {emailError ? (
            <span
              className="position-absolute"
              style={{
                bottom: "-3px",
                left: "50%",
                transform: "translateX(-50%)",
                color: "#c7222a",
              }}
            >
              *{type === "email" ? emailError : whtsappError}
            </span>
          ) : (
            type === "email" && (
              <EmailSent status={emailStatus.status}>
                {emailStatus.message}
              </EmailSent>
            )
          )}
        </div>

        <button
          type="button"
          className="btn--primary btn--inside btn_call uppercase text-center"
          onClick={() => handleClick()}
        >
          Share&nbsp;&nbsp;&nbsp;
          <i class="fa fa-share font_15"></i>
        </button>
        {number && whtsappError ==="" && (
          <a
            style={{
              display: "none",
              height: 0,
              opacity: 0,
              position: "absolute",
            }}
            target="_blank"
            ref={sendRef}
            rel="noreferrer"
            href={`https://api.whatsapp.com/send?phone=91${number}&text=${window.location.href}`}
          >
            Whatsapp
          </a>
        )}
      </form>
    </div>
  );
};



const getYearsUsingTenure = tenure => {
  if (tenure == 1) {
    return "year";
  } else if (tenure == 2) {
    return "2 years";
  } else if (tenure == 3) {
    return "3 years";
  } else return "year";
};



const popupContent = (
  fitlerQuotes,
  quotesForCompare,
  selectedAddPlan,
  setSelectedAddPlan,
  mergedQuotes,
  removePlan,
  value,
  setValue,
  errors,
  setErrors,
  discount,
  removePlan2point0,
) => {
  let companies = [];
  let companyWisePlans = {};
  let companyWiseSumAssured = {};
  let companyWiseLogos = [];
  let ProductWiseId = {};
  let covers = {};
  fitlerQuotes.forEach(item => {
    if (item[0]) {
      companies.push(item[0].product.company.name);
      companyWiseLogos.push(item[0].logo);

      item.forEach(innerItem => {
        if (!(innerItem.product.name in covers)) {
          covers[innerItem.product.name] = [innerItem.sum_insured];
        } else {
          covers[innerItem.product.name].push(innerItem.sum_insured);
        }
      });

      item.forEach(innerItem => {
        if (
          ((companyWisePlans[innerItem.product.company.name] &&
            !companyWisePlans[innerItem.product.company.name].includes(
              innerItem.product.name,
            )) ||
            !companyWisePlans[innerItem.product.company.name]) &&
          !mergedQuotes.some(
            item => item.data.product.name === innerItem.product.name,
          )
        ) {
          companyWisePlans = {
            ...companyWisePlans,
            [innerItem.product.company.name]: [
              ...(companyWisePlans[innerItem.product.company.name]
                ? companyWisePlans[innerItem.product.company.name]
                : []),
              innerItem.product.name,
            ],
          };
          ProductWiseId = {
            ...ProductWiseId,
            [innerItem.product.name]: [innerItem.product.id],
          };
        }
        if (
          (companyWiseSumAssured[innerItem.product.company.name] &&
            !companyWiseSumAssured[innerItem.product.company.name].includes(
              innerItem.sum_insured,
            )) ||
          !companyWiseSumAssured[innerItem.product.company.name]
        )
          companyWiseSumAssured = {
            ...companyWiseSumAssured,
            [innerItem.product.company.name]: [
              ...(companyWiseSumAssured[innerItem.product.company.name]
                ? companyWiseSumAssured[innerItem.product.company.name]
                : []),
              innerItem.sum_insured,
            ],
          };
      });
    }
  });

  return (
    <>
      <PopupWrapper>
        <MergedQuotes className="row">
          {[0, 1, 2].map((item, index) => (
            <div className="col-lg-4">
              {mergedQuotes[index] ? (
                <SelectedProduct first={index === 0}>
                  <RemoveCross
                    onClick={() =>
                      removePlan(
                        `${mergedQuotes[index].data.product.id}${mergedQuotes[index].data.sum_insured}`,
                      )
                    }
                  >
                    <span>
                      <i class="fa fa-close"></i>
                    </span>
                  </RemoveCross>
               
                  <LogoWrapper className="fit_logo_common" style={{marginTop:"0px",marginRight:"5px"}}>
                    <img src={mergedQuotes[index].data.logo} alt="logo"></img>
                  </LogoWrapper>
                  <NameWrapper>
                    <CompanyName>
                      {mergedQuotes[index].data.product.company.name}
                    </CompanyName>
                    <PlanName>
                      {" "}
                      {mergedQuotes[index].data.product.name}
                    </PlanName>
                    <PlanDetails>
                      <DetailWrapper>
                        <Title>Sum Insured</Title>
                        <Value>
                          <i class="fa fa-inr"></i>{" "}
                          {mergedQuotes[index].data.sum_insured.toLocaleString(
                            "en-IN",
                          )}
                        </Value>
                      </DetailWrapper>
                      <DetailWrapper>
                        <Title>Premium</Title>
                        <Value>
                          <i class="fa fa-inr"></i>{" "}
                          {(discount[
                            `${mergedQuotes[index].data.product.id}${mergedQuotes[index].data.sum_insured}`
                          ]
                            ? discount[
                                `${mergedQuotes[index].data.product.id}${mergedQuotes[index].data.sum_insured}`
                              ].premium
                            : mergedQuotes[index].data.premium
                          ).toLocaleString("en-IN") +
                            "/" +
                            getYearsUsingTenure(
                              discount[
                                `${mergedQuotes[index].data.product.id}${mergedQuotes[index].data.sum_insured}`
                              ]?.tenure,
                            )}
                        </Value>
                      </DetailWrapper>
                    </PlanDetails>
                  </NameWrapper>
                </SelectedProduct>
              ) : (
                <AddPlan>
                  <CrossWrapper>
                    <PlusWrapper>
                      <i class="fa fa-plus"></i>
                    </PlusWrapper>
                    <div>No Plans Added</div>
                  </CrossWrapper>
                </AddPlan>
              )}
            </div>
          ))}
        </MergedQuotes>
        <Line></Line>
        <CompareQuotes>
          <div className="row" style={{ padding: "10px" }}>
            {companies.map((item, index) => {
              if (companyWisePlans[item])
                return (
                  <div className="col-lg-4">
                    <QuoteWrapper>
                      <LogoWrapper className="fit_logo_common" style={{marginTop:"0px",marginRight:"5px"}}>
                        <img src={companyWiseLogos[index]} alt="logo"></img>
                      </LogoWrapper>
                      <QuoteName>{item}</QuoteName>
                      <DropDownWrapper>
                        <DropDown
                          name={companyWisePlans[item]}
                          sum={companyWiseSumAssured[item]}
                          covers={covers}
                          onChange={value => {
                            setValue(prev => {
                              return { ...prev, [item]: value };
                            });
                            if (mergedQuotes.length >= 3) {
                              setErrors(prev => {
                                return {
                                  ...prev,
                                  [item]: "You can add only upto 3 plans",
                                };
                              });
                            } else if (value?.plan && value?.sumInsured) {
                              setSelectedAddPlan(
                                `${ProductWiseId[value.plan]}${
                                  value?.sumInsured
                                }`,
                              );
                              setValue({});
                            }
                          }}
                          value={value[item]}
                        ></DropDown>
                      </DropDownWrapper>
                    </QuoteWrapper>
                    <ErrorAddPlan>
                      {errors[item] ? errors[item] : ""}
                    </ErrorAddPlan>
                  </div>
                );
              else return <></>;
            })}
          </div>
        </CompareQuotes>
      </PopupWrapper>
    </>
  );
};

// popup content for mobile view

const popupContentM = (
  fitlerQuotes,
  quotesForCompare,
  selectedAddPlan,
  setSelectedAddPlan,
  mergedQuotes,
  removePlan,
  value,
  setValue,
  errors,
  setErrors,
  discount,
  windowWidth,
  removePlan2point0,
) => {
  let companies = [];
  let companyWisePlans = {};
  let companyWiseSumAssured = {};
  let companyWiseLogos = [];
  let ProductWiseId = {};

  fitlerQuotes.forEach(item => {
    if (item[0]) {
      companies.push(item[0].product.company.name);
      companyWiseLogos.push(item[0].logo);
      item.forEach(innerItem => {
        if (
          ((companyWisePlans[innerItem.product.company.name] &&
            !companyWisePlans[innerItem.product.company.name].includes(
              innerItem.product.name,
            )) ||
            !companyWisePlans[innerItem.product.company.name]) &&
          !mergedQuotes.some(
            item => item.data.product.name === innerItem.product.name,
          )
        ) {
          companyWisePlans = {
            ...companyWisePlans,
            [innerItem.product.company.name]: [
              ...(companyWisePlans[innerItem.product.company.name]
                ? companyWisePlans[innerItem.product.company.name]
                : []),
              innerItem.product.name,
            ],
          };
          ProductWiseId = {
            ...ProductWiseId,
            [innerItem.product.name]: [innerItem.product.id],
          };
        }
        if (
          (companyWiseSumAssured[innerItem.product.company.name] &&
            !companyWiseSumAssured[innerItem.product.company.name].includes(
              innerItem.sum_insured,
            )) ||
          !companyWiseSumAssured[innerItem.product.company.name]
        )
          companyWiseSumAssured = {
            ...companyWiseSumAssured,
            [innerItem.product.company.name]: [
              ...(companyWiseSumAssured[innerItem.product.company.name]
                ? companyWiseSumAssured[innerItem.product.company.name]
                : []),
              innerItem.sum_insured,
            ],
          };
      });
    }
  });

  return (
    <>
      <PopupWrapperM>
        <MergedQuotes
          className="row"
          style={{
            display: "flex",
            flexWrap: "nowrap",
            justifyContent: "space-between",
          }}
        >
          {[0, 1].map((item, index) => (
            <div
              style={windowWidth < 400 ? { width: "48%" } : { width: "46%" }}
            >
              {mergedQuotes[index] ? (
                <SelectedProduct first={index === 0}>
                  <RemoveCross
                    onClick={() =>
                      removePlan(
                        `${mergedQuotes[index].data.product.id}${mergedQuotes[index].data.sum_insured}`,
                      )
                    }
                  >
                    <span>
                      <i class="fa fa-close"></i>
                    </span>
                  </RemoveCross>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "center",
                    }}
                  >
                    <LogoWrapper>
                      <img src={mergedQuotes[index].data.logo} alt="logo"></img>
                    </LogoWrapper>

                    <CompanyName
                      style={{ fontSize: "13px", textAlign: "center" }}
                    >
                      {mergedQuotes[index].data.product.company.name}
                    </CompanyName>

                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                        width: "100%",
                      }}
                    >
                      <Title style={{ fontSize: "12px" }}>Sum Insured :</Title>
                      <Value style={{ fontSize: "12px" }}>
                        <i class="fa fa-inr"></i>{" "}
                        {mergedQuotes[index].data.sum_insured.toLocaleString(
                          "en-IN",
                        )}
                      </Value>
                    </div>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                        width: "100%",
                      }}
                    >
                      <Title style={{ fontSize: "12px" }}>Premium :</Title>
                      <Value style={{ fontSize: "12px" }}>
                        <i class="fa fa-inr"></i>{" "}
                        {(discount[
                          `${mergedQuotes[index].data.product.id}${mergedQuotes[index].data.sum_insured}`
                        ]
                          ? discount[
                              `${mergedQuotes[index].data.product.id}${mergedQuotes[index].data.sum_insured}`
                            ].premium
                          : mergedQuotes[index].data.premium
                        ).toLocaleString("en-IN") +
                          "/" +
                          getYearsUsingTenure(
                            discount[
                              `${mergedQuotes[index].data.product.id}${mergedQuotes[index].data.sum_insured}`
                            ]?.tenure,
                          )}
                      </Value>
                    </div>
                  </div>
                </SelectedProduct>
              ) : (
                <AddPlan style={{ padding: "0px" }}>
                  <CrossWrapper>
                    <PlusWrapper>
                      <i class="fa fa-plus"></i>
                    </PlusWrapper>
                    <div>No Plans Added</div>
                  </CrossWrapper>
                </AddPlan>
              )}
            </div>
          ))}
        </MergedQuotes>
        <Line></Line>
        <div>
          <div className="row">
            {companies.map((item, index) => {
              if (companyWisePlans[item])
                return (
                  <div className="col-lg-4">
                    <QuoteWrapperM>
                      <LogoWrapper className="fit_logo_common_m" style={{marginTop:"0px",marginRight:"5px"}}>
                        <img src={companyWiseLogos[index]} alt="logo"></img>
                      </LogoWrapper>
                      <QuoteNameM>{item}</QuoteNameM>
                      <DropDownWrapper>
                        <DropDown
                          name={companyWisePlans[item]}
                          sum={companyWiseSumAssured[item]}
                          onChange={value => {
                            setValue(prev => {
                              return { ...prev, [item]: value };
                            });
                            if (mergedQuotes.length >= 3) {
                              setErrors(prev => {
                                return {
                                  ...prev,
                                  [item]: "You can add only upto 3 plans",
                                };
                              });
                            } else if (value?.plan && value?.sumInsured) {
                              setSelectedAddPlan(
                                `${ProductWiseId[value.plan]}${
                                  value?.sumInsured
                                }`,
                              );
                            }
                          }}
                          value={value[item]}
                        ></DropDown>
                      </DropDownWrapper>
                    </QuoteWrapperM>
                    <ErrorAddPlan>
                      {errors[item] ? errors[item] : ""}
                    </ErrorAddPlan>
                  </div>
                );
            })}
          </div>
        </div>
      </PopupWrapperM>
    </>
  );
};

const ComparePage = () => {
  const {
    loading,
    mergedQuotes,
    showDiffCbx,
    setshowDiffCbx,
    removePlan,
    QuotesToAdd,
    handleCompare,
    quotesForCompare,
    mergedCover,
    selectedAddPlan,
    setSelectedAddPlan,
    show,
    setShow,
    showM,
    setShowM,
    hideCells,
    setHideCells,
    showBuyNowPopup,
    setShowBuyNowPopup,
    imageSend,
    emailStatus,
    errors,
    setErrors,
    discount,
    removePlan2point0,
  } = useComparePage();
  const proposerMobile = useSelector(({greetingPage}) => greetingPage.proposerDetails.mobile);
const proposerEmail = useSelector(({greetingPage}) => greetingPage.proposerDetails.email);
  const [windowHeight, windowWidth] = useWindowSize();
  const [whtsappError, setwhtsappError] = useState("");
  const [emailError, setemailError] = useState("");
  const [email, setEmail] = useState(proposerEmail);
  const [number, setNumber] = useState(proposerMobile);
  const { groupCode } = useParams();
  const [send, setSend] = useState(false);
  const [value, setValue] = useState({});


  const sendRef = useRef();
  const dispatch = useDispatch();

  const { proposerDetails } = useSelector(state => state.greetingPage);

  

  return (
    <>
      {showBuyNowPopup && (
        <BuyNowModal
          showBuyNow={showBuyNowPopup}
          setShowBuyNow={setShowBuyNowPopup}
        />
      )}

      {loading ? (
        <div className="agn-our-pricing pb-200  mgt-5">
          <GoBack path={"/quotes"} groupCode={groupCode} />
          <Container className="tab-content tab-content_mt_comapre">
            <CardSkeletonLoader noOfCards={3} />
          </Container>
        </div>
      ) : (
        <>
          {" "}
          {/*Mobile header - will visible only on mobie screen*/}
          <div className="mobile-view" className="showOnMobile">
            <MobileHeader
              emailStatus={emailStatus}
              EmailSent={EmailSent}
              imageSend={imageSend}
              sendContent={sendContent}
              groupCode={groupCode}
              path={"/quotes"}
            />
            <ShowDiffMobile
              showDiffCbx={showDiffCbx}
              setshowDiffCbx={setshowDiffCbx}
            />
          </div>
          <div className="agn-our-pricing pb-200 mgt-5 ">
            {/* will visible only on desktop screen by media query*/}
            <div className="desktop-header hideOnMobile">
              <ul className="menu topRight">
                <li class="share bottom">
                  <i class="fa fa-share-alt share"></i>

                  <ul class="submenu">
                    <li>
                      <button
                        onClick={() => {
                          setSend("email");
                        }}
                      >
                        <i class="fa fa-envelope-o"></i>
                      </button>
                    </li>
                    <li>
                      <button onClick={() => setSend("whatsapp")}>
                        <i class="fa fa-whatsapp"></i>
                      </button>
                    </li>
                  </ul>
                </li>
              </ul>
              <GoBack path={"/quotes"} groupCode={groupCode} />
            </div>

            {/* mobile content ( visible on mobile screen )*/}
            <div id="printCompareM">
              <div className="table-wrapper showOnMobile">
                <table className="table table-hover">
                  <THeadM
                    setShowM={setShowM}
                    plans={mergedQuotes}
                    removePlan={removePlan2point0}
                    setshowDiffCbx={setshowDiffCbx}
                    showDiffCbx={showDiffCbx}
                    setShowBuyNowPopup={setShowBuyNowPopup}
                  />
                  <TBlank />
                  <TBodyM
                    title={"Plan Details"}
                    showDiffCbx={showDiffCbx}
                    plans={mergedQuotes}
                    mergedCover={mergedCover}
                  />

                  <TBodyM
                    title={"Key Benefits"}
                    plans={mergedQuotes}
                    showDiffCbx={showDiffCbx}
                  />

                  {mergedQuotes.length > 0 &&
                    mergedQuotes[0].features?.map(
                      (features, i) =>
                        !hideCells.includes(features?.name) && (
                          <>
                            <TBodyM
                              showDiffCbx={showDiffCbx}
                              title={features?.name}
                              plans={mergedQuotes}
                              index={i}
                              setHideCells={setHideCells}
                              hideCells={hideCells}
                            />
                          </>
                        ),
                    )}

                  <TBodyM
                    title={"Permanent Exclusions"}
                    plans={mergedQuotes}
                    showDiffCbx={showDiffCbx}
                  />
                </table>
              </div>
            </div>

            {/* desktop conetent ( visible on desktop screen only ) */}
            <Container
              className="tab-content tab-content_mt_comapre hideOnMobile"
              id="printCompare"
            >
              <div>
                <div className="table-wrapper">
                  <table className="table table-hover">
                    <THead
                      plans={mergedQuotes}
                      setshowDiffCbx={setshowDiffCbx}
                      showDiffCbx={showDiffCbx}
                      removePlan={removePlan2point0}
                      setShow={setShow}
                      setShowBuyNowPopup={setShowBuyNowPopup}
                    />
                    <TBlank />
                    <TBody
                      title={"Plan Details"}
                      plans={mergedQuotes}
                      mergedCover={mergedCover}
                    />
                    <TBlank />
                    <TBody title={"Key Benefits"} plans={mergedQuotes} />
                    <TBlank />
                    {mergedQuotes.length > 0 &&
                      mergedQuotes[0].features?.map(
                        (features, i) =>
                          !hideCells.includes(features?.name) && (
                            <>
                              <TBody
                                showDiffCbx={showDiffCbx}
                                title={features?.name}
                                plans={mergedQuotes}
                                index={i}
                                setHideCells={setHideCells}
                                hideCells={hideCells}
                              />
                              <TBlank />
                            </>
                          ),
                      )}
                  </table>
                </div>
              </div>
            </Container>
          </div>
        </>
      )}

      <CardModalM
        show={showM}
        title={"Add upto 2 plans to compare"}
        customClass={"addToCompare__Modal"}
        buttonValue={"Compare"}
        content={popupContentM(
          QuotesToAdd,
          quotesForCompare,
          selectedAddPlan,
          setSelectedAddPlan,
          mergedQuotes,
          removePlan,
          value,
          setValue,
          errors,
          setErrors,
          discount,
          windowWidth,
        )}
        handleClose={() => {
          setShowM(false);
          setSelectedAddPlan("");
        }}
        handleClick={() => {
          setShowM(false);
          setSelectedAddPlan("");
        }}
      />

      <CardModal
        className="hideOnMobile"
        show={show}
        title={"Add upto 3 plans to compare"}
        customClass={"addToCompare__Modal"}
        buttonValue={"Compare"}
        content={popupContent(
          QuotesToAdd,
          quotesForCompare,
          selectedAddPlan,
          setSelectedAddPlan,
          mergedQuotes,
          removePlan,
          value,
          setValue,
          errors,
          setErrors,
          discount,
        )}
        handleClose={() => {
          setShow(false);
          setSelectedAddPlan("");
        }}
        handleClick={() => {
          setShow(false);
          setSelectedAddPlan("");
        }}
      />
      <CardModal
        show={send}
        content={sendContent(
          send,
          proposerDetails.name.split(" ")[0],
          imageSend,
          email,
          setEmail,
          number,
          setNumber,
          emailStatus,
          sendRef,
          whtsappError,
          setwhtsappError,
          emailError,
          setemailError,
         
        )}
        showButton={false}
        handleClose={() => setSend(false)}
      />
    </>
  );
};

export default ComparePage;

const ImageLogo = styled.img`
  border-radius: unset !important;
  margin-top: 8px;
  height: 40px;
  width: 40px;
`;
