import styled from "styled-components";

export const InputLabel = styled.label`
  color: #000;
  text-align: left !important;
  font-size: 15px;
  font-weight: 200;
  padding-bottom: 9px;
  padding-top: 4px;
  display: inline-block;
  position: absolute;
  top: -21px;
  left: 27px;
  font-size: 33px;
  font-weight: 900;
  line-height: 44px;
 
`;
