import React from "react";
import styled from "styled-components";
import covidImg from "../../../../assets/images/covid.png";

const PlanCard = () => {
	return (
		<CovidPlans>
			<img src={covidImg} />
			<CovidHeader>Covid Plans</CovidHeader>
			<CovidSubHeader>All health plans cover covid-19 treatment</CovidSubHeader>
		</CovidPlans>
	);
};

export default PlanCard;

const CovidPlans = styled.div`
	background: #fff;
	border-radius: 8px;
	box-shadow: 0 20px 30px -19px #00000038;
	margin: -9px 11px;
	padding: 12px;
	& img {
		float: left;
		margin: -10px 9px 0 0;
	}
	& p {
		margin: 0;
		color: black;
		line-height: 28px;
	}
`;

const CovidHeader = styled.p`
	font-size: 19px;
	font-weight: 900;

`;

const CovidSubHeader = styled.p`
	font-size: 15px;
	font-weight: 400;
	@media (max-width: 767px) {
		font-size: 11px;
	}
`;
