import { Link, useHistory, useParams } from "react-router-dom";
import useUrlQuery from "../../customHooks/useUrlQuery";
import { useDispatch, useSelector } from "react-redux";
import {
  selectAnswers,
  selectRecommenedQuotes,
  selectSelectedHospitals,
  setAnswers,
  setQuestions,
  setRecommendedQuotes,
  setSelectedHospitals,
} from "../RecommendedPage/recommendedPage.slice";
import { useEffect } from "react";
import {
  getRecommendationQuestions,
  getRecommendData,
  getRecommendedQuotes,
  getRecommendedQuotesApi,
} from "../RecommendedPage/serviceApi";
import { AnalysingScreen, RecommendedQuoteCard } from "./components";
import { useState } from "react";
import CardSkeletonLoader from "../../components/Common/card-skeleton-loader/CardSkeletonLoader";
import BuyNowModal from "../QuotesPage/components/BuyNowModal/BuyNowModal";
import styled from "styled-components/macro";
import { useCartProduct } from "../Cart";
import { Accordion, Container, Row } from "react-bootstrap";
import FundamentalCheck from "../RecommendedMore/components/FundamentalCheck/FundamentalCheck";
import bg2 from "../../assets/images/recommendedBG2.png";

import { testQuotes } from "./testQuotes";

import * as mq from "../../utils/mediaQueries";
import {
  MobileHeader,
  MobileHeaderText,
} from "../ProposalPage/ProposalPage.style";
import Summary from "../RecommendedPage/components/SummaryTab/Summary";
import Modal from "../../components/Common/Modal";
import { CloseButton } from "../Cart/components/SidebarCart/SidebarCart";
import { selectMembersWithAge } from "../GreetingPage/reducer/greetingPage.slice";
import BackgroundPng from "../../assets/images/bgg.png";
import { SummaryMobile } from "../RecommendedPage/Pages/Fieldsets";

function hospitals(networkHospitalsObject) {
  return Object.keys(networkHospitalsObject).map(pincode => ({
    pincode,
    name: networkHospitalsObject[pincode],
  }));
}

export function YourDetails({
  questionPage = false,
  setCurrentForm = () => {},
}) {
  const dispatch = useDispatch();
  const { groupCode } = useParams();

  const membersWithAge = useSelector(selectMembersWithAge(groupCode));

  const members = useSelector(
    state => state.greetingPage.memberGroups[groupCode],
  );

  const [showYourDetails, setShowYourDetails] = useState(false);

  const openYourDetails = () => setShowYourDetails(true);
  const closeYourDetails = () => setShowYourDetails(false);

  const [recommendData, setRecommendData] = useState(null);

  const answers = useSelector(selectAnswers(groupCode));

  const { gender } = useSelector(state => state.greetingPage.proposerDetails);

  useEffect(() => {
    if (Object.keys(answers).length === 0 && !questionPage) {
      getRecommendationQuestions({ groupCode }).then(res =>
        dispatch(
          setQuestions(
            res.data.map(question => {
              if (question.type === "multiselect") {
                return {
                  ...question,
                  options: question.options.map(
                    option => Object.keys(option)[0],
                  ),
                };
              }
              return question;
            }),
          ),
        ),
      );
      getRecommendData().then(data => {
        const answersData = { ...data.data[0] };
        delete answersData["_id"];
        delete answersData["created_at"];
        delete answersData["updated_at"];
        delete answersData["group"];
        delete answersData["enquiry_id"];
        delete answersData["network_hospital"];
        setRecommendData(answersData);
        dispatch(setAnswers({ groupCode, answers: answersData }));
        dispatch(
          setSelectedHospitals({
            groupCode,
            selectedHospitals: hospitals(data.data[0]["network_hospital"]),
          }),
        );
      });
    }
  }, []);

  return (
    <>
      <div
        css={`
          border: 1px solid #ddd;
          width: 16rem;
          display: flex;
          align-items: center;
          justify-content: space-between;
          padding: 0.37em 0.6em;
          border-radius: 6px;
          position: relative;
          background-color: #fff;
          box-shadow: 0 3px 6px 0 rgb(16 24 48 / 12%);

          ${mq.mobile} {
            display: none;
          }
        `}
      >
        <div>
          <p>Your Selected Details</p>
          <p
            css={`
              font-weight: normal;
              text-transform: capitalize;
            `}
          >
            {members.length === 1
              ? `${membersWithAge[0].type}, ${membersWithAge[0].age}${
                  members[0] === "self" ? `, ${gender}` : ""
                }`
              : members.join(", ")}
            ...
          </p>
        </div>
        <button
          css={`
            margin: 0.3em;
            border-radius: 50%;
            box-shadow: 0px 10px 20px rgb(134 156 213 / 25%);
            height: 26px;
            width: 26px;
            background-color: #fff;
          `}
          onClick={openYourDetails}
        >
          <i
            className="fa fa-angle-right"
            css={`
              margin: 0.2em 0 0 0.2em;
            `}
          />
        </button>
        {showYourDetails ? (
          <Modal>
            <div
              css={`
                position: absolute;
                top: -1px;
                left: -1px;
                z-index: 99;

                & .your_details_re {
                  position: static;
                  border-radius: 6px;
                  background-color: #fff;
                  box-shadow: 0px 10px 20px rgb(134 156 213 / 25%);
                }

                & .your_select_t_sub {
                  &::after {
                    background-color: #f5bc00;
                  }
                }
              `}
            >
              <CloseButton
                css={`
                  position: absolute;
                  right: 10px;
                  top: 10px;
                `}
                onClick={closeYourDetails}
              />
              <Summary
                resultPage={!questionPage}
                setCurrentForm={
                  questionPage
                    ? idx => {
                        setCurrentForm(idx);
                        closeYourDetails();
                      }
                    : () => {}
                }
              >
                <div
                  css={`
                    margin-top: -1.2em;
                    margin-bottom: 0.7em;
                  `}
                >
                  {membersWithAge.map(member => (
                    <div
                      css={`
                        color: #000;
                      `}
                    >
                      <span
                        css={`
                          text-transform: capitalize;
                          font-weight: 900;
                        `}
                      >
                        {member.type}:
                      </span>
                      <span
                        css={`
                          margin-left: 0.3em;
                          color: #727f9f;
                          font-weight: 900;
                        `}
                      >
                        {member.age}
                        {["self", "spouse"].includes(member.type) && ", "}
                      </span>
                      {["self", "spouse"].includes(member.type) && (
                        <span
                          css={`
                            margin-left: 0.3em;
                            color: #727f9f;
                            font-weight: 900;
                          `}
                        >
                          {member.type === "self"
                            ? gender === "M"
                              ? "Male"
                              : "Female"
                            : member.type === "spouse"
                            ? gender === "M"
                              ? "Female"
                              : "Male"
                            : ""}
                        </span>
                      )}
                    </div>
                  ))}
                </div>
                {/* {recommendData && (
                <div>
                  {Object.keys(recommendData).map(key => {
                    if (
                      [
                        "created_at",
                        "enquiry_id",
                        "updated_at",
                        "_id",
                        "group",
                      ].includes(key)
                    )
                      return null;
                    return (
                      <div
                        css={`
                          display: flex;
                          justify-content: space-between;
                        `}
                      >
                        <span>{key}:</span>
                        <span>{recommendData[key]}</span>
                      </div>
                    );
                  })}
                </div>
              )} */}
              </Summary>
            </div>
          </Modal>
        ) : null}
      </div>
      <div
        css={`
          box-shadow: rgb(134 156 213 / 25%) 0px 3px 10px;
          font-size: min(calc(16px * 100vw / 360), 12px);
          display: none;
          align-items: center;
          justify-content: space-between;
          padding: 0.3em;
          ${mq.mobile} {
            display: flex;
          }
        `}
      >
        {showYourDetails ? (
          <SummaryMobile
            onClose={setShowYourDetails}
            showPopup
            setCurrentForm={() => {}}
            resultPage
          />
        ) : null}
        <div
          css={`
            font-size: 1em;
            font-weight: 900;
          `}
        >
          Your Selected Details:
        </div>
        <div
          css={`
            display: flex;
            align-items: center;
          `}
        >
          <p
            css={`
              font-size: 0.79em;
              font-weight: normal;
              text-transform: capitalize;
            `}
          >
            {members.length === 1
              ? `${membersWithAge[0].type}, ${membersWithAge[0].age}${
                  members[0] === "self" ? `, ${gender}` : ""
                }`
              : members.join(", ")}
            ...
          </p>
          <button
            css={`
              font-size: 0.79em;
              background-color: #ddd;
              border-radius: 2em;
              padding: 0 0.6em;
              margin: 0.6em;
            `}
            onClick={() => setShowYourDetails(true)}
          >
            View More <i className="fa fa-angle-right" />
          </button>
        </div>
      </div>
    </>
  );
}

function RecommendationResult() {
  const { groupCode } = useParams();
  const urlQueries = useUrlQuery();
  const enquiryId = urlQueries.get("enquiryId");

  const [recommendDetails, setRecommendDetails] = useState(null);

  const handleRecommendDetails = recommendedQuote => {
    setRecommendDetails(recommendedQuote);
  };

  const history = useHistory();

  const name = useSelector(state => state.greetingPage.proposerDetails.name);
  const firstName = name ? name.split(" ")[0] : "";

  const [loading, setLoading] = useState(false);

  return (
    <div
      css={`
        min-height: 100vh;
        width: 100vw;
        padding: 0.1px;
        padding-bottom: 60px;
        overflow-x: hidden;
        background: url(${BackgroundPng});
      `}
    >
      <MobileHeader>
        <MobileHeaderText
        // onClick={() => {
        //   history.goBack();
        // }}
        >
          <Link
            to={`/recommend/${groupCode}?enquiryId=${enquiryId}`}
            style={{ color: "#fff" }}
          >
            <i className="icon flaticon-back"></i>Recommend
          </Link>
        </MobileHeaderText>
      </MobileHeader>
      <div
        css={`
          display: none;
          ${mq.mobile} {
            display: block;
          }
        `}
      >
        <YourDetails />
      </div>
      <div
        css={`
          max-width: 1200px;
          margin: auto;
          margin-top: 3rem;

          ${mq.mobile} {
            display: none;
          }
        `}
      >
        <Link
          to={`/recommend/${groupCode}?enquiryId=${enquiryId}`}
          css={`
            color: var(--abc-red);
          `}
        >
          <i
            className="flaticon-back"
            css={`
              &::before {
                margin-right: 10px;
              }
            `}
          />
          Restart
        </Link>
      </div>
      <main
        css={`
          max-width: 900px;
          margin: auto;

          ${mq.mobile} {
            margin: 1rem auto;
          }
        `}
      >
        {!loading && (
          <div
            css={`
              display: flex;
              justify-content: space-between;
              align-items: start;

              ${mq.mobile} {
                padding: 0 0.6em;
              }
            `}
          >
            <div
              css={`
                /* text-align: center; */
                position: relative;
                padding-bottom: 1rem;

                &::after {
                  content: "";
                  background-color: #f5bc00;
                  width: 61px;
                  height: 8px;
                  border-radius: 6px;
                  position: absolute;
                  top: 100%;
                  /* left: 50%; */
                  left: 0;
                  /* transform: translate(-50%); */
                }

                ${mq.small} {
                  padding-bottom: 10px;
                }
              `}
            >
              <h1
                css={`
                  width: 100%;
                  font-size: 44px;
                  font-weight: bold;

                  ${mq.small} {
                    font-size: 33px;
                  }
                `}
              >
                Your Recommended Plans
              </h1>
              <p
                css={`
                  font-size: 15px;
                  color: #adadad;
                  font-weight: 100;
                  margin-top: 6px;

                  ${mq.small} {
                    margin-top: 3px;
                    /* font-size: 12px; */
                    font-weight: 900;
                    line-height: 1.6;
                    color: #999;
                  }
                `}
              >
                Hey
                <span
                  css={`
                    text-transform: capitalize;
                    margin-left: 0.3em;
                    display: inline-block;
                  `}
                >
                  {firstName}
                </span>
                , here are your recommended plans based on your preferred
                options
              </p>
            </div>
            <div
              css={`
                ${mq.mobile} {
                  display: none;
                }
              `}
            >
              <YourDetails />
            </div>
          </div>
        )}
        <RecommendedQuotes
          handleRecommendDetails={handleRecommendDetails}
          recommendDetails={recommendDetails}
          setIsLoading={setLoading}
        />
      </main>
    </div>
  );
}

function hospitalSendData(selectedHospitals = []) {
  let sendHospitals = {};
  for (let hospital of selectedHospitals) {
    sendHospitals[hospital.fyntune_code] = hospital.hospital_name;
  }
  /*temp*/
  // if(Object.keys(sendHospitals).length === 0) {
  //   sendHospitals["421301"] = "Shree Devi Hospital"
  // }
  return sendHospitals;
}

function RecommendedQuotes({
  handleRecommendDetails,
  recommendDetails,
  setIsLoading,
}) {
  const dispatch = useDispatch();
  const { groupCode } = useParams();
  const recommendedQuotes = useSelector(selectRecommenedQuotes(groupCode));
  // const recommendedQuotes = [...testQuotes];

  const answers = useSelector(selectAnswers(groupCode));
  const selectedHospitals = useSelector(selectSelectedHospitals(groupCode));

  const [loading, setLoading] = useState(false);
  const [analysing, setAnalying] = useState(true);

  const { addProduct } = useCartProduct(groupCode);

  const afterFetch = res => {
    setAnalying(false);
    setIsLoading(false);
    if (res.data) {
      dispatch(
        setRecommendedQuotes({ groupCode, recommendedQuotes: res.data.data }),
      );
    } else {
      dispatch(setRecommendedQuotes({ groupCode, recommendedQuotes: [] }));
    }
  };

  const urlQuery = useUrlQuery();

  const source = urlQuery.get("source");

  useEffect(() => {
    if (!Object.keys(answers).length && recommendedQuotes.length === 0) {
      setIsLoading(true);
      setLoading("refresh");
      getRecommendedQuotes({ groupCode }).then(res => {
        setLoading(false);
        afterFetch(res);
      });
      return;
    }
    if (source === "cache") return;
    setIsLoading(true);
    setLoading("analyse");
    getRecommendedQuotesApi({
      groupCode,
      answers: {
        ...answers,
        network_hospital: hospitalSendData(selectedHospitals),
      },
    }).then(afterFetch);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const [showCart, setShowCart] = useState(false);

  const handleBuyNow = recommendedQuote => {
    setShowCart(true);
    const {
      total_premium,
      premium,
      sum_insured,
      tax_amount,
      tenure,
      product: { id: product_id, name },
    } = recommendedQuote;
    addProduct({
      total_premium,
      sum_insured,
      tax_amount,
      tenure,
      product_id,
      premium,
      group_id: parseInt(groupCode),
      service_tax: tax_amount,
      product: { id: product_id, name },
    });
  };

  return (
    <div
      css={`
        margin-top: 3rem;

        ${mq.mobile} {
          padding: 0 15px;
        }
      `}
    >
      <BuyNowModal showBuyNow={showCart} setShowBuyNow={setShowCart} />
      {loading === "analyse" ? (
        <div
          css={`
            position: absolute;
            top: 0;
            left: 0;
            width: 100vw;
            z-index: 99;
          `}
        >
          <AnalysingScreen
            loaded={!analysing}
            afterLoading={() => setLoading(false)}
          />
        </div>
      ) : loading === "refresh" ? (
        <CardSkeletonLoader noOfCards={2} />
      ) : recommendedQuotes.length === 0 ? (
        <p>No quotes found!</p>
      ) : (
        <Accordion>
          {recommendedQuotes.map(recommendedQuote => (
            <RecommendedQuoteCard
              recommendedQuote={recommendedQuote}
              recommendDetails={recommendDetails}
              handleBuyNow={handleBuyNow}
              handleRecommendDetails={handleRecommendDetails}
            />
          ))}
        </Accordion>
      )}
    </div>
  );
}

export default RecommendationResult;

// function RecommendDetails({ recommendedQuote }) {
//   return (
//     <SeoDiv>
//       <Container>
//         <h2 className="h2_why_w_r_m">Why do we recommend?</h2>
//         <p className="p_why_w_r_m">Fundamental Checks:</p>
//         <Row>
//           <FundamentalCheck delayAnimation={0} />
//           <FundamentalCheck delayAnimation={300} />
//           <FundamentalCheck delayAnimation={500} />
//         </Row>
//       </Container>
//     </SeoDiv>
//   );
// }

// const SeoDiv = styled.div`
//   background: url(${bg2}) no-repeat center top;
//   background-size: cover;
//   padding: 10px 0 60px;
//   position: relative;
// `;
