import React, { useState, useRef, useEffect } from "react";
import useOutsiteClick from "../../../../../customHooks/useOutsiteClick";
import { v4 as uuidv4 } from "uuid";
import {
  Header,
  HeaderTitle,
  List,
  ListItem,
  Wrapper,
} from "../../../../../components/Common/DropDownComponents/DropDownComponents";
import styled from "styled-components/macro";

const GreetingFormDropdown = ({
  disabled,
  title,
  list,
  handleChange,
  type,
  selected,
  redBorder,
  code,
  ...props
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const [searchText, setSearchText] = useState("");
  const [ageList, setAgeList] = useState(list);

  const dropdownRef = useRef(null);
  useOutsiteClick(isOpen && dropdownRef, () => setIsOpen(false));

  useEffect(() => {
    const array = list.filter(data =>
      data?.title?.toLowerCase().includes(searchText?.toLowerCase()),
    );
    setAgeList(array);
  }, [searchText]);

  const toggleList = () => {
    setIsOpen(!isOpen);
  };
  const handleSelect = value => {
    handleChange(code, value, type);

    setIsOpen(!isOpen);
  };

  return (
    <label htmlFor={disabled && code} {...props}>
      <Wrapper ref={dropdownRef} className="GreetingDD__Wrapper">
        {isOpen && (
          <InputField
            style={{ position: "absolute" }}
            value={searchText}
            placeholder={"type here..."}
            onChange={e => {
              const value = e.target.value.replace(/\D/g, "");
              console.log(list[list.length - 1]);
              if (value <= list[list.length - 1].id) {
                setSearchText(value);
              }
            }}
          />
        )}
        <Header
          onClick={toggleList}
          css={`
            border: ${redBorder && "1px solid red !important"};
          `}
          className={`${isOpen && "active"} GreetingDD__Header ${
            disabled && "disabled"
          }`}
        >
          <HeaderTitle
            className={`${isOpen && "active"} GreetingDD__Title ${
              disabled && "font-gray"
            }`}
          >
            {selected}
          </HeaderTitle>
        </Header>
        {isOpen && (
          <List
            style={{
              visibility: "visible",
              width: "174.562px",
              top: "44px",
              left: "0px",
            }}
            css={`
              @media (max-width: 767px) {
                left: -2px !important;
                width: 130px !important;
              }
            `}
            className="GreetingDD__List"
          >
            {ageList.map(data => {
              return (
                <ListItem
                  className={`GreetingDD__ListItem`}
                  onClick={() => handleSelect(data.title)}
                  key={uuidv4()}
                >
                  {data.title}
                </ListItem>
              );
            })}
          </List>
        )}
      </Wrapper>
    </label>
  );
};

export default GreetingFormDropdown;

const InputField = styled.input`
  poistion: absolute !important;
  top: -8px !important;
  left: -7px !important;
  z-index: 99;
  border: none !important;
  // box-shadow: unset important;
  margin: 13px 16px 20px !important;
  padding: 4px 8px !important;
  width: 58% !important;
  font-size: 17px !important;
  font-weight: 400 !important;

  color: #000 !important;
  &:focus::placeholder {
    opacity: 1 !important;
  }
`;
