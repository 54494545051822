import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { v4 as uuidv4 } from "uuid";
import { yupResolver } from "@hookform/resolvers";
import { useForm } from "react-hook-form";
import { Row, Col, InputGroup, Alert } from "react-bootstrap";
import { saveForm1UserDetails } from "../../reducer/greetingPage.slice";
import { firstFormSchema } from "../../../../utils/validationSchema";
import BackIcon from "../../components/BackIcon/BackIcon";
import checkmark from "../../../../assets/images/checkmark.png";
import { noSpclChars, numOnly } from "../../../../utils/helper";

import {
  Label,
  SubLabel,
} from "../../components/FormComponents/FormComponents";
import {
  RadioLabel,
  RadioInput,
  RadioGroup,
} from "../../components/Radio/Radio";
import StyledButton from "../../components/Button/StyledButton";
import {
  TextInput,
  InputLabel,
  ErrorMessage,
} from "../../components/TextInput/GreetingPage/GreetingTextInput";
import { fieldSet1Data } from "../fieldsets/data/fieldSet1Data/data";
import "styled-components/macro";

const Fieldset1 = ({ currentForm, handleChange, value }) => {
  const { frontendData } = useSelector(state => state.frontendBoot);
  const { proposerDetails } = useSelector(state => state.greetingPage);

  const forbiddedSymbols = [
    "#",
    "!",
    "%",
    "$",
    "&",
    "=",
    "^",
    "*",
    "(",
    ")",
    "{",
    "}",
    "?",
    "|",
    "~",
    "`",
    "'",
    "/",
    "]",
    "[",
    "\\",
    ";",
    ":",
    ",",
    ">",
    "<",
  ];
  const forbiddedSymbols2 = [...forbiddedSymbols, "@", '"', ">", "<", "/"];
  const { data } = frontendData || [""];
  const { genders } = data || [""];

  const [selectedGender, setSelectedGender] = useState(
    proposerDetails.gender || "",
  );
  const [fullName, setFullName] = useState(proposerDetails.name || "");
  const [email, setEmail] = useState(proposerDetails.email || "");
  const [mobile, setMobile] = useState(proposerDetails.mobile || "");
  // const [customErrors, setCustomErrors] = useState(false);

  const [showmore, setShowmore] = useState(false);
  const dispatch = useDispatch();
  const { register, handleSubmit, errors } = useForm({
    resolver: yupResolver(firstFormSchema),
    mode: "onBlur",
  });

  const checkPreviousChar = (value, checkValue) => {
    let check = true;

    if (value[0] === checkValue) {
      check = false;
    }
    if (
      check &&
      value[value.length - 1] === checkValue &&
      fullName[fullName.length - 1] === checkValue
    ) {
      check = false;
    }
    return check;
  };

  const checkAllChar = (value, checkValue) => {
    let check = true;
    let valueArr = value.split("");
    let atTheRateNo = 0;
    let dotCount = 0;
    let doubleInvertedCount = 0;

    valueArr.map(i => {
      return i === "@"
        ? (atTheRateNo += 1)
        : i === "."
        ? (dotCount += 1)
        : i === '"'
        ? (doubleInvertedCount += 1)
        : doubleInvertedCount + 0;
    });

    //  let checkDotContinuity = ;
    if (dotCount > 1) {
      if (
        value[valueArr.lastIndexOf(".") - 1] ===
          value[valueArr.lastIndexOf(".")] ||
        value[valueArr.lastIndexOf(".") + 1] ===
          value[valueArr.lastIndexOf(".")]
      ) {
        return false;
      }
    }

    if (doubleInvertedCount > 0) {
      if (
        value[valueArr.lastIndexOf('"') + 1] ===
          value[valueArr.lastIndexOf('"')] ||
        value[valueArr.lastIndexOf('"') - 1] ===
          value[valueArr.lastIndexOf('"')]
      ) {
        return false;
      }
    }

    if (atTheRateNo > 0) {
      if (
        value[valueArr.lastIndexOf(".") - 1] === "@" ||
        value[valueArr.lastIndexOf(".") + 1] === "@"
      )
        return false;
    }

    check =
      value[0] !== "." &&
      value[0] !== "#" &&
      value[0] !== "@" &&
      atTheRateNo < 2;
    // value.indexOf(".")>0?
    // value[value.indexOf(".") + 1] !== value[value.indexOf(".")]?
    // value[value.indexOf(".") - 1] !== value[value.indexOf(".")]?true:false

    for (let i in value) {
      if (checkValue.includes(value[i])) {
        check = false;
      }
    }
    return check;
  };

  const checkDoubleChar = e => {
    if (e.keyCode === 190 && fullName[fullName.length - 1] === " ") {
      e.preventDefault();
    }
    if (e.keyCode === 32 && fullName.length < 1) {
      e.preventDefault();
    }
  };

  const onSubmit = data => {
    dispatch(
      saveForm1UserDetails(
        {
          fullName: data.fullName.trim(),
          mobile: data.mobile,
          email: data.email,
          gender: data.gender,
        },
        handleChange,
      ),
    );
  };

  return (
    <fieldset style={{ display: currentForm === value ? "block" : "none" }}>
      <div
        style={{
          visibility: "hidden",
        }}
      >
        <BackIcon onClick={e => e.preventDefault()} />
      </div>
      <Label>
        Tell us about your
        <a
          onClick={() => {
            setFullName("test test");
            setMobile("9111111111");
            setEmail("test@gmail.com");
            setSelectedGender("M");
          }}
        >
          s
        </a>
        elf?
      </Label>
      <form autoComplete="off" noValidate onSubmit={handleSubmit(onSubmit)}>
        <Row>
          {fieldSet1Data.map(
            ({ type, name, label, placeHolder, maxLength }) => (
              <Col xs={12} md={name === "email" ? 12 : 6} key={name}>
                <InputGroup
                  style={{
                    marginBottom: "13px",
                    display: "block !important",
                    flexDirection: "column",
                  }}
                  className="inputGroup1"
                >
                  <TextInput
                    onPaste={e => {
                      e.preventDefault();
                      return false;
                    }}
                    onCopy={e => {
                      e.preventDefault();
                      return false;
                    }}
                    name={name}
                    type={type}
                    className="input_place_css_r"
                    style={{
                      textTransform: name === "fullName" && "capitalize",
                    }}
                    placeholder={placeHolder}
                    value={
                      name === "fullName"
                        ? fullName
                        : name === "email"
                        ? email
                        : name === "mobile"
                        ? mobile
                        : undefined
                    }
                    onKeyDown={
                      name === "mobile"
                        ? numOnly
                        : name === "fullName" && checkDoubleChar
                    }
                    onBlur={e => {
                      name === "fullName" && setFullName(e.target.value.trim());
                      name === "email" && setEmail(e.target.value);
                    }}
                    onChange={e => {
                      name === "fullName" &&
                        checkPreviousChar(e.target.value, " ") &&
                        checkPreviousChar(e.target.value, ".") &&
                        //    checkDoubleChar(e, ".", " ") &&
                        checkAllChar(e.target.value, forbiddedSymbols2) &&
                        setFullName(e.target.value);
                      name === "email" &&
                        checkAllChar(e.target.value, forbiddedSymbols) &&
                        setEmail(e.target.value);

                      name === "mobile" && setMobile(e.target.value);
                    }}
                    maxLength={maxLength}
                    ref={register}
                    redBorder={errors[name] ? true : undefined}
                  />
                  <InputLabel className="input_i_css">{label}</InputLabel>
                  {errors[name] && (
                    <ErrorMessage
                      css={`
                        margin-top: -20px;
                        top: 6px;
                        z-index: 9;
                        font-size: 15px;
                        left: 4px;
                        @media (max-width: 767px) {
                          margin-top: -12px;
                          top: 3px;
                          left: 4px;
                        }
                      `}
                    >
                      {errors[name].message}
                    </ErrorMessage>
                  )}
                </InputGroup>
              </Col>
            ),
          )}
        </Row>
        {/* <SubLabel className="hideOnMobile">Select Your Gender</SubLabel> */}
        <RadioGroup>
          {genders &&
            genders.map(({ code, display_name }) => {
              const uid = uuidv4();
              return (
                <div
                  key={display_name}
                  className="radioContainer"
                  style={{ position: "relative" }}
                >
                  <img
                    src={checkmark}
                    style={{
                      display: selectedGender === code ? "block" : "none",
                      position: "absolute",
                      height: "55px",
                      left: "137px",
                    }}
                    css={`
                      top: -2px;
                      @media (max-width: 767px) {
                        top: -2px;
                      }
                    `}
                  />
                  <RadioInput
                    type={"radio"}
                    name={"gender"}
                    id={uid}
                    value={code}
                    checked={selectedGender === code || false}
                    onChange={e => setSelectedGender(code)}
                    ref={register}
                  />
                  <RadioLabel
                    css={`
                      border: ${errors["gender"]
                        ? "2px solid red !important"
                        : "2px solid #e1e2ea"};
                    `}
                    htmlFor={uid}
                  >
                    {display_name}
                  </RadioLabel>
                </div>
              );
            })}
        </RadioGroup>
        {errors["gender"] && (
          <ErrorMessage
            css={`
              top: -17px;
              left: 4px;
              margin-bottom: -16px;
              font-size: 15px;
              @media (max-width: 767px) {
                top: -13px;
              }
            `}
          >
            {"Gender is required"}
          </ErrorMessage>
        )}
        {/* {Object.keys(errors)[0] && (
          <Alert variant="danger" style={{ fontSize: "15px" }}>
            {errors[Object.keys(errors)[0]].message}
          </Alert>
        )} */}

        <StyledButton
          type="submit"
          value={"Get Started"}
          laptopLg
          style={{ marginBottom: "20px" }}
        />
        {/*=================================================================================================================================================================== */}
        <div>
          <label
            style={{ fontSize: "13px", color: "black", fontWeight: "400" }}
          >
            <i class="termchk"></i>By clicking on Get Started, I hereby
            authorise Aditya Birla Insurance Brokers Limited. and all of its
            affiliates, subsidiaries, group companies and related parties to
            access the details such as my name, address, telephone number,{" "}
            <span style={{ display: showmore ? "inline" : "none" }}>
              e-mail address, birth date and / or anniversary date shared by me,
              and contact me to provide information on the various products and
              services offered. I understand that this consent will override my
              NDNC registration, if any. I also understand that at any point of
              time, I wish to stop receiving such communications from ABIBL, I
              can withdraw such consent anytime on{" "}
              <a
                href="https://cpuat.adityabirlainsurancebrokers.com/"
                target="_blank"
                style={{ color: "black !important" }}
              >
                cpuat.adityabirlainsurancebrokers.com
              </a>{" "}
              (to provide a contact number or email id or both){" "}
            </span>
            <a
              style={{ color: "red", fontSize: "15px" }}
              onClick={() => {
                setShowmore(!showmore);
              }}
            >
              {showmore ? "...show less" : "...show more"}
            </a>
          </label>
        </div>
      </form>
    </fieldset>
  );
};

export default Fieldset1;
