import React from "react";
import { Col } from "react-bootstrap";
import styled from "styled-components";
import covidImg from "../../../assets/images/covid.png";
import { plansArray } from "./PlansData/PlansData";

const GreetingPlanCard = () => {
  return (
    <Col md={4} className="greeting__plan-wrapper hideOnMobile">
      <ParagraphWrapper>
        <Paragraph>
          Protect your Family's <br /> Health with us
        </Paragraph>
        <Hr />
      </ParagraphWrapper>
      <TextWrapper>
        <ul>
          {plansArray.map((plans, index) => {
            return <li key={index}>{plans}</li>;
          })}
        </ul>
      </TextWrapper>
      <CovidPlans>
        <img src={covidImg} />
        <CovidHeader>Covid Plans</CovidHeader>
        <CovidSubHeader>
          All health plans cover covid-19 treatment
        </CovidSubHeader>
      </CovidPlans>
    </Col>
  );
};

export default GreetingPlanCard;

const ParagraphWrapper = styled.div``;

const Hr = styled.hr`
  display: block;
  height: 11px;

  width: 70px;
  margin-left: 9px;

  background-color: #fecc28;
  border-radius: 50px;

  border: 0;
  position: relative;
  top: -16px;
`;
const Paragraph = styled.p`
  margin: 14px 0px 0 10px;
  color: #000;
  font-size: 42px;
  line-height: 50px;
  margin-bottom: 25px;
  font-weight: 900;

`;

const TextWrapper = styled.div`
	margin-top: 20px;
  margin-bottom: 40px;
  & ul{
  
   li {
    font-size: 18px;
		line-height: 38px;
    color: #000;
    position: relative;
    padding-left: 35px;
    margin: 0 12px;
    font-weight: 400 !important;

    }
    li:before {
      content: "\f107";
      font-family: "Flaticon";
      position: absolute;
      line-height: 30px;
      font-size: 15px;
      left: 0;
      top: 6px;
      color: #3a9560;
    }
    li:hover {
      // font-size: 21px;
      // line-height: 42px;
      // color: #000;
      // position: relative;
      // padding-left: 35px;
      // padding: 3px 13px 0 33px;
      // background: #de9b9e29;
      // border-radius: 8px;
    }
   }
  }
`;

const CovidPlans = styled.div`
  background: #fff;
  border-radius: 8px;
  box-shadow: 0 20px 30px -19px #00000038;
  margin: -9px 11px;
  padding: 12px;
  & img {
    float: left;
    margin: -10px 9px 0 0;
  }
  & p {
    margin: 0;
    color: black;
    line-height: 28px;
  }
`;

const CovidHeader = styled.p`
  font-size: 19px;
  font-weight: 900;

`;

const CovidSubHeader = styled.p`
  font-size: 15px;
  font-weight: 400;
`;
