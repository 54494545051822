import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import iclogo from "../../../../assets/images/Care_Health.png";
import speedometer from "../../../../assets/images/speedometer.png";
import styled from "styled-components";

const RecommendedCard = () => {
  return (
    <Col
      lg={12}
      sm={12}
      className="single-block recommend_box_shadow aos-init aos-animate"
      data-aos="fade-up"
    >
      <Row>
        <Col lg={1}>
          <img src={iclogo} className="img_care_health_m_top" />
        </Col>
        <Col lg={4}>
          <p class="p_care_name_re">Care Advantage</p>
        </Col>
        <Col lg={3} className="text-black text-black margin_p_right_cover">
          <p class="p_cover_p_s">Cover</p>
          <p class="p_cover_p_s">
            <i class="fa fa-inr"></i> 5.5L
          </p>
        </Col>
        <Col xs={3} lg={3} className="text-black cover_premium_re_more">
          <p class="p_premium_p_s">Premium</p>
          <p class="p_premium_p_s">
            <i class="fa fa-inr"></i> 15,225 / per year
          </p>
        </Col>
        <Col xs={3} lg={1}>
          <button className="btn btn_recommend_more">Buy Now</button>
        </Col>
      </Row>
      <Hr />
      <Row>
        <Col lg={1}>
          <img src={speedometer} />
        </Col>
        <Col lg={2}>
          <p className="margin_top_align_re_rate">8.5/10</p>
        </Col>
        <Col lg={2}>
          <p className="show_details_re_m">
            Show Details <i className="fa fa-angle-right"></i>
          </p>
        </Col>
      </Row>
    </Col>
  );
};

export default RecommendedCard;

const Hr = styled.hr`
  margin-top: 1rem;
  margin-bottom: 1rem;
  border: 0;
  border-top: 1px solid #ddd;
`;
