import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { Col, Row, ProgressBar } from "react-bootstrap";
import styled from "styled-components/macro";
import Fieldset1 from "./fieldsets/Fieldset1";
import Fieldset2 from "./fieldsets/Fieldset2";
import Fieldset3 from "./fieldsets/Fieldset3";
import Fieldset4 from "./fieldsets/Fieldset4";
import Fieldset5 from "./fieldsets/Fieldset5";
import "./GreetingFormCard.scss";
import SecureLS from "secure-ls";
import PlanCard from "../components/PlanCard/PlanCard";
import { plansArray } from "../GreetingPlanCard/PlansData/PlansData";

const GreetingFormCard = () => {
  const ls = new SecureLS();
  const [isIndividualPlan, setIsIndividualPlan] = useState(false);
  const [currentForm, setCurrentForm] = useState("form1");

  const greetingPage = useSelector(state => state.greetingPage);
  const {
    proposerDetails: { member, plan_type },
  } = greetingPage;

  useEffect(() => {
    if (plan_type && plan_type === "I") {
      setIsIndividualPlan(true);
    } else {
      setIsIndividualPlan(false);
    }
  }, [plan_type]);

  const handleChange = value => {
    // if (value === "redirect") {
    //   //go to quotes page
      
    //   history.replace({
    //     pathname: "/quotes",
    //     search: `enquiryId=${ls.get("enquiryId")}`,
    //   });
    // } else {
    //   setCurrentForm(value);
    // }
    setCurrentForm(value);
  };
  // to print the checklist which keep track of which form has been displayed
  const CheckList = () => {
    var row = [
      <li key={-1} className="active">
        &nbsp;
      </li>,
    ];
    const stepsLength = isIndividualPlan ? 4 : 5;
    for (var i = 1; i < stepsLength; i++) {
      row.push(
        <li key={i} className={`${currentForm.split("m")[1] > i && "active"} `}>
          &nbsp;
        </li>,
      );
    }
    return row;
  };

  const displayFieldset = () => {
    return (
      <>
        {" "}
        <Fieldset1
          currentForm={currentForm}
          handleChange={handleChange}
          value={"form1"}
        />
        <Fieldset2
          currentForm={currentForm}
          handleChange={handleChange}
          value={"form2"}
        />
        <Fieldset3
          currentForm={currentForm}
          handleChange={handleChange}
          value={"form3"}
        />
        <Fieldset4
          currentForm={currentForm}
          handleChange={handleChange}
          value={"form4"}
        />
        <Fieldset5
          currentForm={currentForm}
          handleChange={handleChange}
          value={"form5"}
          isIndividualPlan={isIndividualPlan}
        />
      </>
    );
  };

  return (
    <Col
      md={8}
      css={`
        padding-bottom: 100px;
        @media (max-width: 767px) {
          padding-bottom: unset;
        }
      `}
      className="greeting__form-wrapper"
    >
      {/* ==================================Mobile Form======================================== */}

      {/* ========================================================================== */}
      <Form className="mobileWidth">
        <div className="bb_i_t" className="showOnMobile">
          <p className="p_input_title">
            Buy health insurance plan in{" "}
            <span className="font_bold font_30 color_five">Few</span> simple
            steps
            <span
              className="showOnMobile"
              css={`
                position: relative;
                width: 100%;
                margin-top: 12px;
                // display: flex;

                justify-content: center;
                @media (max-width: 767px) {
                  .progress {
                    width: 52% !important;
                    border-radius: 27px !important;
                    margin: unset !important;
                    height: 8px !important;
                  }
                }
              `}
            >
              <ProgressBar now={`${currentForm.split("m")[1] * 2}0`} />
            </span>
          </p>
        </div>

        <Row className="hideOnMobile">
          <Title>
            Buy Health Insurance plan in{" "}
            <span className="font_bold font_30 color_five">Few</span> simple
            steps
          </Title>
        </Row>

        {/* <ul id="progressbar" className="hideOnMobile">
          {CheckList()}
        </ul> */}
        <ul id="progressbar" className="hideOnMobile">
          <ProgressBar now={`${currentForm.split("m")[1] * 2}0`} />
        </ul>
        {displayFieldset()}
        <div
          className="hideOnDesktop"
          css={`
            margin: -15px;
            padding: 15px;
            background-image: linear-gradient(to right, #fbf8f3, #fbf8f3);
            padding-bottom: 60px;
          `}
        >
          <p
            css={`
              display: none;
              @media (max-width: 1023px) {
                position: relative;
                display: block;
                color: #000;
                font-size: 20px;
                line-height: 26px;
                width: 100%;
                margin-bottom: 22px;
              }
            `}
          >
            Protect your Family's Health with us
            <div
              css={`
                border-bottom: 7px solid #fecc28;
                border-radius: 21px;
                position: absolute;
                bottom: -12px;
                left: 0%;
                right: 79%;
              `}
            ></div>
          </p>
          <TextWrapper className="hideOnDesktop">
            <ul>
              {plansArray.map((plans, index) => {
                return <li key={index}>{plans}</li>;
              })}
            </ul>
          </TextWrapper>

          <span className="hideOnDesktop">
            <PlanCard />
          </span>
        </div>
      </Form>
    </Col>
  );
};

export default GreetingFormCard;

const Form = styled.div`
  & .progress {
    width: 229px;
    border-radius: 27px;
    margin: unset;
    height: 12px;

    & .progress-bar {
      border-radius: 27px;
      // background-color: #fecc28 !important;
    }
  }

  width: 90%;
  margin: 16px auto;
  position: sticky;
  z-index: 1111;
  text-align: left;
  font-size: 25px;
  color: gray;

  & fieldset {
    /* background: white; */
    border: 0 none;
    border-radius: 2px;
    /* box-shadow: 0 17px 41px -21px rgb(0 0 0); */
    padding: 0px 0px 20px 0px;
    /* border-top: 9px solid #fecc28; */
    box-sizing: border-box;
    width: 80%;
    margin: 0px 7%;
    position: relative;
    @media (max-width: 1140px) {
      width: 90%;
    }
    @media (max-width: 767px) {
      width: 100%;
      margin: unset;
    }
  }

  & input {
    outline: none;
    display: block;
    width: 100%;
    margin: 0 0 12px;
    padding: 17px 26px;
    border: 1px solid #d9d9d9;
    -webkit-border-radius: 3px;
    -moz-border-radius: 3px;
    border-radius: 3px;
    color: #837e7e;
    -webkti-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
    font-size: 20px;
    font-wieght: 100;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    -webkit-transition: all 0.3s linear 0s;
    -moz-transition: all 0.3s linear 0s;
    -ms-transition: all 0.3s linear 0s;
    -o-transition: all 0.3s linear 0s;
    transition: all 0.3s linear 0s;
    /* height: 80px; */
    border-radius: 12px;
    border: 1.2px solid #d6d9e0;
    /* box-shadow: 0 20px 30px -19px #004b8340; */
  }
  & select {
    outline: none;
    display: block;
    width: 94%;
    margin: 0 0 20px;
    padding: 10px 50px;
    border: 1px solid #d9d9d9;
    -webkit-border-radius: 3px;
    -moz-border-radius: 3px;
    border-radius: 3px;
    color: #837e7e;
    font-family: "Roboto";
    -webkti-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
    font-size: 14px;
    font-wieght: 400;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    -webkit-transition: all 0.3s linear 0s;
    -moz-transition: all 0.3s linear 0s;
    -ms-transition: all 0.3s linear 0s;
    -o-transition: all 0.3s linear 0s;
    transition: all 0.3s linear 0s;
    height: 80px;
    border-radius: 20px;
    border: 2px solid #f6d8da;
    box-shadow: 1px 6px 4px 1px #eaf0f4;
  }
`;

const Title = styled.p`
  margin: 0 4px 0 8%;
  color: #000;
  font-size: 28px;
  line-height: 28px;
  margin-bottom: 26px;
  font-weight: 900;
  background-image: linear-gradient(to right, #ffe7e7, #fff);
  padding: 10px;
  width: 68%;
  & span {
    color: #f5bc00;
    font-size: 32px;
    font-weight: 900;
  }
  @media (max-width: 1140px) {
    width: 98%;
  }
`;

const TextWrapper = styled.div`

display: none;
@media(max-width: 1023px){
  display: block;

  margin-top: 20px;
  margin-bottom: 40px;
  & ul{
    
   li {
    font-size: 15px;
    line-height: 38px;
    color: #000;
    position: relative;
    padding-left: 33px;
    margin: 0px -4px;
    font-weight: 400 !important;
    
    }
    li:before {
      content: "\f107";
      font-family: "Flaticon";
      position: absolute;
      line-height: 28px;
      font-size: 14px;
      left: 8px;
      top: 6px;
      color: #3a9560;
    }
    li:hover {
      // font-size: 21px;
      // line-height: 42px;
      // color: #000;
      // position: relative;
      // padding-left: 35px;
      // padding: 3px 13px 0 33px;
      // background: #de9b9e29;
      // border-radius: 8px;
    }
   }
  }
  `;

  // compare page edit members quote card ui recommended fitlers input defects