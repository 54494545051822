import React, { useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import bg from "../../assets/images/recommendedBG.png";
import bg2 from "../../assets/images/recommendedBG2.png";
import bg3 from "../../assets/images/recommendedBG3.png";

import styled from "styled-components";

import Loader from "../../components/Common/Loader/Loader";
import RecommendedCard from "./components/RecommendedCard/RecommendedCard";
import FundamentalCheck from "./components/FundamentalCheck/FundamentalCheck";
import RequirementCard from "./components/RequirementCard/RequirementCard";
import FeatureCard from "./components/FeatureCard/FeatureCard";
import "./Scss/recommendationMore.scss";
import MobileRecommendedCard from "./components/RecommendedCard/MobileRecommendedCard";

const RecommendedMore = () => {
  console.log("Recommendation Card rendering...");
  const [loader, setLoader] = useState(false);

  return (
    <span className="recommendation__more">
      <div className="img_bg_re_m" style={{ height: "400vh" }}>
        <img src={bg} />
      </div>
      <div className="">
        <section>
          {loader && <Loader />}
          {/* Loader........ */}
        </section>

        {/* =================================Mobile Head======================================== */}
        <MobileHeader>
          <div>
            <a href="recommend_mobile.html">
              {" "}
              <p class="text-white select_plan_options_p">
                <i class="back-icon_r flaticon-back"></i> Recommend
              </p>
            </a>
          </div>
        </MobileHeader>

        {/* ========================================================================= */}
        <div className="re_more seo-what-we-do margin_top_none ">
          <Container>
            <div className="theme-title-one title-underline text-center upper-bar">
              <p className="go_back_arrow_re_more">
                <i className="icon flaticon-back"></i> Go Back
              </p>
              <h2 className="main-title h2_recommend_title">
                Your Recommended Plans
              </h2>
              <p className="h2_recommend_title_p">
                Loreum Ipsum is simply dummy text of the printing and
                typesetting industry.
              </p>
            </div>
            {/* =================================Recommended Card======================================== */}
            <Row>
              <MobileRecommendedCard />
              <MobileRecommendedCard />
              <MobileRecommendedCard />
            </Row>

            {/* <Row>
							<RecommendedCard />
							<RecommendedCard />
							<RecommendedCard />
						</Row> */}

            {/* ========================================================================= */}
          </Container>
        </div>
        <SeoDiv>
          <Container>
            <h2 className="h2_why_w_r_m">Why do we recommend?</h2>
            <p className="p_why_w_r_m">Fundamental Checks:</p>
            <Row>
              <FundamentalCheck delayAnimation={0} />
              <FundamentalCheck delayAnimation={300} />
              <FundamentalCheck delayAnimation={500} />
            </Row>
          </Container>
        </SeoDiv>
        <div className="our-service-three pos-r pb-100 md-pb-100 md-pt-110">
          <div className="img_bg_re_m_matches">
            <img src={bg3} />
          </div>
          <Container>
            <h2 className="h2_why_w_r_m">Matches your requirement</h2>

            <Row>
              <RequirementCard />
              <RequirementCard />
              <RequirementCard />
              <RequirementCard />
              <RequirementCard />
              <RequirementCard />
            </Row>
          </Container>
        </div>
        <div className="our-service-three pos-r pb-150 md-pb-100 md-pt-110">
          <Container>
            <h2 className="h2_why_w_r_m">Important Feature</h2>
            <Row>
              <FeatureCard />
              <FeatureCard />
              <FeatureCard />
              <FeatureCard />
              <FeatureCard />
              <FeatureCard />
              <FeatureCard />
              <FeatureCard />
            </Row>
            <br />
            <Col lg={12} className="text-center">
              <p className="show_details_re_m_s">
                Show Plan Details<i class="fa fa-angle-right"></i>{" "}
              </p>
            </Col>
          </Container>
        </div>
      </div>
    </span>
  );
};

export default RecommendedMore;

const MobileHeader = styled.div`
  display: none;
  background: #c72229;
  height: 57px;
  padding: 1px 15px;
  @media (max-width: 1023px) {
    display: block;
  }
`;
const SeoDiv = styled.div`
  background: url(${bg2}) no-repeat center top;
  background-size: cover;
  padding: 10px 0 60px;
  position: relative;
`;
