export const fieldSet1Data = [
  {
    type: "text",
    name: "fullName",
    label: "Full Name",
    placeHolder: "Enter Full Name.",
    maxLength: "60",
  },
  {
    type: "tel",
    name: "mobile",
    label: "Mobile No.",
    placeHolder: "Enter mobile no.",
    maxLength: "10",
  },
  {
    type: "email",
    name: "email",
    label: "Email id",
    placeHolder: "Enter email id.",
    maxLength: "50",
  },
];

export const fieldSet1RadioInputData = [
  {
    type: "radio",
    name: "gender",
    label: "Male",
    value: "M",
    id: "male",
  },
  {
    type: "radio",
    name: "gender",
    label: "Female",
    value: "F",
    id: "female",
  },
];
