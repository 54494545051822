import React, { useState, useEffect, Fragment, useRef } from "react";
import { Col, Row } from "react-bootstrap";
import "./EligibilityCard.scss";
import Toggle from "../components/Toggle.jsx";
import DropDown from "../components/DropDown";
import EditEl from "../../../assets/images/edit_el.png";
const EligibilityCard = ({}) => {
  const [medical, setMedical] = useState(false);
  const [smoke, setSmoke] = useState(false);
  const members = ["self", "spouse", "Daughter", "Son"];
  const [membersStatus, setMembersStatus] = useState({});
  const [membersStatusSmoke, setMembersStatusSmoke] = useState("self");
  const [habits, setHabits] = useState({});
  const sliderRef = useRef();
  const editRef = useRef();
  const [index, setIndex] = useState(0);
  console.log(membersStatus, membersStatusSmoke, habits);
  useEffect(() => {
    if (membersStatusSmoke) {
      setIndex(members.indexOf(membersStatusSmoke));
    }
    if (sliderRef.current)
      sliderRef.current.style.left = `${50 + index * 164}px`;
    if (editRef.current) editRef.current.style.left = `${155 + index * 164}px`;
  }, [membersStatusSmoke, index]);

  useEffect(() => {
    if (!medical) {
      setMembersStatus(false);
    }
    if (!smoke) {
      setIndex(0);
      setMembersStatusSmoke("self");
    }
  }, [medical, smoke]);
  return (
    <>
      <div className="container">
        <div className="box_shadow_box_card_medical">
          <div className="row">
            <div className="col-lg-9 col-md-12">
              <p className="mb-10 p_propsal_form_r_q_m">
                Has the Insured Person suffered or is suffering from any
                disease/ailment/adverse medical condition of any kind especially
                Heart/Stroke/Cancer/Renal disorder/Alzheimer's
                disease/Parkinson's disease?
              </p>
            </div>
            <div className="col-lg-3 col-md-12 middle">
            {console.log(medical,"++++++++++++")}
              <Toggle
                name={"medical"}
                value={medical}
                error={"Select At least One"}
                updateChecked={setMedical}
              />
            </div>
          </div>

          {medical && (
            <>
              <div className="group">
                {members.map((item, index) => (
                  <Fragment key={index}>
                    <input
                      type="checkbox"
                      name={item}
                      id={"elgmedical" + index}
                      onChange={e => {
                        setMembersStatus({
                          ...membersStatus,
                          [e.target.name]: e.target.checked,
                        });
                      }}
                      checked={membersStatus[item]}
                    />
                    <label for={"elgmedical" + index}>{item}</label>
                  </Fragment>
                ))}
              </div>
            </>
          )}
        </div>
      </div>

      <hr class="hr_border_bottom_pro_f"></hr>
      <div className="container">
        <div className="box_shadow_box_card_medical">
          <div className="row">
            <div className="col-lg-9 col-md-12">
              <p className="mb-10 p_propsal_form_r_q_m">
                Have You smoked, consumed alcohol, or chewed tobacco, gutka,
                paan or used any recreational drugs?
              </p>
            </div>
            <div className="col-lg-3 col-md-12 middle">
              <Toggle
                name={"smoke"}
                value={smoke}
                error={"Select At least One"}
                updateChecked={setSmoke}
              />
            </div>
          </div>
        </div>

        <div className="tile">
          {smoke && (
            <>
              {smoke && (
                <>
                  <div className="group">
                    <div className="slider" ref={sliderRef}></div>

                    {members.map((item, index) => (
                      <Fragment key={index}>
                        <input
                          type="radio"
                          name={item}
                          id={"elgsmoke" + index}
                          onChange={e => {
                            setMembersStatusSmoke(e.target.name);
                          }}
                          checked={membersStatusSmoke === item}
                        />
                        <label for={"elgsmoke" + index}>{item}</label>
                      </Fragment>
                    ))}
                    <img src={EditEl} className="pencil-img" ref={editRef} />
                  </div>
                </>
              )}
              <div className="box_shadow_box_card_medical">
                <div className="row">
                  <div className="col-lg-4 col-md-12">
                    <div className="form-group dropdown_product_m_t_b_p">
                      <DropDown
                        key={membersStatusSmoke}
                        label={"Alcohol"}
                        options={{
                          "14+": ">14 units/ weeks",
                          "14-": "<14 units/ weeks",
                        }}
                        value={habits[membersStatusSmoke]?.alcohol}
                        onChange={e =>
                          setHabits({
                            ...habits,
                            [membersStatusSmoke]: {
                              ...(habits[membersStatusSmoke]
                                ? habits[membersStatusSmoke]
                                : {}),
                              alcohol: e.target.value,
                            },
                          })
                        }
                      ></DropDown>
                    </div>
                  </div>
                  <div className="col-lg-4 col-md-12">
                    <div className="form-group dropdown_product_m_t_b_p">
                      <DropDown
                        key={membersStatusSmoke}
                        label={"Smoke"}
                        options={{
                          "20+": ">20 ciggarettes/day",
                          "20-": "<20 ciggarettes/day",
                        }}
                        value={habits[membersStatusSmoke]?.smoke}
                        onChange={e =>
                          setHabits({
                            ...habits,
                            [membersStatusSmoke]: {
                              ...(habits[membersStatusSmoke]
                                ? habits[membersStatusSmoke]
                                : {}),
                              smoke: e.target.value,
                            },
                          })
                        }
                      ></DropDown>
                    </div>
                  </div>
                  <div className="col-lg-4 col-md-12">
                    <div className="form-group dropdown_product_m_t_b_p">
                      <DropDown
                        key={membersStatusSmoke}
                        label={"Tobaco"}
                        options={{
                          "20+": ">20 ciggarettes/day",
                          "20-": "<20 ciggarettes/day",
                        }}
                        value={habits[membersStatusSmoke]?.tobacco}
                        onChange={e =>
                          setHabits({
                            ...habits,
                            [membersStatusSmoke]: {
                              ...(habits[membersStatusSmoke]
                                ? habits[membersStatusSmoke]
                                : {}),
                              tobacco: e.target.value,
                            },
                          })
                        }
                      ></DropDown>
                    </div>
                  </div>
                </div>
              </div>
            </>
          )}
        </div>
      </div>
    </>
  );
};

export default EligibilityCard;
