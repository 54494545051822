import styled from "styled-components";

const RadioInput = styled.input`
  &:checked,
  &:not(:checked) {
    position: absolute;
    left: -9999px;
    width: 0;
    height: 0;
    visibility: hidden;
  }

  &:not(:disabled) ~ label {
    cursor: pointer;
  }
`;
const RadioLabel = styled.label`
  font-weight: 900;
`;

export { RadioInput, RadioLabel };
