import React from "react";
import styled from "styled-components/macro";

const RadioButton = ({ value, checked, onChange, id, ...props }) => {
  return (
    <div {...props}>
      <StyledRadioButton
        type="radio"
        id={id}
        value={value}
        checked={checked === value ? true : false}
        onClick={onChange}
        // css={`
        //   &:checked {
        //     & + .label_r.radio_re {
        //       background-color: #fff1f1;
        //     }
        //   }
        // `}
      />
      <RadioLabel
        htmlFor={id}
        className="label_r radio_re"
        css={`
          & .h2_g_r {
            background: transparent !important;
          }
        `}
      >
        <h2 className="h2_g_r" style={{ color: "#000" }}>
          {value}
        </h2>
      </RadioLabel>
    </div>
  );
};

export default RadioButton;

const RadioLabel = styled.label`
  padding: 10px;
  box-shadow: 0px 3px 10px -2px rgb(161 170 166 / 50%);
  background-color: #fff;
  @media (max-width: 767px) {
    margin-right: 10px;
  }
`;

const StyledRadioButton = styled.input``;
