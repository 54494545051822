import { combineReducers } from "redux";
import {
  greetingPageSlice,
  quotePageSlice,
  frontendBootSlice,
  proposalPageSlice,
  schemaSlice,
  productPageSlice,
  comparePageSlice,
  seeDetailsSlice,
  cartSlice,
} from "../modules";
import proposerSlice from "../modules/Proposer/proposer.slice";
import recommendedPageSlice from "../modules/RecommendedPage/recommendedPage.slice";
import FormBuilderSlice from "./../components/FormBuilder/FormBuilder.slice";
export default combineReducers({
  frontendBoot: frontendBootSlice,
  proposer: proposerSlice,
  greetingPage: greetingPageSlice,
  seeDetails: seeDetailsSlice,
  quotePage: quotePageSlice,
  recommendedPage: recommendedPageSlice,
  comparePage: comparePageSlice,
  cart: cartSlice,
  productPage: productPageSlice,
  proposalPage: proposalPageSlice,
  schema: schemaSlice,
  formBuilder: FormBuilderSlice,
});
