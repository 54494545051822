import {useEffect} from "react";
import { useDispatch, useSelector } from "react-redux";
import { Container, Row } from "react-bootstrap";

import GreetingFormCard from "./GreetingFormCard/GreetingFormCard";
import GreetingPlanCard from "./GreetingPlanCard/GreetingPlanCard";
import "./Scss/GreetingPage.scss";
import Loader from "../../components/Common/Loader/Loader";
import { setShouldFetchQuotes } from "../QuotesPage/quotePage.slice";

const GreetingPage = ({ history }) => {
  const frontendBoot = useSelector(state => state.frontendBoot);
  const { loading, error } = frontendBoot;
 const dispatch = useDispatch()
  // this will be called when the app first mount and on the first page'
  // will be used to contain all data

  useEffect(() => {
    dispatch(setShouldFetchQuotes(false))
  }, [])

  return (
    <>
      {loading ? (
        <Loader />
      ) : (
        <Container
          mt={20}
          fluid
          id="input"
          style={{
            minHeight: "100vh",
            //white-edges v
            // padding: "15px 15px 0px",
          }}
        >
          <div className="element-section">
            <Row>
              <GreetingPlanCard />
              <GreetingFormCard history={history} />
            </Row>
            <Row></Row>
          </div>
        </Container>
      )}
    </>
  );
};

export default GreetingPage;
