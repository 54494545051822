import React, { useEffect, useState } from "react";
import QuoteFilter from "../QuotesPage/components/QuoteFilter/QuoteFilter";
import QuoteCard from "./components/QuoteCard/QuoteCard";
import RecomendationCard from "./components/RecommendationCard/RecomendationCard";
import { addQuoteToCart,removeQuoteFromCart} from "../Cart/cart.slice";
import { Col, Container, Row } from "react-bootstrap";
import SortByDD from "./components/DropDown/SortByDD";
import ComparePopup from "./components/ComparePopup/ComparePopup";
import QuotePlanFor from "../QuotesPage/components/QuotePlanFor/QuotePlanFor";
import BuyNowModal from "./components/BuyNowModal/BuyNowModal";
import SeeDetails from "./../SeeDetails/SeeDetails";
import QuoteFilterMobile from "./components/QuoteFilter/QuoteFilterMobile";
import CardSkeletonLoader from "./../../components/Common/card-skeleton-loader/CardSkeletonLoader";
import "./Scss/QuotePage.scss";
import FilterSkeletonLoader from "./../../components/Common/filter-skeleton-loader/FilterSkeletonLoader";
import useQuotesPage from "./useQuotesPage";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { fetchQuotes, setFilters } from "./quotePage.slice";
import RefreshIcon from "../../assets/images/refresh.png";
import TalkToUsButton from "../../components/Common/Button/TalkToUsButton";
import TalkToUsModal from "../../components/Common/Modal/TalkToUsModal";
import TalkToUsModalMobile from "../../components/Common/Modal/TalkToUsModalMobile";
import styled from "styled-components/macro";
import MobileHeader from "./components/MobileHeader/MobileHeader";
import MobileQuoteCard from "./components/MobileQuoteCard";
import MobilePlansFor from "./components/MobilePlansFor";

const QuotesPage = props => {
  const {
    quotes,
    filterMobile,
    arr,
    companies,
    setFilterMobile,
    filterQuotes,
    showTalkModal,
    setShowTalkModal,
    setShowBuyNow,
    setShowSeeDetails,
    showPopup,
    showBuyNow,
    showSeeDetails,
    sortByData,
    quotesLength,
    member,
    setSortBy,
    recFilterdQuotes,
  } = useQuotesPage(props);

  const [seeDetailsQuote, setSeeDetailsQuote] = useState({
    quote: "",
    activeSum: "",
  });

  const { memberGroups, proposerDetails } = useSelector(
    state => state.greetingPage,
  );

  const { planType } = useSelector(state => state.quotePage.filters);
  // const { selectedGroup } = useSelector(state => state.quotePage);
  const {
    plantypes,
    baseplantypes: basePlanTypes,
    covers,
  } = useSelector(state => state.frontendBoot.frontendData.data);

  const { groupCode } = useParams();

  useEffect(() => {
    setShowSeeDetails(false);
  }, [groupCode]);

  const selectedGroup = groupCode;

  const selectedPlanType =
    planType ||
    plantypes?.find(planType => planType.code === proposerDetails.plan_type)
      ?.display_name ||
    "";

  const plansFor = memberGroups[selectedGroup]?.join(", ") || [];

  const { loadingQuotes, filters } = useSelector(state => state.quotePage);

  const firstQuoteFound =
    filterQuotes.some(quotes => quotes?.length > 0) || !loadingQuotes;

  const dispatch = useDispatch();

  const { cover, tenure, plan_type } = useSelector(
    ({ frontendBoot }) => frontendBoot.frontendData.data.defaultfilters,
  );

  const defaultfilters = {
    insurers: [],
    premium: null,
    cover: "3 to 5 Lacs",
    ownCover: null,
    planType:
      memberGroups[selectedGroup].length === 1
        ? "Individual"
        : proposerDetails.plan_type
          ? proposerDetails.plan_type === "M"
            ? "Multi Individual"
            : "Family Floater"
          : "Family Floater",
    multiYear: "1 Year",
    basePlanType: "Base health",
    moreFilters: {},
  };
  console.log("gege3", defaultfilters, filters);
  const isFiltersDefault =
    filters.premium === defaultfilters.premium &&
      filters.cover === defaultfilters.cover &&
      filters.basePlanType === defaultfilters.basePlanType &&
      filters.insurers.length < 1 &&
      filters.multiYear === defaultfilters.multiYear &&
      Object.keys(filters.moreFilters).length === 0
      ? true
      : false;



  const handleClearFilters = () => {
    dispatch(setFilters(defaultfilters));
    console.log('clear filters fetchQuotes')
    dispatch(
      fetchQuotes(companies?.companies, {
        sum_insured: cover,
        tenure,
        member: selectedGroup,
        plan_type:
          memberGroups[selectedGroup].length === 1
            ? "I"
            : proposerDetails.plan_type
              ? proposerDetails.plan_type === "M"
                ? "M"
                : "F"
              : "F",
      }),
    );
  };

  return (
    <div
      style={{
        minHeight: "100vh",
        background:
          "rgba(0, 0, 0, 0) linear-gradient(156deg, rgb(250, 250, 252) 0%, rgb(250, 250, 252) 60%, rgb(255, 235, 235) 106%) repeat scroll 0% 0%",
      }}
    >
      <span
        css={`
          display: none;
          @media (max-width: 1023px) {
            display: block;
          }
        `}
      >
        <MobileHeader groupCode={groupCode} />
        <MobilePlansFor />
        <span
          css={`
            display: none;
            @media (max-width: 767px) {
              display: block;
              padding-bottom: 67px;
            }
          `}
        >
          {" "}
          {recFilterdQuotes?.length > 0 &&
            recFilterdQuotes.map((item, index) => {
              return (
                <MobileQuoteCard
                  key={index}
                  id={index}
                  isRecommended={item[0].score}
                  item={item}
                  handleClick={() => setShowBuyNow(true)}
                  handleSeeDetails={quote => {
                    setSeeDetailsQuote(quote);
                    setShowSeeDetails(true);
                  }}
                />
              );
            })}
          {quotesLength ? (
            // filterQuotes.length > 1 ? (
            firstQuoteFound ? (
              filterQuotes.map(
                (item, index) =>
                  item.length > 0 && (
                    <MobileQuoteCard
                      key={index}
                      id={index}
                      item={item}
                      handleClick={() => setShowBuyNow(true)}
                      handleSeeDetails={quote => {
                        setSeeDetailsQuote(quote);
                        setShowSeeDetails(true);
                      }}
                    />
                  ),
              )
            ) : (
              <CardSkeletonLoader noOfCards={3} />
            )
          ) : (
            // filterQuotes.length > 1 && <p>No Quotes Found</p>
            !loadingQuotes && <p>No Quotes Found</p>
          )}
          {loadingQuotes && <CardSkeletonLoader noOfCards={1} />}
        </span>
        <ComparePopup showPopup={showPopup} groupCode={groupCode} />
      </span>
      {filterMobile ? (
        <QuoteFilterMobile
          companies={arr}
          coverFilter={companies?.covers}
          setFilterMobile={setFilterMobile}
          premiumFilter={companies?.premiums}
          moreFilter={companies?.morefilters}
          planType={companies?.plantypes}
        />
      ) : (
        <>
          <QuotePlanFor members={member} />
          {/* {filterQuotes.length > 1 ? ( */}
          {firstQuoteFound ? (
            <QuoteFilter
              setShowBuyNow={() => setShowBuyNow(true)}
              companies={arr}
              coverFilter={companies?.covers}
              premiumFilter={companies?.premiums}
              planType={companies?.plantypes}
              moreFilter={companies?.morefilters}
              setFilterMobile={setFilterMobile}
              setShowTalkModal={setShowTalkModal}
            />
          ) : (
            <FilterSkeletonLoader />
          )}
          <Container
            fluid
            style={{
              marginTop: "12px",

              //  marginBottom: "56px"
            }}
            css={`
              @media (min-width: 1200px) {
                max-width: 1200px !important;
              }
              @media (max-width: 1023px) {
                padding-bottom: 68px;
              }
              @media (max-width: 767px) {
                display: none;
              }
            `}
            className="quote__container"
          >
            <Row>
              <Col xl={9}>
                {firstQuoteFound && (
                  <Row
                    css={`
                      display: flex;
                      align-items: center;
                      padding-left: 18px;
                      justify-content: space-between;
                      @media (max-width: 1023px) {
                        display: none;
                      }
                    `}
                  >
                    <Col md={4}>
                      {/* {filterQuotes.length > 1 && ( */}
                      {true && (
                        <div className="product_title_p_bor">
                          <p
                            className="font_bold margin_showing margin_title_product_plan text-dark"
                            css={`
                              white-space: nowrap;
                              ${!isFiltersDefault
                                ? "white-space: nowrap; text-overflow: ellipsis; min-width: 333px; overflow: hidden;"
                                : "white-space: nowrap; white-space: nowrap; text-overflow: ellipsis; min-width: 600px;  overflow: hidden;"}
                            `}
                          >
                            Showing {selectedPlanType} Plans for{" "}
                            <span style={{ textTransform: "capitalize" }}>
                              {plansFor?.replaceAll("_", "-")}
                            </span>
                          </p>
                        </div>
                      )}
                    </Col>
                    {!isFiltersDefault && (
                      <Col
                        md={4}
                        css={`
                          display: flex;
                          justify-content: center;
                        `}
                      >
                        <button
                          onClick={handleClearFilters}
                          style={{
                            background: "#fff0f0",
                            color: "var(--abc-red)",
                            fontWeight: "bold",
                            width: "max-content",
                            padding: "8px 12px",
                            borderRadius: "24px",
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "space-between",
                          }}
                          css={`
                            &:hover {
                              // color: #fff0f0 !important;
                              // background: var(--abc-red) !important;
                              border: 1px solid var(--abc-red);
                            }
                          `}
                        >
                          Clear all filters
                          <img
                            src={RefreshIcon}
                            alt="clear"
                            css={`
                              &:hover {
                                color: white !important;
                              }
                            `}
                            style={{ width: "16px", marginLeft: "10px" }}
                          />
                        </button>
                      </Col>
                    )}
                    <Col md={4} className={""}>
                      {/* {filterQuotes.length > 1 && ( */}
                      {true && (
                        <SortByDD
                          list={sortByData}
                          title="Sort By"
                          onSortByChange={setSortBy}
                        />
                      )}
                    </Col>
                  </Row>
                )}
                <div>
                  {recFilterdQuotes?.length > 0 &&
                    recFilterdQuotes.map((item, index) => {
                      return (
                        <QuoteCard
                          key={index}
                          lastQuote={index === recFilterdQuotes.length - 1}
                          id={index}
                          isRecommended={item[0].score}
                          item={item}
                          handleClick={() => setShowBuyNow(true)}
                          handleSeeDetails={(quote, clickedFrom) => {
                            setSeeDetailsQuote(quote);
                            setShowSeeDetails(clickedFrom || true);
                          }}
                        />
                      );
                    })}
                </div>
                <div>
                  {quotesLength ? (
                    // filterQuotes.length > 1 ? (
                    firstQuoteFound ? (
                      filterQuotes.map(
                        (item, index) =>
                          item.length > 0 && (
                            <QuoteCard
                              key={index}
                              id={index}
                              item={item}
                              handleClick={() => setShowBuyNow(true)}
                              handleSeeDetails={(quote, clickedFrom) => {
                                setSeeDetailsQuote(quote);
                                setShowSeeDetails(clickedFrom || true);
                                
                              }}
                            />
                          ),
                      )
                    ) : (
                      <CardSkeletonLoader noOfCards={3} />
                    )
                  ) : (
                    // filterQuotes.length > 1 && <p>No Quotes Found</p>
                    !loadingQuotes && (
                      <p
                        css={`
                          display: flex;
                          height: 275px;
                          justify-content: center;
                          align-items: center;
                        `}
                      >
                        {!firstQuoteFound ?
                          <span>
                            No Quotes Found !
                          </span>
                          :
                          <CardSkeletonLoader noOfCards={1} />
                        }
                      </p>
                    )
                  )}
                  {loadingQuotes && <CardSkeletonLoader noOfCards={1} />}
                </div>
              </Col>
              <Col
                xl={3}
                css={`
                  @media (max-width: 1023px) {
                    display: none;
                  }
                `}
              >
                {/* {filterQuotes.length > 1 ? ( */}
                {/* {!loadingQuotes ? ( */}
                <p
                  css={`
                    display: none;
                    @media (min-width: 1200px) {
                      display: block;
                      margin-left: 16px;
                      margin-left: 16px;
                      position: relative;
                      top: 11px;
                      &:after {
                        content: "";
                        height: 33px;
                        width: 8px;
                        position: absolute;
                        left: -14px;
                        top: -3px;
                        background-color: #fecc28;
                        border-radius: 0 15px 15px 0;
                      }
                    }
                  `}
                >
                  All premium in plans are gst inclusive
                </p>
                <RecomendationCard />
                {/* ) : ( */}
                {/* <CardSkeletonLoader noOfCards={1} /> */}
                {/* )} */}
              </Col>
            </Row>
            <ComparePopup showPopup={showPopup} groupCode={groupCode} />
            {showBuyNow && (
              <BuyNowModal
                showBuyNow={showBuyNow}
                setShowBuyNow={setShowBuyNow}
              />
            )}
            {showSeeDetails && (
              <SeeDetails
                show={showSeeDetails}
                handleClose={() => {
                  setShowSeeDetails(!showSeeDetails);
                  dispatch(removeQuoteFromCart(groupCode));
                }}
                quote={seeDetailsQuote.quote}
                sum_insured={
                  seeDetailsQuote.quote.sum_insured[seeDetailsQuote.activeSum]
                }
                tenure={seeDetailsQuote.quote.tenure[seeDetailsQuote.activeSum]}
                product={
                  seeDetailsQuote.quote.product[seeDetailsQuote.activeSum]
                }
              />
            )}
            {/* <TalkToUsButton
              showTalkModal={showTalkModal}
              setShowTalkModal={setShowTalkModal}
            /> */}
            <TalkToUsModal
              showTalkModal={showTalkModal}
              setShowTalkModal={setShowTalkModal}
            />
          </Container>
        </>
      )}
    </div>
  );
};

const QuotesPageLoading = props => {
  const appLoading = useSelector(state => state.quotePage.appLoading);
  if (appLoading) return "Loading...";
  return <QuotesPage {...props} />;
};

export default QuotesPageLoading;
