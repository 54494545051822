import React from "react";

const CloseBtn = ({ customClassName, onClick }) => {
	return (
		<button
			type="button"
			className={`btn btn-white modal_close_css border_radius_50_all ${customClassName}`}
			// onClick={() => history.push("/quotes")}
			onClick={onClick}
		>
			<i className="fa fa-close"></i>
		</button>
	);
};

export default CloseBtn;
