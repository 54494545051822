import "./filterskeleton.scss";

const FilterSkeletonLoader = () => {
  return (
    <div class="card">
      <div class="card__title loading"></div>
      <div class="card__description loading"></div>
    </div>
  );
};

export default FilterSkeletonLoader;
