import React, { useEffect, useState } from "react";
import { Col, Container, ProgressBar, Row } from "react-bootstrap";
import CloseBtn from "../components/closeBtn/CloseBtn";
import Fieldset1 from "./FieldsetData/Fieldset1";
import Fieldset2 from "./FieldsetData/Fieldset2";
import Fieldset3 from "./FieldsetData/Fieldset3";
import Fieldset4 from "./FieldsetData/Fieldset4";
import Fieldset5 from "./FieldsetData/Fieldset5";
import Fieldset6 from "./FieldsetData/Fieldset6";
import Fieldset7 from "./FieldsetData/Fieldset7";
import Fieldset8 from "./FieldsetData/Fieldset8";
import Fieldset9 from "./FieldsetData/Fieldset9";
import Success from "./FieldsetData/Success";
import Fieldset from "../components/Fieldset/Fieldset";
import { useHistory, useParams } from "react-router-dom";
import Summary from "../components/SummaryTab/Summary";
import useUrlQuery from "../../../customHooks/useUrlQuery";
import { useDispatch, useSelector } from "react-redux";
import {
  selectAnswers,
  selectQuestions,
  selectSelectedHospitals,
  setAnswers,
} from "../recommendedPage.slice";
import "styled-components/macro";
import * as mq from "../../../utils/mediaQueries";
import {
  MobileHeader,
  MobileHeaderText,
} from "../../ProposalPage/ProposalPage.style";
import EditPencil from "../../../assets/images/pencil_m.png";
import Modal from "../../../components/Common/Modal";
import { CloseButton } from "../../Cart/components/SidebarCart/SidebarCart";
import styled from "styled-components/macro";
import BG_RIGHT from "../../../assets/images/bg_search.png";
import BG_LEFT from "../../../assets/images/bg_left.png";
import { selectMembersWithAge } from "../../GreetingPage/reducer/greetingPage.slice";
import { recommendationIcons } from "../icons/recommendationIcons";
import { YourDetails } from "../../RecommendationResult/RecommendationResult";

const Fieldsets = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { groupCode } = useParams();
  const urlQuerires = useUrlQuery();
  const enquiryId = urlQuerires.get("enquiryId");
  const initialQno = parseInt(urlQuerires.get("qno")) || 0;
  const [currentForm, setCurrentForm] = useState(initialQno);
  const questions = useSelector(state => state.recommendedPage.questions);
  const previousAnswers = useSelector(selectAnswers(groupCode));

  useEffect(() => {
    if (questions.length === 0) {
      window.location = `/recommend/${groupCode}?enquiryId=${enquiryId}`;
    }
  }, []);

  const handleContinue = (value, question) => {
    const nextQuestion = questions[currentForm + 1];

    const { type, name } = question;
    if (
      (type === "multiselect" && value.length > 0) ||
      (type === "toggle" && value)
    ) {
      dispatch(
        setAnswers({
          groupCode,
          answers: { ...previousAnswers, [name]: value },
        }),
      );
      if (!nextQuestion) {
        history.push(
          `/recommendation-result/${groupCode}?enquiryId=${enquiryId}`,
        );
        return;
      }
      // if (nextQuestion) {
      //   // const { when } = nextQuestion;
      //   // if (when) {
      //   //   if (Array.isArray(value)) {
      //   //     if (!value.includes(when)) {
      //   //       setCurrentForm(currentForm + 2);
      //   //       return;
      //   //     }
      //   //   } else if (value !== when) {
      //   //     setCurrentForm(currentForm + 2);
      //   //     return;
      //   //   }
      //   // }

      // }
      setCurrentForm(currentForm + 1);
    }
  };

  // useEffect(() => {
  //   if (currentForm > 0) {
  //     const { when } = questions[currentForm];
  //     const lastQuestion = Object.keys(previousAnswers)[currentForm - 1];
  //     if (lastQuestion && when) {
  //       const prevAnswer = previousAnswers[lastQuestion];
  //       if (Array.isArray(prevAnswer)) {
  //         if (!prevAnswer.includes(when)) {
  //           return null;
  //         }
  //       } else if (prevAnswer !== when) {
  //         alert(prevAnswer + " " + when);
  //       }
  //     }
  //   }
  // }, []);

  const conditionalBack = () => {
    const prevQuestion = questions[currentForm - 1];
    if (prevQuestion) {
      const { name } = prevQuestion;
      if (name === "network_hospital") {
        setCurrentForm(currentForm - 1);
        return;
      }
    }
    // const prevAnswered = Object.keys(previousAnswers).slice(0, currentForm);
    // const prevIndex = questions.findIndex(
    //   q => q.name === prevAnswered[prevAnswered.length - 1],
    // );

    // setCurrentForm(prevIndex);

    const prevQuestions = questions.slice(0, currentForm).reverse();

    const pq = prevQuestions.find(prevQuestion => {
      const { when, name } = prevQuestion;

      if (!previousAnswers[name]) {
        return false;
      }

      if (when && when[0].question_id) {
        const render = when.some(condition => {
          const { question_id, answers } = condition;
          // const { name } = questions[question_id - 1];
          const { name } = questions.find(
            q => q.sequence === parseInt(question_id),
          );
          const prevAnswer = previousAnswers[name];
          if (Array.isArray(prevAnswer)) {
            return prevAnswer.some(a => answers.includes(a));
          }
          return answers.includes(prevAnswer);
        });
        if (!render) return false;
      }
      return prevQuestion;
    });

    const idx = questions.findIndex(q => q.name === pq.name);

    setCurrentForm(idx);
  };

  const handleBackClick = () => {
    currentForm === 0
      ? history.push(`/quotes/${groupCode}?enquiryId=${enquiryId}`)
      : conditionalBack();
  };

  const handleClose = () => {
    history.push(`/quotes/${groupCode}?enquiryId=${enquiryId}`);
  };

  return (
    <>
      <FullScreenbackground
        css={`
          background: linear-gradient(
            176deg,
            rgb(250, 250, 252) 0%,
            rgb(250, 250, 252) 60%,
            rgb(255, 245, 245) 100%
          );
        `}
      />
      <FullScreenbackground
        css={`
          background: url(${BG_RIGHT}) no-repeat;
          background-position: 100% 100%;
        `}
      />
      <FullScreenbackground
        css={`
          background: url(${BG_LEFT}) no-repeat;
          background-position: 0 100%;
        `}
      />
      <FullScreenbackground
        css={`
          background: url(${recommendationIcons[questions[currentForm]?.name]})
            no-repeat;
          background-position: 90% 90%;
          background-size: calc(
            ${[
                "monthly_diseases",
                "exercise",
                "child_birth",
                "network_hospital",
                "annual_income",
                "travel",
              ].includes(questions[currentForm]?.name)
                ? 467
                : 399} * 100vw / 1440
          );
          opacity: 1;

          ${mq.mobile} {
            background-size: calc(70 * 100vw / 360);
          }
        `}
      />
      <Container
        fluid
        className="recommended__container"
        style={{ position: "relative" }}
        css={`
          ${mq.mobile} {
            display: none;
          }
        `}
      >
        <CloseBtn
          customClassName={"recommended__fieldetClose "}
          onClick={handleClose}
        />
        <Row>
          <p className="go_back_arrow" onClick={handleBackClick}>
            <i className="icon flaticon-back"></i>
            <span
              className="margin_recommend_bg_l"
              css={`
                margin-left: -16px;
              `}
            >
              Go Back
            </span>
          </p>
        </Row>
        <Row>
          <div className="recommended__content">
            <ProgressBar
              now={((currentForm + 1) / questions?.length) * 100}
              css={`
                width: 180px;
              `}
            />
            {/* <span className="color_onenine">
              {currentForm !== questions.length
                ? currentForm + 1
                : questions.length}
              /{questions.length}
            </span> */}
            <br />

            {questions.map((question, idx) => {
              return question.name === "network_hospital" ? (
                <Fieldset1
                  currentForm={currentForm}
                  setCurrentForm={setCurrentForm}
                  question={questions[0]}
                  index={idx}
                  key={idx}
                />
              ) : (
                <Fieldset
                  question={question}
                  index={idx}
                  currentForm={currentForm}
                  setCurrentForm={setCurrentForm}
                  handleContinue={handleContinue}
                  key={idx}
                />
              );
            })}
            {/* <Success
              currentForm={currentForm}
              setCurrentForm={setCurrentForm}
            /> */}

            {/* <Fieldset2
              currentForm={currentForm}
              setCurrentForm={setCurrentForm}
            />
            <Fieldset3
              currentForm={currentForm}
              setCurrentForm={setCurrentForm}
            />
            <Fieldset4
              currentForm={currentForm}
              setCurrentForm={setCurrentForm}
            />
            <Fieldset5
              currentForm={currentForm}
              setCurrentForm={setCurrentForm}
            />
            <Fieldset6
              currentForm={currentForm}
              setCurrentForm={setCurrentForm}
            />

            <Fieldset7
              currentForm={currentForm}
              setCurrentForm={setCurrentForm}
            />
            <Fieldset8
              currentForm={currentForm}
              setCurrentForm={setCurrentForm}
            />
            <Fieldset9
              currentForm={currentForm}
              setCurrentForm={setCurrentForm}
            />
            <Success
              currentForm={currentForm}
              setCurrentForm={setCurrentForm}
            /> */}
          </div>
          <div>
            {/* <Summary setCurrentForm={setCurrentForm} /> */}
            <YourDetails questionPage setCurrentForm={setCurrentForm} />
          </div>
        </Row>
      </Container>

      <div
        css={`
          display: none;
          ${mq.mobile} {
            display: flex;
            flex-direction: column;
          }
        `}
      >
        <MobileHeader
          css={`
            display: flex;
            align-items: center;
            color: #fff;
          `}
        >
          <div
            css={`
              display: flex;
              align-items: center;
            `}
          >
            <button
              onClick={() => {
                history.goBack();
              }}
              css={`
                color: #fff;
                display: flex;
                align-items: center;
              `}
            >
              <i className="flaticon-back" />
            </button>
            <p
              css={`
                margin-left: 0.6em;
              `}
            >
              Recommend
            </p>
          </div>
        </MobileHeader>
        <SummaryMobile setCurrentForm={setCurrentForm} />
        <div
          css={`
            display: flex;
            align-items: center;
            margin-top: 2rem;
            position: relative;
          `}
        >
          {currentForm > 0 ? (
            <button
              css={`
                background-color: #efefef;
                height: 30px;
                width: 30px;
                border-radius: 50%;
                display: flex;
                align-items: center;
                justify-content: center;
                position: absolute;
                left: 2%;
              `}
              onClick={handleBackClick}
            >
              <i
                className="fa fa-angle-up"
                css={`
                  transform: rotate(-90deg) translate(0, -10%);
                `}
              />
            </button>
          ) : null}
          <div
            css={`
              display: flex;
              align-items: center;
              margin: auto;
            `}
          >
            <ProgressBar
              now={((currentForm + 1) / questions?.length) * 100}
              css={`
                width: 180px;
              `}
            />
            {/* <span
              css={`
                font-size: 12px;
                color: #a1a1a1;
              `}
            >
              {currentForm !== questions.length
                ? currentForm + 1
                : questions.length}
              /{questions.length}
            </span> */}
          </div>
        </div>

        <div
          css={`
            margin-top: 1.6rem;
          `}
        >
          {questions.map((question, idx) => {
            return question.name === "network_hospital" ? (
              <Fieldset1
                currentForm={currentForm}
                setCurrentForm={setCurrentForm}
                question={questions[0]}
                index={idx}
              />
            ) : (
              <Fieldset
                question={question}
                index={idx}
                currentForm={currentForm}
                setCurrentForm={setCurrentForm}
                handleContinue={handleContinue}
              />
            );
          })}
        </div>
      </div>
    </>
  );
};

export default Fieldsets;

export function SummaryMobile({
  setCurrentForm,
  showPopup = false,
  onClose = () => {},
  resultPage,
}) {
  const history = useHistory();
  const enquiryId = useUrlQuery().get("enquiryId");
  const { groupCode } = useParams();
  const selectedHospitals = useSelector(selectSelectedHospitals(groupCode));
  const answers = useSelector(selectAnswers(groupCode));
  const questions = useSelector(selectQuestions);

  const selectedDetails = questions.filter(
    q =>
      (q.name === "network_hospital" && selectedHospitals.length > 0) ||
      answers[q.name],
  );

  const [showViewMore, setShowViewMore] = useState(false);

  const handleEdit = idx => {
    if (resultPage) {
      history.push(`/recommend/${groupCode}?enquiryId=${enquiryId}&qno=${idx}`);
      return;
    }
    setCurrentForm(idx);
  };

  const handleViewMoreClick = () => {
    setShowViewMore(true);
  };

  const membersWithAge = useSelector(selectMembersWithAge(groupCode));

  const { gender } = useSelector(state => state.greetingPage.proposerDetails);

  return (
    <div
      css={`
        padding: 15px 15px 30px 15px;
        box-shadow: 0 3px 6px 0 rgb(16 24 48 / 12%);
        position: relative;

        ${mq.small} {
          padding: 10px 10px 20px 10px;
        }
      `}
    >
      {showViewMore || showPopup ? (
        <SelectedDetailsPopup
          setCurrentForm={setCurrentForm}
          handleClose={() => {
            setShowViewMore(false);
            onClose(false);
          }}
        >
          {resultPage && (
            <div
              css={`
                margin-top: 1em;
              `}
            >
              {membersWithAge.map(member => (
                <div
                  css={`
                    color: #000;
                  `}
                >
                  <span
                    css={`
                      text-transform: capitalize;
                      font-weight: 900;
                      ${mq.mobile} {
                        font-size: 0.9em;
                      }
                    `}
                  >
                    {member.type}:
                  </span>
                  <span
                    css={`
                      margin-left: 0.3em;
                      color: #727f9f;
                      font-weight: 900;
                      ${mq.mobile} {
                        font-size: 0.9em;
                      }
                    `}
                  >
                    {member.age}
                    {["self", "spouse"].includes(member.type) && ", "}
                  </span>
                  {["self", "spouse"].includes(member.type) && (
                    <span
                      css={`
                        margin-left: 0.3em;
                        color: #727f9f;
                        font-weight: 900;
                        ${mq.mobile} {
                          font-size: 0.9em;
                        }
                      `}
                    >
                      {member.type === "self"
                        ? gender === "M"
                          ? "Male"
                          : "Female"
                        : member.type === "spouse"
                        ? gender === "M"
                          ? "Female"
                          : "Male"
                        : ""}
                    </span>
                  )}
                </div>
              ))}
            </div>
          )}
          {selectedDetails.map((detail, idx) => {
            if (detail.name === "network_hospital") {
              return (
                <SelectedDetailsPopup.ListItem
                  handleEdit={() => {
                    setCurrentForm(idx);
                    setShowViewMore(false);
                    if (resultPage) handleEdit();
                  }}
                  heading={
                    <div>
                      <p
                        css={`
                          text-transform: capitalize;
                          color: #000;
                          font-size: 14px;
                          line-height: 1.6;
                          min-width: max-content;
                          margin-right: 0.3em;
                        `}
                      >
                        Hospital List:
                      </p>
                      <div>
                        {selectedHospitals.map(selectedHospital => (
                          <p
                            css={`
                              font-size: 0.76em;
                              width: 200px;
                              overflow: hidden;
                              white-space: nowrap;
                              text-overflow: ellipsis;
                            `}
                          >
                            -{" "}
                            {selectedHospital.hospital_name ||
                              selectedHospital.name}
                          </p>
                        ))}
                      </div>
                    </div>
                  }
                />
              );
            }
            return (
              <SelectedDetailsPopup.ListItem
                handleEdit={() => {
                  setCurrentForm(idx);
                  setShowViewMore(false);
                  if (resultPage) handleEdit();
                }}
                heading={
                  <p
                    css={`
                      text-transform: capitalize;
                      color: #000;
                      font-size: 14px;
                      line-height: 1.6;
                      min-width: max-content;
                      margin-right: 0.3em;
                    `}
                  >
                    {detail.name?.replace("_", " ")}:
                  </p>
                }
                value={
                  <p
                    css={`
                      font-weight: normal;
                      font-size: 16px;
                      overflow: hidden;
                      white-space: nowrap;
                      text-overflow: ellipsis;
                      margin: 0 10px;
                    `}
                  >
                    {answers[detail.name]}
                  </p>
                }
              />
            );
          })}
        </SelectedDetailsPopup>
      ) : null}
      <p
        css={`
          font-size: 12px;
          line-height: 1.6;
        `}
      >
        Your selected Details
      </p>
      <div>
        {selectedDetails.slice(0, 2).map((question, idx) =>
          question.name === "network_hospital" && selectedHospitals?.length ? (
            <>
              <Title
                question={{ ...question, name: "Hospitals Name" }}
                handleEdit={() => handleEdit(idx)}
              />
              <p
                css={`
                  /* margin-bottom: 6px; */
                `}
              >
                {selectedHospitals.map(selectedHospital => (
                  <p
                    css={`
                      line-height: 1.6;
                      font-size: 14px;
                      color: #727f9f;
                    `}
                  >
                    {selectedHospital.name}
                  </p>
                ))}
              </p>
            </>
          ) : answers[question.name] ? (
            <>
              <Title
                question={question}
                handleEdit={() => handleEdit(idx)}
                answer={
                  Array.isArray(answers[question.name])
                    ? answers[question.name].join(", ")
                    : answers[question.name]
                }
              />
            </>
          ) : null,
        )}
      </div>
      <button
        css={`
          font-size: 12px;
          background-color: #efefef;
          padding: 3px 7px;
          border-radius: 30px;
          position: absolute;
          bottom: 0;
          left: 50%;
          transform: translate(-50%, 50%);
          display: flex;
          align-items: center;

          & .fa {
            font-size: 1.2em;
            margin-left: 0.3em;
          }
        `}
        onClick={handleViewMoreClick}
      >
        View more <i className="fa fa-angle-down" />
      </button>
    </div>
  );
}

function Title({ question = {}, handleEdit = () => {}, answer }) {
  const { name: title } = question;
  const handleEditClick = () => {
    handleEdit(question);
  };
  return (
    <div
      css={`
        display: flex;
        align-items: center;
        justify-content: space-between;
      `}
    >
      <div
        css={`
          display: flex;
        `}
      >
        <p
          css={`
            text-transform: capitalize;
            color: #000;
            font-size: 14px;
            line-height: 1.6;
            min-width: max-content;
            margin-right: 0.3em;
          `}
        >
          {title?.replace("_", " ")}:
        </p>
        <p
          css={`
            /* margin-bottom: 6px; */
            line-height: 1.6;
            font-size: 14px;
            color: #727f9f;
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
          `}
        >
          {answer}
        </p>
      </div>

      <button
        onClick={handleEditClick}
        css={`
          height: 20px;
          min-width: 20px;
          display: flex;
          align-items: center;
          justify-content: center;
          /* box-shadow: 0 3px 6px 0 rgb(16 24 48 / 12%); */
          border-radius: 50%;
          margin-left: 0.3em;
        `}
      >
        <img src={EditPencil} alt="edit" />
      </button>
    </div>
  );
}

function SelectedDetailsPopup({ handleClose = () => {}, children }) {
  return (
    <Modal>
      <div
        css={`
          position: fixed;
          height: 100vh;
          width: 100vw;
          top: 0;
          left: 0;
          z-index: 999;
        `}
      >
        <div
          css={`
            background-color: #fff;
            margin: 60px auto;
            border-radius: 10px;
            padding: 15px;
            width: 90%;
            position: relative;
          `}
        >
          <CloseButton
            css={`
              position: absolute;
              right: 10px;
              top: 10px;
              height: 30px;
              width: 30px;
              font-size: 16px;
            `}
            onClick={handleClose}
          />
          <h1
            css={`
              font-size: 18px;
              font-weight: 900;
            `}
          >
            Your selected details
          </h1>
          <div
            css={`
              margin-top: 1rem;
            `}
          >
            {children}
          </div>
        </div>
      </div>
    </Modal>
  );
}

SelectedDetailsPopup.ListItem = ({ heading, value, handleEdit = () => {} }) => {
  const handleEditClick = () => {
    handleEdit();
  };
  return (
    <div
      css={`
        border-bottom: 1px solid #ddd;
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 10px 0;
        margin: 3px;
      `}
    >
      <div>{heading}</div>
      <div
        css={`
          flex: 1;
          overflow: hidden;
        `}
      >
        {value}
      </div>
      <button
        onClick={handleEditClick}
        css={`
          height: 20px;
          min-width: 20px;
          display: flex;
          align-items: center;
          justify-content: center;
          /* box-shadow: 0 3px 6px 0 rgb(16 24 48 / 12%); */
          border-radius: 50%;
          margin-left: 0.3em;
        `}
      >
        <img src={EditPencil} alt="edit" />
      </button>
    </div>
  );
};

const FullScreenbackground = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  height: 100vh;
  width: 100vw;
  z-index: -1;
`;
