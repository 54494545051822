import React, { useState, useEffect, useRef, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { yupResolver } from "@hookform/resolvers";
import { useForm } from "react-hook-form";
import BackIcon from "../../components/BackIcon/BackIcon";
import cross from "../../../../assets/images/cross.png";
import StyledButton from "../../components/Button/StyledButton";
import "../../components/Dropdown/PincodeDropdown/PincodeDropdown.scss";
import { InputLabel } from "../../components/Dropdown/PincodeDropdown/PincodeDropdown";
import { Label } from "../../components/FormComponents/FormComponents";
import styled from "styled-components";
import { Alert, Col, Row } from "react-bootstrap";

import { RadioInput, RadioLabel } from "../../components/Radio/CitiesRadio";
import "../../components/Radio/CitiesRadioButton.scss";
import backButton from "../../../../assets/images/backbutton.png";
import {
  saveForm2UserDetails,
  setIsDisabled,
} from "../../reducer/greetingPage.slice";
import { ErrorMessage } from "../../components/TextInput/GreetingPage/GreetingTextInput";
import { secondFormSchema } from "../../../../utils/validationSchema";
import { getRegion } from "./../../reducer/greetingPage.slice";
import BackBtn from "../../components/Button/BackBtn";
import "styled-components/macro";
import { useHistory } from "react-router-dom";

const Fieldset2 = ({ currentForm, handleChange, value }) => {
  const dispatch = useDispatch();
  const [displayRadios, setDisplayRadios] = useState(true);

  const { regionDetails, regionDetailsLoading, proposerDetails, isDisabled } =
    useSelector(state => state.greetingPage);
  const [pinCode, setPinCode] = useState();
  const [flag, setFlag] = useState(true);
  const [customErrors, setCustomErrors] = useState(false);
  const [sortedPopularcities, setSortedPopularcities] = useState([]);

  const [locationKeys, setLocationKeys] = useState([]);
  const history = useHistory();

  const { frontendData } = useSelector(state => state.frontendBoot);
  const ref = useRef();
  const searchInput = useRef(null);

  const { data } = frontendData || [""];
  const { popularcities } = data || [""];

  const { register, handleSubmit, errors } = useForm({
    resolver: yupResolver(secondFormSchema),
    mode: "onSubmit",
  });
  const onSubmit = data => {
    if (
      !isDisabled &&
      regionDetails?.city?.toLowerCase() === data?.pinCode?.toLowerCase()
    ) {
      setCustomErrors(false);
      dispatch(
        saveForm2UserDetails(
          {
            pinCode: regionDetails.pincode,
            is_pincode_search: regionDetails.is_pincode_search,
          },
          handleChange,
        ),
      );
      // handleChange("form3");
    } else if (
      !isDisabled &&
      regionDetails?.city?.toLowerCase() !== data?.pinCode?.toLowerCase()
    ) {
      setCustomErrors("Invalid Pincode or city");
    } else if (isDisabled) {
      setCustomErrors(false);
    }
  };

  const onSelect = useCallback(selectedItem => {}, []);

  useEffect(() => {
    // if (pinCode?.length > 0) {
    //   setDisplayRadios(false);
    // } else {
    //   setDisplayRadios(true);
    // }
    if (pinCode?.length > 2) {
      dispatch(getRegion(pinCode));
    }
  }, [pinCode]);

  // this is used to stop back btn

  useEffect(() => {
    return history.listen(location => {
      if (history.action === "PUSH") {
        setLocationKeys([location.key]);
      }

      if (history.action === "POP") {
        if (locationKeys[1] === location.key) {
          setLocationKeys(([_, ...keys]) => keys);

          // handleChange("form1");
        } else {
          // Handle back event
          // handleChange("form3");
        }
      }
    });
  }, [locationKeys]);

  useEffect(() => {
    let tempArray = [...popularcities].sort(function (a, b) {
      // ASC  -> a.length - b.length
      // DESC -> b.length - a.length
      return a.name.length - b.name.length;
    });

    setSortedPopularcities(tempArray);
  }, [popularcities]);

  return (
    <fieldset style={{ display: currentForm === value ? "block" : "none" }}>
      <div>
        <BackIcon onClick={() => handleChange("form1")} />
      </div>
      <a
        onClick={() => handleChange("form1")}
        css={`
          display: none !important;
          @media (max-width: 767px) {
            display: inline-block !important;
            height: 32px;
            z-index: 999;
            margin-left: -12px;
          }
        `}
      >
        <img
          src={backButton}
          css={`
            height: 58px;
          `}
        />
      </a>
      <Label
        css={`
          @media (max-width: 767px) {
            margin-bottom: 12px !important;
          }
        `}
      >
        Tell us where do you live?
      </Label>
      <form onSubmit={handleSubmit(onSubmit)}>
        <div
          //style={{ position: "relative", width: "476px" }}
          css={`
            position: relative;
            width: 476px;
            @media (max-width: 767px) {
              width: 100%;
              & input {
                width: 100% !important;
              }
            }
          `}
        >
          {pinCode?.length > 1 && (
            <ClearBtn
              onClick={() => {
                setPinCode("");
              }}
            >
              <img src={cross} />
            </ClearBtn>
          )}
          {/* <img src={search} className="img_icon_se" alt="searchIcon" /> */}
          <InputLabel className="label_pincode">Pincode/City</InputLabel>

          <input
            list="pinCodes"
            name="pinCode"
            placeholder="Enter Pincode/City"
            value={pinCode}
            autoComplete="off"
            id="pinCode"
            className={`pinCodeInput ${customErrors && "redBorders"}`}
            css={`
              @media (max-width: 767px) {
                margin: 0px 0 9px !important;
              }
            `}
            maxLength={20}
            onChange={e => {
              setPinCode(e.target.value);
            }}
            onKeyDown={e => {
              if (e.key === "Enter") {
                handleSubmit(onSubmit);
              }
            }}
            ref={register}
            style={{
              width: "477px",
            }}
          />

          {!regionDetailsLoading &&
            regionDetails?.city &&
            pinCode?.length > 2 &&
            proposerDetails.pincode !== regionDetails?.pincode && (
              <NavDropdown
                onClick={() => {
                  setPinCode(regionDetails.city);
                  setCustomErrors(false);
                  dispatch(
                    saveForm2UserDetails(
                      {
                        pinCode: regionDetails.pincode,
                        is_pincode_search: regionDetails.is_pincode_search,
                      },
                      handleChange,
                    ),
                  );
                  // handleChange("form3");
                }}
              >
                {" "}
                {regionDetails.city}
              </NavDropdown>
            )}

          {customErrors && (
            <ErrorMessage
              css={`
                left: -10px;
                top: -9px;
                margin-top: -3px;
                @media (max-width: 767px) {
                  margin-top: -3px;
                  top: -5px;
                }
              `}
              style={{ marginLeft: "12px" }}
            >
              {customErrors}
            </ErrorMessage>
          )}
        </div>

        <span style={{ display: displayRadios ? "block" : "none" }}>
          <Fieldset2Label>Popular Cities</Fieldset2Label>
          <Row>
            <Col xs={12}>
              {sortedPopularcities &&
                sortedPopularcities.map(({ pincode, name }, i) => (
                  <div key={i} style={{ display: "inline" }}>
                    <RadioInput
                      className="checkbox-tools"
                      type={"radio"}
                      name={name}
                      value={name}
                      id={name}
                      checked={pinCode === pincode || false}
                      onChange={e => {
                        setPinCode(`${e.target.value}`);
                        dispatch(setIsDisabled(true));
                        setCustomErrors(false);
                        dispatch(
                          saveForm2UserDetails(
                            {
                              pinCode: pincode,
                              is_pincode_search: false,
                            },
                            handleChange,
                          ),
                        );
                        // setTimeout(() => {
                        //   handleChange("form3");
                        // }, 500);
                      }}
                    />
                    <RadioLabel className="for-checkbox-tools" htmlFor={name}>
                      {name}
                    </RadioLabel>
                  </div>
                ))}
            </Col>
          </Row>
        </span>
        {/* {Object.keys(errors)[0] && !customErrors && (
          <Alert variant="danger" style={{ fontSize: "15px" }}>
            {errors[Object.keys(errors)[0]].message}
          </Alert>
        )}
        {customErrors && (
          <Alert variant="danger" style={{ fontSize: "15px" }}>
            {customErrors}
          </Alert>
        )} */}
        <span
          css={`
            @media (max-width: 767px) {
              display: flex;
              margin-top: ${!regionDetailsLoading &&
              regionDetails?.city &&
              pinCode?.length > 2 &&
              proposerDetails.pincode !== regionDetails?.pincode &&
              "50px"};
            }
          `}
        >
          <StyledButton
            disable={flag}
            type="submit"
            value={"Continue"}
            laptopLg
            mobileSm
          />
        </span>
      </form>
    </fieldset>
  );
};

export default Fieldset2;

const Fieldset2Label = styled.p`
  font-size: 17px;
  font-size: 17px;
  margin: 9px 6px;
  color: #000;
  font-weight: 100;
  margin-top: -11px !important;
  @media (max-width: 767px) {
    font-size: 20px;
    margin-top: 5px !important;
  }
`;

const ClearBtn = styled.a`
  position: absolute;
  right: 14px;
  top: 19px;
  & img {
    height: 27px;
    font-size: 14px !important;
    background: #fff;
    padding: 0 !important;
  }
`;

const NavDropdown = styled.a`
  background-color: #fff5f6;
  border: 2px solid #d9d9d9;
  border-top: none;
  color: black !important;
  position: absolute;
  z-index: 1;
  left: 1px;
  width: 475px;
  top: 58px;
  border-radius: 0px 0px 12px 12px;
  height: 57px;
  padding: 12px 2px 2px 26px;
  font-size: 20px;
  @media (max-width: 767px) {
    width: 99%;
  }
`;
