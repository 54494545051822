import React from "react";
import styled from "styled-components";

const BackIcon = ({ onClick }) => {
	return (
		<StyledBackIcon type="button" className="hideOnMobile" onClick={onClick}>
			<i className="icon flaticon-back"></i>
		</StyledBackIcon>
	);
};

export default BackIcon;

const StyledBackIcon = styled.button`
	background: #fff;
	color: #000;
	width: 40px;
	height: 40px;
	border-radius: 50px;
	box-shadow: 1px 6px 4px 1px #eaf0f4;
	position: absolute;
	margin: 0px -57px;
	font-size: 19px;
	& i {
		position: relative;
		top: 5px;
		left: 5px;
	}
`;
