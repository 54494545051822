import React from "react";
import { Col, Row } from "react-bootstrap";
import iclogo from "../../../../assets/images/Care_Health.png";
import speedometer from "../../../../assets/images/speedometer.png";

const MobileRecommendedCard = () => {
  return (
    <>
      <Col
        className="col-xs-12"
        sm={6}
        className="single-block recommend_box_shadow aos-init aos-animate"
      >
        <Row>
          <Col className="col-xs-3" lg={1}>
            <img src={iclogo} className="img_care_health_m_top" />
          </Col>
          <Col className="col-xs-4" lg={4}>
            <p className="p_care_name_re">Care Advantage</p>
          </Col>
          <Col className="col-xs-5" lg={1}>
            <button className="btn btn_recommend_more">Buy Now</button>
          </Col>
          <div className="col-xs-12">
            <hr class="hr_line_card_re_m" />
          </div>
          <div className="col-xs-3 col-lg-3 text-black margin_p_right_cover">
            <p className="p_cover_p_s">Cover</p>
            <p className="p_cover_p_s">
              <i className="fa fa-inr"></i> 5.5L
            </p>
          </div>

          <div className="col-xs-4 col-lg-3 text-black cover_premium_re_more">
            <p className="p_cover_p_s">Premium</p>
            <p className="p_cover_p_s">
              <i className="fa fa-inr"></i> 15,225 / per year
            </p>
          </div>
          <div className="col-xs-3 col-lg-1">
            <img src={speedometer} className="margin_left_14_i" />
          </div>
          <div className="col-xs-2 col-lg-2">
            <p className="margin_top_align_re_rate">8.5/10</p>
          </div>
        </Row>
      </Col>{" "}
    </>
  );
};

export default MobileRecommendedCard;
