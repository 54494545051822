import "./App.scss";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import ScrollToTop from "./components/Common/ScrollToTop/ScrollToTopBtn";
import GreetingPage from "./modules/GreetingPage/GreetingPage";
import {
  Route,
  useLocation,
  Switch,
  Redirect,
  useParams,
} from "react-router-dom";
import QuotesPage from "./modules/QuotesPage/QuotesPage";
import ProductDetails from "./modules/ProductDetails/ProductDetails";
import ComparePage from "./modules/ComparePage/ComparePage";
import Navbar from "./components/Common/Navbar/Navbar";
import Footer from "./components/Common/Footer/Footer";
import ProposalPage from "./modules/ProposalPage/ProposalPage";
import { fetchFrontendData } from "./FrontendBoot/reducer/frontendBoot.slice";
import {
  ageError,
  getProposerDetails,
} from "./modules/GreetingPage/reducer/greetingPage.slice";
import RecommendedMore from "./modules/RecommendedMore/RecommendedMore";
import RecommendedPage from "./modules/RecommendedPage/RecommendedPage";
import Loader from "./components/Common/Loader/Loader";

import ProposalSummary from "./modules/ProposalSummary/ProposalSummary";
import CardSkeletonLoader from "./components/Common/card-skeleton-loader/CardSkeletonLoader";
import ThankYouPage from "./modules/ThankYouPage/ThankYouPage";
import EligibilityPage from "./modules/EligibilityPage/EligibilityPage";
import Generator from "./modules/Generator/Generator";
import { getCart } from "./modules/Cart/cart.slice";
import RecommendationResult from "./modules/RecommendationResult/RecommendationResult";
import { getRecommendedQuotesOnMount } from "./modules/RecommendedPage/recommendedPage.slice";
import { fetchQuotes, setFilters, setShouldFetchQuotes } from "./modules/QuotesPage/quotePage.slice";
import { getCompare, setShoutGetCompare } from "./modules/ComparePage/compare.slice";

const App = () => {
  const dispatch = useDispatch();
  const { groupCode } = useParams();
  const { pathname } = useLocation();
  const [displayNavbar, setdisplayNavbar] = useState(true);
  const [displayNavbarClass, setdisplayNavbarClass] = useState("");
  const [displayFooter, setDisplayFooter] = useState(true);

  const { frontendData } = useSelector(state => state.frontendBoot);
  const { proposerDetails } = useSelector(state => state.greetingPage);

  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    dispatch(fetchFrontendData()).then(() => {
      if (pathname === "/") setIsLoading(false);
      if (pathname.slice(0, 8) === "/compare") {
       dispatch(setShoutGetCompare(true))
      }
      if (pathname !== "/") {
       dispatch(setShouldFetchQuotes(true))
      }
      if (pathname !== "/" && Object.keys(proposerDetails || {}).length < 1) {
        Promise.allSettled([
          dispatch(getCart()),
          dispatch(getProposerDetails()),
          
        ]).then(() => setIsLoading(false));
      }
    });
  }, []);

  useEffect(() => {
    if (
      pathname.slice(0, 8) === "/compare" ||
      pathname.includes("/proposal") ||
      pathname.includes("/productdetails")
    ) {
      setdisplayNavbarClass("hideOnMobile");
    } else if (pathname.slice(0, 7) === "/quotes") {
      setdisplayNavbarClass("showOnDesktop");
    } else {
      setdisplayNavbarClass("");
    }
  }, [pathname]);


 

  if (isLoading)
    return (
      <>
        <Navbar displayNavbar={true} className={displayNavbarClass} />
        <div style={{ padding: "12px", height: "80vh" }}>
          <CardSkeletonLoader noOfCards={3} />
        </div>
        <Footer displayFooter={true} />
      </>
    );

  return Object.keys(frontendData || {}).length > 0 ? (
    <div className="App">
      <Switch>
        <Route exact path="/recommendedMore" component={RecommendedMore} />
        <Route exact path="/recommend/:groupCode" component={RecommendedPage} />
        <Route
          exact
          path="/recommendation-result/:groupCode"
          component={RecommendationResult}
        />
        <div style={{ height: "80vh" }}>
          <Navbar
            displayNavbar={displayNavbar}
            className={displayNavbarClass}
          />
          <div style={{ minHeight: "100%" }}>
            <Route exact path="/proposal_summary" component={ProposalSummary} />
            <Route exact path="/proposal" component={ProposalPage} />
            {/* <Route exact path="/tempquotes" component={TQuotesPage} /> */}
            <Route exact path="/eligibility" component={EligibilityPage} />

            <Route exact path="/compare/:groupCode" component={ComparePage} />

            <Route exact path="/quotes/:groupCode" component={QuotesPage} />
            <Route
              exact
              path="/productdetails/:groupCode"
              component={ProductDetails}
            />
            <Route exact path="/thankyou" component={ThankYouPage} />
            <Route exact path="/generator" component={Generator} />
            <Route exact path="/" component={GreetingPage} />
          </div>

          <Footer displayFooter={displayFooter} />
        </div>
        <Redirect to="/" />
      </Switch>

      <ScrollToTop />
    </div>
  ) : (
    <>
      <Navbar displayNavbar={true} />
      <div style={{ padding: "12px", height: "80vh" }}>
        <CardSkeletonLoader noOfCards={3} />
      </div>
      <Footer displayFooter={true} />
    </>
  );
};

export default App;