import React, { useState, useEffect, Fragment } from "react";
import "../EligibilityPage.scss";

import styled from "styled-components";
const Toggle = ({ name, value, error, onChange, updateChecked }) => {
  const [boolean, setBoolean] = useState("N");

  return (
    <div className="middle">
      <label>
        <input
          type="radio"
          id={`${name}_Yes`}
          name={name}
          value="Yes"
          checked={Boolean(value)}
          onChange={e => {
            updateChecked && updateChecked(e.target.checked && true);
          }}
        />

        <div className="front-end box small-toggle">
          <span className="toggle-font" htmlFor={`${name}_Yes`}>
            YES
          </span>
        </div>
      </label>
      <label>
        <input
          type="radio"
          id={`${name}_No`}
          value="No"
          name={name}
          checked={Boolean(!value)}
          onChange={e => {
            updateChecked && updateChecked(e.target.checked && false);
          }}
        />

        <div className="front-end box small-toggle">
          <span className="toggle-font" htmlFor={`${name}_No`}>
            NO
          </span>
        </div>
      </label>

      {<p className="formbuilder__error">{error}</p>}
    </div>
  );
};

export default Toggle;
const ToggleContainer = styled.div``;
