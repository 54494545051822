import {
  EditMembersWrap,
  LogoWrap,
  Title,
  CloseButton,
  CloseImage,
  StyledDropDownToggle,
  StyledDropDownMenu,
  StyledDropDownItem,
} from "./EditMembers.styles";
import close from "../../../../../assets/svg/icon43.svg";
import { Dropdown } from "react-bootstrap";
import { useEffect, useState } from "react";
import styled from "styled-components";
import { Quantity } from "../../../../../components/Quantity/Quantity";

function EditMembers({ onClose = () => {} }) {
  return (
    <EditMembersWrap>
      <LogoWrap>
        <Title>Edit Members</Title>
        <CloseButton onClick={() => onClose()}>
          <CloseImage src={close} alt="close" />
        </CloseButton>
      </LogoWrap>
      <MembersList>
        <MemberWithDropDown id={"self"} label={"Self"} />
        <MemberWithDropDown id={"spouse"} label={"Spouse"} />
        <MemberWithQuantityList id={"son"} label={"Son"} />
        <MemberWithQuantityList id={"daughter"} label={"Daughter"} />
        <MemberWithDropDown id={"father"} label={"Father"} />
        <MemberWithDropDown id={"mother"} label={"Mother"} />
        <MemberWithDropDown id={"grandMother"} label={"Grand Father"} />
        <MemberWithDropDown id={"grandFather"} label={"Grand Mother"} />
        <MemberWithDropDown id={"fatherInLaw"} label={"Father-in-law"} />
        <MemberWithDropDown id={"motherInLaw"} label={"Mother-in-law"} />
      </MembersList>
      <UpdateButton className="theme-button-two mar_edit_l">
        Update
      </UpdateButton>
    </EditMembersWrap>
  );
}

function MemberWithDropDown(props) {
  return (
    <Member {...props}>
      <EditMembersDropDown />
    </Member>
  );
}

function MemberWithQuantityList({ id, label }) {
  const [list, setList] = useState([]);
  const [count, setCount] = useState(0);
  const [isChecked, setIsChecked] = useState(false);
  const handleIncrement = () => {
    if (!isChecked) setIsChecked(true);
    setList(prevList => {
      const newMember = {
        id: `${id}${
          prevList.length ? parseInt(prevList.slice(-1)[0].id.slice(-1)) + 1 : 1
        }`,
        label: `${label}${prevList.length + 1}`,
      };
      return [...prevList, newMember];
    });
  };
  const handleDecrement = count => {
    if (!count) {
      setList([]);
      setIsChecked(false);
      return;
    }
    setList(prevList => prevList.slice(prevList.length * -1 + 1));
  };
  return (
    <div style={{ width: "100%" }}>
      <MemberWithQuantity
        onIncrement={handleIncrement}
        onDecrement={handleDecrement}
        id={id}
        label={label}
        onChange={count => setCount(count)}
        value={count}
        handleMemberCheckboxChange={checked => {
          if (checked && count === 0) {
            handleIncrement();
            setCount(1);
            return;
          }
          if (!checked) {
            setList([]);
            setCount(0);
            setIsChecked(false);
          }
        }}
        checked={isChecked}
      />
      {list.map(member => (
        <MemberWithDropDown
          key={member.id}
          id={member.id}
          label={member.label}
          displayCheckbox={false}
        />
      ))}
    </div>
  );
}

function MemberWithQuantity(props) {
  return (
    <Member {...props}>
      <Quantity {...props} style={{ width: "123px", height: "37px" }} />
    </Member>
  );
}

const UpdateButton = styled.button`
  &.theme-button-two {
    margin: 10px 0;
    &::before {
      position: static;
      display: none;
    }
  }
`;

function EditMembersDropDown() {
  const [s, ss] = useState("Select");
  return (
    <Dropdown style={{ margin: 0 }}>
      <StyledDropDownToggle variant="custom" id="dropdown-basic">
        {s}
      </StyledDropDownToggle>

      <StyledDropDownMenu>
        <StyledDropDownItem onClick={() => ss("Action")}>
          Action
        </StyledDropDownItem>
        <StyledDropDownItem onClick={() => ss("Test")}>Test</StyledDropDownItem>
        <StyledDropDownItem onClick={() => ss("A")}>A</StyledDropDownItem>
      </StyledDropDownMenu>
    </Dropdown>
  );
}

export function EditMembersCheckbox({
  id,
  label,
  checked = false,
  displayCheckbox = true,
  handleMemberCheckboxChange = () => {},
  ...otherProps
}) {
  const [isChecked, setIsChecked] = useState(checked);
  useEffect(() => {
    setIsChecked(checked);
  }, [checked]);
  useEffect(() => {
    handleMemberCheckboxChange(isChecked);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isChecked]);
  return (
    <EditMembersCheckboxWrap className="label_edit_plan" {...otherProps}>
      {displayCheckbox && (
        <input
          className="inp-cbx"
          id={id}
          readOnly
          type="checkbox"
          checked={isChecked}
          onChange={evt => {
            setIsChecked(evt.target.checked);
          }}
        />
      )}
      <EditMembersCheckboxLabel className="cbx" htmlFor={id}>
        <CheckboxOutline
          style={{ visibility: displayCheckbox ? "visible" : "hidden" }}
        >
          {isChecked && (
            <svg width="12px" height="10px">
              <use xlinkHref="#check">
                <svg id="check" viewBox="0 0 12 10">
                  <polyline points="1.5 6 4.5 9 10.5 1"></polyline>
                </svg>
              </use>
            </svg>
          )}
        </CheckboxOutline>
        {/* <span className="checkbox-text">{label}</span> */}
        {label}
      </EditMembersCheckboxLabel>
    </EditMembersCheckboxWrap>
  );
}

function Member(props) {
  return (
    <MemberWrap>
      <EditMembersCheckbox {...props} />
      {props.children}
    </MemberWrap>
  );
}

const MembersList = styled.ul`
  width: 100%;
  padding: 0 16px;
  margin-top: 16px;
`;

const MemberWrap = styled.li`
  width: 100%;
  padding: 12px 3px;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const EditMembersCheckboxWrap = styled.div`
  &.label_edit_plan {
    position: static;
    margin: 0;
  }
`;

const EditMembersCheckboxLabel = styled.label`
  &.cbx {
    display: flex;
    align-items: center;
    width: 137px;
  }
  & .checkbox-text {
    margin-top: 3px;
    font-size: 13px !important;
  }
`;

const CheckboxOutline = styled.span`
  border-radius: 50% !important;
  margin: 0 10px 4px 0;
`;

export default EditMembers;
