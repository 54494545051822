import React, { useEffect, useRef, useState } from "react";
import { Col, Container, Modal, Row } from "react-bootstrap";
import styled from "styled-components/macro";
import {
  dataSet,
  getAge,
} from "../../../GreetingFormCard/fieldsets/data/Fieldset3Data/Data";
// import {
//   age,
//   modalFamilyMember,
// } from "../../../GreetingFormCard/fieldsets/data/Fieldset3Data/Data";
import StyledButton from "../../Button/StyledButton";
import Form3CheckBox from "../../CheckBox/Form3CheckBox";
import GreetingFormDropdown from "../../Dropdown/GreetingForm/GreetingFormDropdown";
import "./GreetingFormModal.scss";

const GreetingFormModal = ({
  modalTitle,
  show,
  setShow,
  membersArray,
  handleInput,
  setMembersArray,
  insurerCBXArray,
  insurerDDArray,
  editPopup,
  ageError,
}) => {
  const handleClose = () => setShow(false);

  const modalDataset = () => {
    return (
      <>
        {membersArray &&
          membersArray.map(
            ({
              display_name,
              min_age,
              max_age,
              is_primary,
              hasClose,
              code,
              is_primary_modal,
            }) => {
              const age = getAge(min_age, max_age);
              return (
                (!is_primary || is_primary_modal) &&
                dataSet(
                  display_name,
                  age,
                  insurerCBXArray,
                  insurerDDArray,
                  handleInput,
                  hasClose,
                  code,
                  ageError,
                )
              );
            },
          )}
      </>
    );
  };

  const initRef = useRef(0);

  useEffect(() => {
    if (initRef.current === 1) {
      const tempArray = membersArray.map(data => {
        if (!data.is_primary && insurerCBXArray.includes(data.display_name)) {
          return {
            [`code`]: data.code,
            [`display_name`]: data.display_name,
            [`min_age`]: data.min_age,
            [`max_age`]: data.max_age,
            ["is_primary"]: true,
          };
        } else {
          return data;
        }
      });
      setMembersArray(tempArray);
    }
    initRef.current += 1;
  }, [membersArray]);

  const handleClick = () => {
    const tempArray = membersArray.map(data => {
      if (!data.is_primary && insurerCBXArray.includes(data.code)) {
        return {
          [`code`]: data.code,
          [`display_name`]: data.display_name,
          [`min_age`]: data.min_age,
          [`max_age`]: data.max_age,
          ["is_primary"]: true,
          ["is_primary_modal"]: true,
        };
      } else {
        return data;
      }
    });
    setMembersArray(tempArray);
    handleClose();
  };

  return (
    <Modal
      centered
      show={show}
      onHide={handleClose}
      animation={false}
      style={{
        zIndex: "2000",

        border: "none",
        overflow: "hidden",
      }}
      className={"greetings-form__modal"}
    >
      <Modal.Header
        style={{
          borderBottomColor: "#fff",
          borderTopLeftRadius: "14px",
          borderTopRightRadius: "14px",
          borderBottomRightRadius: "0px",
          borderBottomLeftRadius: "0px",
        }}
      >
        <ModalTitle>{modalTitle}</ModalTitle>
      </Modal.Header>
      <CloseButton
        type="button"
        className="btn btn-white recom_close_css"
        style={{ marginTop: "-8px" }}
        onClick={handleClose}
      >
        <i className="fa fa-close"></i>
      </CloseButton>
      <Modal.Body
      // style={{ position: "relative", left: "17px", width: "672px" }}
      >
        <Container>
          <Row>{modalDataset()}</Row>
        </Container>
      </Modal.Body>
      <Modal.Footer
        style={{
          borderBottomColor: "#fff",
          borderTopLeftRadius: "0px",
          borderToprightRadius: "0px",
          borderBottomRightRadius: "14px",
          borderBottomLeftRadius: "14px",
        }}
      >
        {editPopup ? (
          <button
            css={`
              height: 46px;
              width: 170px;
              background-color: var(--abc-red);
              color: #fff;
              margin: 10px auto 30px;
              border-radius: 2px;
            `}
            onClick={handleClick}
            laptopLg
            mobileSm
          >
            Apply
          </button>
        ) : (
          <StyledButton
            type="button"
            value={"Add Members"}
            onClick={handleClick}
            noIcon={true}
            mobileWidth
          />
        )}
      </Modal.Footer>
    </Modal>
  );
};

export default GreetingFormModal;

const CloseButton = styled.button`
  position: absolute;
  top: 20px;
  right: 20px;
  @media (max-width: 767px) {
    top: 21px;
    display: block !important;
  }
`;
const ModalTitle = styled.h5`
  // font-family: "PFEncoreSansProblck";
  margin: 0 17px;
  margin-bottom: 0;
  line-height: 1.5;
  color: #000;
  font-size: 23px;
  font-weight: 900;
  &:after {
    content: "";
    height: 9%;
    width: 7px;
    position: absolute;
    left: 0px;
    top: 13px;
    background-color: #fecc28;
    border-radius: 50px;
  }
  @media (max-width: 767px) {
    margin: 0 7px;
    color: white;
    &:after {
      display: none;
    }
  }
`;
