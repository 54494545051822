import React, { useState } from "react";
import { useSelector } from "react-redux";
import BackIcon from "../../components/BackIcon/BackIcon";
import { Label } from "../../components/FormComponents/FormComponents";
import StyledButton from "../../components/Button/StyledButton";
import styled from "styled-components/macro";
import {
  UnorderedList,
  RadioInput,
  RadioLabel,
  RadioLabelImage,
} from "../../components/Radio/FamilyRadio";
import { familyFloaterRadioArr } from "../fieldsets/data/fieldSet4Data/data";
import { forthFormSchema } from "../../../../utils/validationSchema";
import "../../components/RadioButtons/GreetingsPage/FamiltyFloaterRadio.scss";
import check from "../../../../assets/images/checkmark.png";
import wrong from "../../../../assets/images/wrong.png";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers";
import { ErrorMessage } from "../../components/TextInput/GreetingPage/GreetingTextInput";
import {
  saveForm2UserDetails,
  saveForm4UserDetails,
} from "../../reducer/greetingPage.slice";
import { useDispatch } from "react-redux";
import BackBtn from "../../components/Button/BackBtn";
import { Alert } from "react-bootstrap";
import backButton from "../../../../assets/images/backbutton.png";

const Fieldset4 = ({ currentForm, handleChange, value }) => {
  const { frontendData } = useSelector(state => state.frontendBoot);

  const { data } = frontendData || [""];
  const { plantypes, description } = data || [""];

  const dispatch = useDispatch();
  const [selected, setSelected] = useState("F");
  const [selectedText, setSelectedText] = useState("F");

  const { register, handleSubmit, errors } = useForm({
    resolver: yupResolver(forthFormSchema),
    mode: "onChange",
  });

  const onSubmit = data => {
    handleChange("form5");
    dispatch(saveForm4UserDetails(data));
  };

  return (
    <fieldset style={{ display: currentForm === value ? "block" : "none" }}>
      <div>
        <BackIcon onClick={() => handleChange("form3")} />
      </div>
      <a
        onClick={() => handleChange("form3")}
        className="showOnMObile"
        css={`
          display: none !important;
          @media (max-width: 767px) {
            display: inline-block !important;
            height: 32px;
            z-index: 999;
            margin-left: -12px;
          }
        `}
      >
        <img
          src={backButton}
          css={`
            height: 58px;
          `}
        />
      </a>
      <Label
        css={`
          padding-bottom: 19px !important;
        `}
      >
        Which plan would you like to opt for?
      </Label>
      <form autoComplete="off" noValidate onSubmit={handleSubmit(onSubmit)}>
        <Page>
          <div className="pcss3t pcss3t-effect-scale pcss3t-theme-1">
            <>
              {plantypes &&
                plantypes.map(({ code, display_name }, i) => {
                  return (
                    code !== "I" && (
                      <div
                        onMouseEnter={e => setSelectedText(code)}
                        onClick={e => setSelected(code)}
                        key={i}
                        style={{ display: "inline" }}
                      >
                        <RadioInput
                          type="radio"
                          name={"planType"}
                          id={display_name}
                          value={code}
                          checked={selected === code || false}
                          onChange={e => setSelected(code)}
                          ref={register}
                        />
                        <RadioLabel
                          css={`
                            @media (max-width: 767px) {
                              margin-bottom: ${code === "M" &&
                              "10px !important"};
                            }
                          `}
                          htmlFor={display_name}
                          itemsCentered={true}
                        >
                          {selected === code && <RadioLabelImage src={check} />}
                          <span> {display_name}</span>
                        </RadioLabel>
                      </div>
                    )
                  );
                })}

              <UnorderedList>
                {plantypes &&
                  plantypes.map(({ code, description, display_name }, i) => {
                    return (
                      <li
                        className={`typography ${
                          selectedText === code && "active"
                        }`}
                      >
                        <h1>What is {display_name}?</h1>
                        <p>{description}</p>
                      </li>
                    );
                  })}
              </UnorderedList>
            </>
          </div>
        </Page>
        {Object.keys(errors)[0] && (
          <Alert variant="danger" style={{ fontSize: "15px" }}>
            {errors[Object.keys(errors)[0]].message}
          </Alert>
        )}
        <span
          css={`
            @media (max-width: 767px) {
              display: flex;
            }
          `}
        >
          <StyledButton value={"Continue"} type="submit" laptopLg mobileSm />
        </span>
      </form>
    </fieldset>
  );
};

export default Fieldset4;

const Page = styled.div`
  position: relative;
  max-width: 980px;
  margin: 0 -44px;
  @media (max-width: 767px) {
    width: 100%;
  }
`;
