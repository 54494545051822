import BackgroundImg from "../../../assets/images/bg_search.png";
import { useHistory, useParams } from "react-router-dom";
import CloseBtn from "../components/closeBtn/CloseBtn";
import useUrlQuery from "../../../customHooks/useUrlQuery";
import { useEffect } from "react";
import { getRecommendationQuestions } from "../serviceApi";
import { useDispatch, useSelector } from "react-redux";
import {
  setAnswers,
  setQuestions,
  setSelectedHospitals,
} from "../recommendedPage.slice";
import "styled-components/macro";
import { CloseButton } from "../../Cart/components/SidebarCart/SidebarCart";
import * as mq from "../../../utils/mediaQueries";

const MainPage = ({ currentForm, handleChange, value }) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { groupCode } = useParams();
  const handleClick = e => {
    e.preventDefault();
    dispatch(setAnswers({ groupCode, answers: {} }));
    dispatch(setSelectedHospitals({ groupCode, selectedHospitals: [] }));
    handleChange("form2");
  };

  const urlQuery = useUrlQuery();
  const enquiryId = urlQuery.get("enquiryId");

  useEffect(() => {
    getRecommendationQuestions({ groupCode }).then(res => {
      dispatch(
        setQuestions(
          res.data.map(question => {
            if (question.type === "multiselect") {
              return {
                ...question,
                options: question.options.map(option => Object.keys(option)[0]),
              };
            }
            return question;
          }),
        ),
      );
    });
  }, [dispatch, groupCode]);

  const name = useSelector(state => state.greetingPage.proposerDetails.name);
  const firstName = name ? name.split(" ")[0] : "";

  return (
    <>
      <div
        className="modal bg-white show"
        style={{
          display: currentForm === value ? "block" : "none",
          overflow: "auto",
        }}
        css={`
          ${mq.mobile} {
            display: none !important;
          }
        `}
      >
        <div className="about_curve1 hidden-xs bg-img">
          <img src={BackgroundImg} alt="" />{" "}
        </div>
        <div
          style={{
            height: "100vh",
            textAlign: "center",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <div>
            <h5 className="re_h5">
              Hey{" "}
              <span
                css={`
                  text-transform: capitalize;
                `}
              >
                {firstName},
              </span>{" "}
              Let us help you find you a best plan
              <br /> based on your requirements.{" "}
            </h5>
            <br />
            <p className="re_start">
              Answer a few questions and you are done. <br />
              It's that Simple!
            </p>
          </div>
          <div className="modal-footer border_none bg_transparent">
            <CloseBtn
              customClassName="fixed_back_btn_recommend"
              onClick={() =>
                history.push(`/quotes/${groupCode}?enquiryId=${enquiryId}`)
              }
            />
            <button
              css={`
                background-color: var(--abc-red);
                color: #fff;
                width: 300px;
                height: 72px;
                border-radius: 2px;

                font-size: 21px;
                font-weight: 900;
              `}
              onClick={handleClick}
            >
              Let's Start
            </button>
          </div>
        </div>
      </div>
      <MainPageMobile
        history={history}
        enquiryId={enquiryId}
        groupCode={groupCode}
        handleClick={handleClick}
        firstName={firstName}
      />
    </>
  );
};

export default MainPage;

function MainPageMobile({
  history,
  groupCode,
  enquiryId,
  handleClick,
  firstName,
}) {
  return (
    <div
      css={`
        position: relative;
        min-height: 100vh;
        width: 100vw;
        align-items: center;
        justify-content: center;
        display: none;
        ${mq.mobile} {
          display: flex;
        }
      `}
    >
      <div className="about_curve1 hidden-xs bg-img">
        <img src={BackgroundImg} alt="" />
      </div>
      <div
        css={`
          max-width: 1200px;
          width: 90%;
          margin: auto;
        `}
      >
        <div
          css={`
            position: absolute;
            right: 10px;
            top: 10px;
          `}
        >
          <CloseButton
            onClick={() =>
              history.push(`/quotes/${groupCode}?enquiryId=${enquiryId}`)
            }
          />
        </div>
        <h1
          css={`
            text-align: center;
            ${mq.mobile} {
              font-size: 27px;
              font-weight: 900;
            }
          `}
        >
          Hey{" "}
          <span
            css={`
              text-transform: capitalize;
            `}
          >
            {firstName}
          </span>
          , Let us help you find you a best plan based on your requirements.
        </h1>
        <p
          css={`
            color: #9497b8;
            text-align: center;
            font-size: 16px;
            margin-top: 10px;
          `}
        >
          Answer a few questions and you are done. <br />
          It's that Simple!
        </p>
        <button
          css={`
            background-color: var(--abc-red);
            color: #fff;
            width: 160px;
            height: 60px;
            border-radius: 2px;
            /* box-shadow: rgb(255 30 56 / 25%) 0px 10px 20px; */
            font-size: 18px;
            font-weight: 900;
            margin: auto;
            margin-top: 30px;

            /* ${mq.small} {
              width: 200px;
              height: 60px;
            } */
          `}
          onClick={handleClick}
        >
          Let's Start
        </button>
      </div>
    </div>
  );
}
