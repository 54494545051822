import React, { useCallback, useEffect, useRef, useState } from "react";
import { debounce } from "lodash";
import { Row } from "react-bootstrap";
import "styled-components/macro";
import {
  selectSelectedHospitals,
  setSelectedHospitals,
} from "../../recommendedPage.slice";
import image from "../../../../assets/images/hospital.png";
import checkMark from "../../../../assets/images/checkmarkRadio.png";
import {
  Title,
  ContinueButton,
} from "../../components/fieldsetComponents/fieldsetComponents";
import { getCityList, searchNetworkHospital } from "../../serviceApi";
import { useDispatch, useSelector } from "react-redux";
import CloseBtn from "../../components/closeBtn/CloseBtn";
import { v4 as uuid } from "uuid";
import { useParams } from "react-router";

import SearchIcon from "../../../../assets/images/searchInput.png";
import * as mq from "../../../../utils/mediaQueries";
import GreetingFormDropdown from "../../../GreetingPage/components/Dropdown/GreetingForm/GreetingFormDropdown";
import { state } from "../../city-list";
import styled from "styled-components/macro";
import useOutsiteClick from "../../../../customHooks/useOutsiteClick";
import { getCityForPincode } from "../../../../FrontendBoot/serviceApi/frontendBoot";

function HospitalChip({ hospital = {}, handleRemove = () => {}, ...props }) {
  const { hospital_name: label } = hospital;
  const handleRemoveClick = () => handleRemove(hospital);
  return (
    <div
      css={`
        padding: 3px 6px;
        background-color: var(--dark-pink);
        max-width: 160px;
        width: max-content;
        display: flex;
        align-items: center;
        justify-content: space-between;
        border-radius: 30px;
        color: #fff;
        margin-right: 0.3rem;
      `}
      {...props}
    >
      <span
        css={`
          margin-right: 0.6em;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
        `}
        title={label}
      >
        {label}
      </span>
      <button
        css={`
          color: #fff;
          line-height: 1;
        `}
        onClick={handleRemoveClick}
      >
        <i className="fas fa-times-circle"></i>
      </button>
    </div>
  );
}

const visuallyHidden = `
  clip: rect(1px, 1px, 1px, 1px);
  clip-path: inset(50%);
  height: 1px;
  width: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
`;

const listItemStyle = `
position: relative;
padding: 10px;
border-radius: 6px;
width: 100%;
background-color: #fff;
cursor: pointer;
&:hover {
  background-color: var(--light-pink);
}
`;

function ListItem({ checked, onChange = () => {}, hospital }) {
  const { hospital_name, phone: id, pincode } = hospital;
  const label = `${hospital_name} - ${pincode}`;
  const handleChange = () => {
    onChange(!checked, hospital);
  };
  return (
    <div>
      <label
        onClick={handleChange}
        htmlFor={id}
        css={`
          position: relative;
          padding: 10px;
          border-radius: 6px;
          width: 100%;
          background-color: #fff;
          cursor: pointer;
          &:hover {
            background-color: var(--light-pink);
          }

          &::after {
            width: 18px;
            height: 18px;
            content: "";
            border: 1px solid var(--dark-pink);
            background-color: #fff;
            background-image: ${checked ? `url(${checkMark})` : ""};
            background-size: 10px 8px;
            background-repeat: no-repeat;
            background-position: 50% 50%;
            border-radius: 3px;
            z-index: 2;
            position: absolute;
            right: 10px;
            top: 50%;
            -webkit-transform: translateY(-50%);
            -ms-transform: translateY(-50%);
            transform: translate(-50%, -50%);
            cursor: pointer;

            ${mq.mobile} {
              right: 1%;
            }
          }

          ${mq.mobile} {
            font-size: 13px;
            padding-right: 37px;
          }
        `}
      >
        {label}
      </label>
      <input
        type="checkbox"
        checked={checked}
        readOnly
        id={id}
        css={`
          ${visuallyHidden}
        `}
      />
    </div>
  );
}

const cities = Object.keys(state)
  .reduce((cities, s) => {
    return [...cities, ...state[s]];
  }, [])
  .sort();

function CityDropDown({
  selected,
  optionsList = [],
  onChange = () => {},
  ...props
}) {
  const [isOpen, setIsOpen] = useState(false);
  const toggleOpen = () => setIsOpen(!isOpen);
  // const open = () => setIsOpen(true);

  const [options, setOptions] = useState(optionsList);

  useEffect(() => setOptions(optionsList), [optionsList]);

  const [search, setSearch] = useState("");

  const close = () => {
    setSearch("");
    setOptions(optionsList);
    setIsOpen(false);
  };

  const handleSearch = evt => {
    const searchTerm = evt.target.value;
    setSearch(searchTerm);
    const filteredOptions = optionsList.filter(option =>
      option.toLowerCase().startsWith(searchTerm.toLowerCase()),
    );
    setOptions(filteredOptions);
  };

  const dropdownRef = useRef(null);

  useOutsiteClick(dropdownRef, close);

  const handleOptionClick = city => {
    onChange(city);
    close();
  };

  const selectedOptionRef = useRef(null);

  useEffect(() => {
    if (isOpen && selectedOptionRef.current) {
      selectedOptionRef.current.scrollIntoView();
    }
  }, [selected, isOpen]);

  return (
    <div
      {...props}
      css={`
        margin-right: 1rem;
        align-self: stretch;
        position: relative;

        /* margin-top: 20px; */
        /* & label {
          height: 100%;
        } */
      `}
      ref={dropdownRef}
    >
      <button
        css={`
          display: flex;
          align-items: center;
          justify-content: space-between;
          font-size: 21px;
          width: 171px;
          height: 100%;
          padding-left: 1rem;
          & .fa {
            font-weight: 900;
          }
        `}
        onClick={toggleOpen}
      >
        <p
          title={selected}
          css={`
            text-overflow: ellipsis;
            white-space: nowrap;
            overflow: hidden;
          `}
        >
          {selected || "Select"}
        </p>
        <i className={`fa fa-angle-${isOpen ? "up" : "down"}`} />
      </button>

      {isOpen ? (
        <DropDownOptionsContainer>
          <input value={search} onChange={handleSearch} placeholder="Search" />
          <ul
            css={`
              overflow: auto;
              max-height: 210px;
              margin-top: 1em;
            `}
          >
            {options.map((option, idx) => (
              <li
                onClick={() => handleOptionClick(option)}
                key={option + idx}
                title={option}
                css={`
                  & ${DropDownOption} {
                    background-color: ${selected === option
                      ? "var(--light-pink)"
                      : "#fff"};
                    &:hover {
                      background-color: var(--light-pink);
                    }
                  }
                `}
                ref={selected === option ? selectedOptionRef : null}
              >
                <DropDownOption
                  css={`
                    white-space: nowrap;
                    overflow: hidden;
                    text-overflow: ellipsis;
                  `}
                >
                  {option}
                </DropDownOption>
              </li>
            ))}
          </ul>
        </DropDownOptionsContainer>
      ) : null}

      {/* <GreetingFormDropdown
        title={"City"}
        list={Object.keys(state).map((city, idx) => ({
          id: idx,
          title: city,
        }))}
        type={"dropdown"}
        handleChange={() => {}}
        selected={"Select City"}
        css={`
          & .GreetingDD__Header {
            margin: 0;
            height: 100%;
            border-radius: 0px;
            box-shadow: none;
            border: none;
            background: transparent;
            display: flex;
            align-items: center;

            & .GreetingDD__Title {
              position: relative;
              top: 50%;
              transform: translate(0, -50%);
              font-size: 21px;

              &::after {
                transform: translate(0, -50%) rotate(45deg) scale(1.2);
                right: 0;
              }
            }
          }
          & .GreetingDD__Wrapper {
            top: 0;
            left: 0;
            height: 100%;
          }
          & .GreetingDD__List {
            width: 100% !important;
            margin: 0;
          }
        `}
      /> */}
    </div>
  );
}

const isDigits = string => string.match(/^[0-9]+$/);

function PincodeInput({ onPincodeChange = () => {}, ...props }) {
  const [pincode, setPincode] = useState("");
  const handlePincodeChange = evt => {
    const { value: enteredPincode } = evt.target;
    if (enteredPincode && !enteredPincode.match(/^[0-9]+$/)) return;
    setPincode(enteredPincode);
    if (enteredPincode.length === 6) onPincodeChange(enteredPincode);
  };
  return (
    <input
      css={`
        flex: 1;
        position: relative;
        display: flex;
        align-items: center;
        background-color: #fff;
        box-shadow: 1px 6px 4px 1px #eaf0f4;
        border: 1px solid #edeef5;
        border-radius: 20px;
        height: 94px;
        padding: 0 60px 0 30px;
        margin-top: 20px;
        color: var(--font-gray-six);
        margin-right: 10px;
        font-size: 21px;
        &:focus {
          border: 1px solid var(--dark-pink);
        }
      `}
      placeholder="Enter pincode"
      value={pincode}
      onChange={handlePincodeChange}
      maxLength={6}
      {...props}
    />
  );
}

function SearchHospital() {
  const dispatch = useDispatch();

  const { groupCode } = useParams();

  const [preferedHospital, setPreferedHospital] = useState("");

  const [hospitalsList, setHospitalsList] = useState([]);

  const selectedHospitals = useSelector(selectSelectedHospitals(groupCode));

  const [loading, setLoading] = useState(false);

  const [pincode, setPincode] = useState("");

  const { city: cityFromGreetingPage, pincode: pincodeFromGreetingPage } =
    useSelector(state => state.greetingPage.proposerDetails);

  const [city, setCity] = useState(cityFromGreetingPage || "Select City");

  useEffect(() => {
    if (!cityFromGreetingPage) {
      getCityForPincode({ pincode: pincodeFromGreetingPage }).then(res =>
        setCity(res.data?.city || "Select City"),
      );
    }
  }, [cityFromGreetingPage, pincodeFromGreetingPage]);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debouncedSave = useCallback(
    debounce(
      ({ hospitalName, city }) =>
        searchNetworkHospital({ city, hospitalName })
          .then(res => {
            setHospitalsList(res.data);
          })
          .finally(() => setLoading(false)),
      300,
    ),
    [],
  );

  const handleChange = event => {
    const { value } = event.target;
    // if (value && !isDigits(value)) return;
    setPreferedHospital(value);
  };

  useEffect(() => {
    if (preferedHospital || pincode) {
      if (preferedHospital.trim().length > 2 || pincode) {
        setLoading(true);
        debouncedSave({
          hospitalName: preferedHospital,
          city,
        });
        return;
      }
      debouncedSave.cancel();
      setHospitalsList([]);
      setLoading(false);
    }
  }, [debouncedSave, preferedHospital, pincode, city]);

  const addHospital = hospital => {
    if (selectedHospitals.length < 4) {
      dispatch(
        setSelectedHospitals({
          groupCode,
          selectedHospitals: [...selectedHospitals, hospital],
        }),
      );
    }
  };

  const removeHospital = hospital => {
    dispatch(
      setSelectedHospitals({
        groupCode,
        selectedHospitals: selectedHospitals.filter(
          selectedHospital =>
            selectedHospital.hospital_name !== hospital.hospital_name &&
            selectedHospital.pincode !== hospital.pincode,
        ),
      }),
    );
  };

  const searchFieldRef = useRef(null);

  const handleHospitalChange = (checked, hospital) => {
    if (checked) {
      addHospital(hospital);
    } else {
      removeHospital(hospital);
    }
    setHospitalsList([]);
    setPreferedHospital("");
    if (searchFieldRef.current) {
      searchFieldRef.current.focus();
    }
  };

  const [options, setOptions] = useState([]);

  useEffect(() => {
    getCityList().then(res => {
      const cities = res.data.map(obj => obj.city);
      setOptions(cities);
    });
  }, []);

  return (
    <div
      className={"search__container"}
      css={`
        display: flex;
        align-items: center;
        max-width: 100%;
        box-shadow: 1px 6px 4px 1px #eaf0f4;
        border: 1px solid #edeef5;
        border-radius: 20px;
        background-color: #fff;
        /* overflow: hidden; */
        ${mq.mobile} {
          margin: auto;
        }
      `}
    >
      <CityDropDown selected={city} onChange={setCity} optionsList={options} />
      <div
        css={`
          height: 70px;
          width: 1px;
          background: #ddd;
        `}
      />
      {/* <PincodeInput onPincodeChange={pincode => setPincode(pincode)} /> */}
      <div
        css={`
          flex: 3;
          position: relative;
          /* margin-top: 20px; */
          max-width: 100%;

          ${mq.mobile} {
            margin: auto;
            margin-top: 20px;
          }
        `}
      >
        <div
          css={`
            display: none;
            ${mq.mobile} {
              display: flex;
              flex-flow: row wrap;
              justify-content: space-between;
              margin-bottom: 10px;
            }
          `}
        >
          {selectedHospitals.map(hospital => (
            <HospitalChip
              hospital={hospital}
              handleRemove={removeHospital}
              css={`
                margin: 10px 0;
                width: calc(50% - 6px);
                font-size: 14px;
                max-width: 100%;
              `}
            />
          ))}
        </div>
        <div
          css={`
            position: relative;
            display: flex;
            align-items: center;
            background-color: #fff;
            /* box-shadow: 1px 6px 4px 1px #eaf0f4;
            border: 1px solid #edeef5;
            border-radius: 20px; */
            height: 94px;
            padding: 0 60px 0 30px;
            overflow: hidden;
            border-radius: 20px;

            &:focus-within {
              border: 1px solid var(--dark-pink);
              & .search-icon {
                display: none;
              }
            }

            ${mq.mobile} {
              height: 60px;
              border-radius: 12px;
              padding: 0 30px 0 15px;
              display: ${selectedHospitals.length < 4 ? "flex" : "none"};
            }
          `}
        >
          {selectedHospitals.map(hospital => (
            <div
              css={`
                ${mq.mobile} {
                  display: none;
                }
              `}
            >
              <HospitalChip
                hospital={hospital}
                handleRemove={removeHospital}
                style={{ width: "100px" }}
              />
            </div>
          ))}
          <div
            css={`
              margin-right: 0.3rem;
              min-width: max-content;

              ${mq.mobile} {
                width: 20px;
                min-width: 0px;
              }
            `}
          >
            <i
              className="fa fa-search search-icon"
              css={`
                color: gray;
              `}
            />
          </div>
          {selectedHospitals.length < 4 && (
            <input
              ref={searchFieldRef}
              type="text"
              placeholder="Search upto 4 hospitals"
              // placeholder="Enter pincode or name to search, you can select upto 4 Hospitals"
              css={`
                font-size: 21px;
                flex: 1;
                border: none;
                height: 100%;
                color: var(--font-gray-six);

                ${mq.mobile} {
                  font-size: 16px;
                }
              `}
              onChange={handleChange}
              value={preferedHospital}
            />
          )}
        </div>
        {loading ? (
          <div
            css={`
              position: absolute;
              top: 50%;
              right: 0;
              transform: translate(-50%, -50%);
              display: flex;
              align-items: center;
              justify-content: center;
              & .fa-circle-notch {
                font-size: 30px;
                color: var(--dark-pink);
                ${mq.mobile} {
                  font-size: 21px;
                  right: 3%;
                  transform: translate(0, -30%);
                }
              }
              ${mq.mobile} {
                right: 3%;
                transform: translate(0, -27%);
              }
            `}
          >
            <i className="fa fa-circle-notch rotate" />
          </div>
        ) : null}
        {hospitalsList.length === 0 &&
          preferedHospital.length >= 3 &&
          !loading && (
            <DropDownOptionsWrap
              heading={
                city === "Select City"
                  ? "Please select City"
                  : `No Hospitals found for "${preferedHospital}"`
              }
              onClose={() => {
                setPreferedHospital("");
                setHospitalsList([]);
              }}
            />
          )}
        {hospitalsList.length > 0 && preferedHospital && !loading && (
          <DropDownOptionsWrap
            heading={`Showing Hospitals for "${preferedHospital}"`}
            onClose={() => {
              setPreferedHospital("");
              setHospitalsList([]);
            }}
          >
            <ul
              css={`
                overflow: auto;
                max-height: 210px;
              `}
            >
              {hospitalsList.map(hospital => (
                <li key={uuid()}>
                  <ListItem
                    checked={selectedHospitals.some(
                      selectedHospital =>
                        selectedHospital.hospital_name ===
                          hospital.hospital_name &&
                        selectedHospital.pincode === hospital.pincode,
                    )}
                    onChange={handleHospitalChange}
                    hospital={hospital}
                  />
                </li>
              ))}
            </ul>
          </DropDownOptionsWrap>
        )}
      </div>
    </div>
  );
}

const Fieldset1 = ({ currentForm, setCurrentForm, index }) => {
  const { groupCode } = useParams();

  const selectedHospitals = useSelector(selectSelectedHospitals(groupCode));

  const handleSubmit = () => {
    selectedHospitals.length > 0 && setCurrentForm(index + 1);
  };

  return (
    <>
      <fieldset style={{ display: currentForm !== index && "none" }}>
        <div
          css={`
            overflow-x: hidden;
          `}
        >
          <Row>
            <img src={image} className="img_recommend_icon" alt={image} />
            <Title
              className="fs-title font-reset"
              css={`
                ${mq.mobile} {
                  font-size: 21px;
                  margin: auto;
                  text-align: center;
                  padding: 0 30px;
                }
              `}
            >
              Tell us which hospitals do you prefer
            </Title>
          </Row>
        </div>

        <SearchHospital />

        <p
          css={`
            margin-top: 12px;
            color: #666;
          `}
        >
          You can search upto 4 network hospitals of your choice from all over
          India
        </p>

        <div
          css={`
            width: max-content;
            ${mq.mobile} {
              width: 100%;
            }
          `}
        >
          <ContinueButton
            onClick={handleSubmit}
            css={`
              margin-top: 60px;
              ${mq.mobile} {
                width: 120px !important;
                height: 3rem;
                display: flex;
                justify-content: center;
                align-items: center;
                font-size: 18px;
                border-radius: 2px;
                margin: 2rem auto;
                margin-bottom: 0.37em;
              }
              margin-bottom: 10px;
            `}
          >
            Continue
          </ContinueButton>
          <button
            onClick={() => setCurrentForm(currentForm + 1)}
            css={`
              font-size: 1.1rem;
              display: flex;
              align-items: center;
              justify-content: space-between;
              border-bottom: 1px dashed;
              line-height: 1;
              margin: auto;

              ${mq.mobile} {
                font-size: 1rem;
              }
            `}
          >
            Skip this question
            <i
              className="fa fa-angle-right"
              css={`
                margin-left: 0.2em;
                margin-bottom: 0.1em;
              `}
            />
          </button>
        </div>
      </fieldset>
    </>
  );
};

export default Fieldset1;

const DropDownOptionsContainer = styled.div`
  position: absolute;
  top: 120%;
  width: 100%;
  background-color: #fff;
  padding: 10px;
  max-height: 300px;
  border-radius: 10px;
  box-shadow: 0 8px 12px 0 rgb(16 24 48 / 12%);
`;

function DropDownOptionsWrap({
  heading,
  onClose = () => {},
  children,
  ...props
}) {
  return (
    <DropDownOptionsContainer {...props}>
      <div
        css={`
          height: 60px;
          display: flex;
          align-items: center;
          justify-content: space-between;

          & .close-drop-down {
            position: static;
            height: 36px;
            width: 36px;
            display: flex;
            align-items: center;
            justify-content: center;
            align-self: flex-start;
            margin: 0;
          }
        `}
      >
        <p
          css={`
            padding-left: 10px;
            color: #727f9f;

            ${mq.mobile} {
              font-size: 16px;
            }
          `}
        >
          {heading}
        </p>
        <CloseBtn customClassName="close-drop-down" onClick={onClose} />
      </div>
      {children}
    </DropDownOptionsContainer>
  );
}

const DropDownOption = styled.div`
  position: relative;
  padding: 10px;
  border-radius: 6px;
  width: 100%;
  background-color: #fff;
  cursor: pointer;
  &:hover {
    background-color: var(--light-pink);
  }
`;
