import React, { useEffect, useRef, useState } from "react";
import { Col, Row } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useParams } from "react-router";
import styled from "styled-components/macro";

import edit from "../../../../assets/images/edit_pencil.png";
import useOutsiteClick from "../../../../customHooks/useOutsiteClick";
import useUrlQuery from "../../../../customHooks/useUrlQuery";
import {
  fetchQuotes,
  replaceQuotes,
  saveQuotesData,
  setFilters,
  setSelectedGroup,
} from "../../quotePage.slice";
import EditMembersPopup from "../EditMembersPopup/EditMembersPopup";
import EditMembers from "./EditMembers";

const MemberTabButton = styled.button`
  border: 1px solid transparent;
`;

function PlanForTab({ handleClick = () => { }, active, members = [] }) {
  const membersText = members.join(", ").replaceAll("_", "-");
  return (
    <div
      css={`
        @media (max-width: 1128px) {
          max-width: 159px;
        }
      `}
      style={{ margin: "0 10px" }}
    >
      <MemberTabButton
        disabled={active}
        style={{
          width: "100%",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "56px",
        }}
        className={active ? "active" : ""}
        onClick={() => {
          handleClick();
        }}
      >
        <span
          style={{
            fontSize: "1vw",
            textTransform: "capitalize",
            overflow: "hidden",
            whiteSpace: "nowrap",
            textOverflow: "ellipsis",
            width: "136px",
          }}
          title={membersText}
          className="flaot_left_button fixed_font_to_16"
        >
          {membersText}
        </span>
        {/* <span
          style={{ fontSize: "1vw" }}
          className="flaot_right_button fixed_font_to_16"
        >
          9 Plans
        </span> */}
      </MemberTabButton>
    </div>
  );
}

const QuotePlanFor = ({ members }) => {
  console.log("QuotePlanFor Rendering...");
  // const [openArogyaDropdown, setOpenArogyaDropdown] = useState(false);
  const [openEditSidebar, setOpenEditSidebar] = useState(false);


  const {
    // selectedGroup,
    toggleUi,
    filters: { cover: sumAssured, ownCover, multiYear, planType, basePlanType },
  } = useSelector(state => state.quotePage);

  const { groupCode: selectedGroup } = useParams();

  const dispatch = useDispatch();

  // const groups = React.useMemo(
  //   () =>
  //     members
  //       ? members.reduce(
  //           (groups, member) => ({
  //             ...groups,
  //             [member.group]: groups[member.group]
  //               ? [...groups[member.group], member.type]
  //               : [member.type],
  //           }),
  //           {}
  //         )
  //       : null,
  //   [members]
  // );

  const { memberGroups, proposerDetails } = useSelector(
    state => state.greetingPage,
  );
  console.log("memberGroups", Object.keys(memberGroups).length)

  const { companies } = useSelector(
    state => state.frontendBoot.frontendData.data,
  );
  const companyList = Object.keys(companies);
  const { cover, tenure, plan_type } = useSelector(
    ({ frontendBoot }) => frontendBoot.frontendData.data.defaultfilters,
  );
  const { plantypes, covers } = useSelector(
    ({ frontendBoot }) => frontendBoot.frontendData.data,
  );

  // const getQuotes = (sendMembers) => {
  //   dispatch(replaceQuotes([]));
  //   companyList.map((company) =>
  //     dispatch(
  //       saveQuotesData({
  //         alias: company,
  //         type: "normal",
  //         sum_insured: ownCover
  //           ? `${ownCover}-${ownCover}`
  //           : sumAssured
  //           ? covers.filter((cover) => cover.display_name === sumAssured)[0]
  //               .code
  //           : cover,
  //         tenure: multiYear ? multiYear[0] : tenure,
  //         member: sendMembers,
  //         plan_type: planType
  //           ? plantypes.filter((plan) => plan.display_name === planType)[0].code
  //           : plan_type,
  //       })
  //     )
  //   );
  // };

  const initRef = useRef(true);

  useEffect(() => {
    if (!initRef.current)
      // getQuotes(members.filter((m) => m.group === selectedGroup));
      console.log("Plans for fetching quotes...");

      console.log('plans for fetchQuotes')
    dispatch(
      fetchQuotes(companies, {
        sum_insured: ownCover
          ? `${ownCover}-${ownCover}`
          : sumAssured
            ? covers.filter(cover => cover.display_name === sumAssured)[0]?.code
            : cover,
        tenure: multiYear ? multiYear[0] : tenure,
        member: selectedGroup,
        plan_type:
          memberGroups[selectedGroup].length === 1
            ? "I"
            : proposerDetails.plan_type
              ? proposerDetails.plan_type === "M"
                ? "M"
                : "F"
              : "F",
        // plan_type: planType
        //   ? plantypes.filter(plan => plan.display_name === planType)[0].code
        //   : plan_type,
      }),
    );
    initRef.current = false;
  }, [selectedGroup]);

  const history = useHistory();

  const urlQueryStrings = useUrlQuery();

  const enquiryId = urlQueryStrings.get("enquiryId");

  if (!Object.keys(memberGroups)) return <div>Loading...</div>;

  return (
    <div
      className="shrt-menu"
      css={`
        background: ${toggleUi && "#ebecf2;"} 
        @media (max-width: 767px) {
          display: none;
        }
      `}
    >
      <Row
        //  style={{ width: "100%", margin: 0, paddingLeft: "39px" }}
        className="top-header top_nav_bg_n d-lg-flex justify-content-between align-items-center"
        css={`
          max-width:1200px;
          margin: 0px auto;
          padding-left: 39px;

          background: ${!toggleUi && "unset;"} ;

          @media (max-width: 1023px) {
            display: none !important;
          }
        `}
      >
        <Col md={8} className=" no_padding"

        >
          <div className="button_bg">
            <Row>
              <Col md={2} className=" no_padding"
                css={`
              position: relative;
              left:-20px;
              `}
              >
                <span className="plan_for" style={{ marginTop: "12px" }}>
                  <span></span> Plans For
                </span>
              </Col>
              <Col md={11} style={{ paddingLeft: "30px" }}

              >
                <div
                  // style={{ justifyContent: "space-evenly" }}
                  className="multi-button"
                  css={`
                overflow-x:${Object.keys(memberGroups).length > 3 ? "scroll !important;" : "hidden"};
                `}
                >
                  {/* <div>
                    <button
                      style={{ width: "100%" }}
                      className={planFor === 0 ? "active" : ""}
                      onClick={() => setPlanFor(0)}
                    >
                      <span
                        style={{ fontSize: "1vw" }}
                        className="flaot_left_button fixed_font_to_16"
                      >
                        Self, Wife and Kids
                      </span>
                      <span
                        style={{ fontSize: "1vw" }}
                        className="flaot_right_button fixed_font_to_16"
                      >
                        9 Plans
                      </span>
                    </button>
                  </div>
                  <div>
                    <button
                      style={{ width: "100%" }}
                      className={planFor === 1 ? "active" : ""}
                      onClick={() => setPlanFor(1)}
                    >
                      <span
                        style={{ fontSize: "1vw" }}
                        className="flaot_left_button fixed_font_to_16"
                      >
                        Father & Mother Plans
                      </span>
                      <span
                        style={{ fontSize: "1vw" }}
                        className="flaot_right_button fixed_font_to_16"
                      >
                        5 Plans
                      </span>
                    </button>
                  </div>
                  <div>
                    <button
                      style={{ width: "100%" }}
                      className={planFor === 2 ? "active" : ""}
                      onClick={() => setPlanFor(2)}
                    >
                      <span
                        style={{ fontSize: "1vw" }}
                        className="flaot_left_button fixed_font_to_16"
                      >
                        All Members
                      </span>
                      <span
                        style={{ fontSize: "1vw" }}
                        className="flaot_right_button fixed_font_to_16"
                      >
                        1 Plan
                      </span>
                    </button>
                  </div> */}
                  {Object.keys(memberGroups)
                    .sort()
                    .map(group => (
                      <PlanForTab
                        key={group}
                        members={memberGroups[group]}
                        active={selectedGroup === group}
                        handleClick={() => {
                          history.push({
                            pathname: `/quotes/${group}`,
                            search: `enquiryId=${enquiryId}`,
                          });
                          dispatch(setSelectedGroup(group));
                          // getQuotes(members.filter((m) => m.group === group));
                        }}
                      />
                    ))}
                </div>
              </Col>
            </Row>
          </div>
        </Col>
        <Col md={4} css={"    display: flex;    justify-content: flex-end;"}>
          <ArogyaDropDown />
          <EditMembersButton />
        </Col>
      </Row>
    </div>
  );
};

export default QuotePlanFor;

function ArogyaDropDown() {
  const [openArogyaDropdown, setOpenArogyaDropdown] = useState(false);
  const arogyaDropDownRef = useRef(null);
  useOutsiteClick(arogyaDropDownRef, () => setOpenArogyaDropdown(false));

  const {
    basePlanType,
    multiYear: tenure,
    cover,
    planType,
  } = useSelector(state => state.quotePage.filters);

  const { groupCode } = useParams();

  const dispatch = useDispatch();

  const {
    baseplantypes: basePlanTypes,
    companies,
    covers,
    plantypes,
  } = useSelector(state => state.frontendBoot.frontendData.data);

  const sum_insured = covers.find(cov => cov.code === cover);

  const pt = plantypes.find(p => p.display_name === planType);

  const sendPlanType = pt ? pt.code : "F";

  const sendCover = sum_insured ? sum_insured.code : "";

  const handleClick = evt => {
    if (basePlanType !== evt.target.value) {
      dispatch(setFilters({ basePlanType: evt.target.value }));
      console.log('plans for 2 fetchQuotes')
      dispatch(
        fetchQuotes(companies, {
          sum_insured: sendCover,
          tenure: parseInt(tenure),
          member: groupCode,
          plan_type: sendPlanType,
          basePlanType: evt.target.id,
        }),
      );
    }
    setOpenArogyaDropdown(false);
  };

  function 
  PlanTypeCheckbox({ value, onChange, label, isSelected }) {
    return (
      <div>
        <input
          checked={isSelected}
          onChange={onChange}
          value={label}
          type="checkbox"
          name="dropdown-group"
          className="check checkbox-custom"
          id={value + "t"}
        />
        <label
          htmlFor={value + "t"}
          css={`
            display: flex;
            align-items: center;
            justify-content: space-between;
            margin-bottom: 20px;
            cursor: pointer;
          `}
        >
          {label}
          <div
            css={`
              height: 20px;
              width: 20px;
              border: 1px solid;
              border-radius: 50%;
              border-color: ${isSelected ? "var(--abc-red)" : "#d2d8e2"};
              display: flex !important;
              align-items: center;
              justify-content: center;

              &::after {
                display: ${isSelected ? "block" : "none"};
                content: "";
                height: 12px;
                width: 12px;
                background-color: var(--abc-red);
                border-radius: 50%;
              }
            `}
          />
        </label>
      </div>
    );
  }

  return (
    <Col md={6} className="no_padding" ref={arogyaDropDownRef}>
      <div
        style={{ margin: 0, width: "100%" }}
        className={`dropdown ${openArogyaDropdown ? "open" : ""}`}
      >
        <label
          onClick={() => {
            setOpenArogyaDropdown(!openArogyaDropdown);
          }}
          style={{ margin: 0, fontSize: "1vw" }}
          className="dropdown-label fixed_font_to_16"
        >
          {basePlanType}
        </label>

        <div
          style={{ width: "100%", paddingBottom: 0 }}
          className="dropdown-list"
        >
          {basePlanTypes.map(thisBasePlanType =>
            thisBasePlanType.code !== "arogya_sanjeevani" ? (
              <PlanTypeCheckbox
                key={thisBasePlanType.code}
                value={thisBasePlanType.code}
                label={thisBasePlanType.display_name}
                onChange={handleClick}
                isSelected={basePlanType === thisBasePlanType.display_name}
              />
            ) : null,
          )}
        </div>
      </div>
    </Col>
  );
}

function EditMembersButton() {
  const [openEditSidebar, setOpenEditSidebar] = useState(false);
  return (
    <Col md={6} style={{ maxWidth: 175 }} className="no_padding">
      <span
        onClick={() => setOpenEditSidebar(true)}
        className="badge clr_blue_bold menu-button sidebar-menu-open edit_memmbers_btn fixed_font_to_16"
        style={{
          fontSize: "1vw",
          width: "100%",
          marginLeft: 10,
          fontWeight: "900",
        }}
      >
        Edit Members
        <img src={edit} className="edit_member_icon" alt="edit-members" />
      </span>
      {openEditSidebar && (
        <EditMembersPopup handleClose={() => setOpenEditSidebar(false)} />
      )}
    </Col>
  );
  // return (
  //   <Col md={2} style={{ maxWidth: 175 }} className="no_padding">
  //     <span
  //       onClick={() => setOpenEditSidebar(true)}
  //       className="badge clr_blue_bold menu-button sidebar-menu-open edit_memmbers_btn fixed_font_to_16"
  //       style={{
  //         fontSize: "1vw",
  //         width: "100%",
  //         marginLeft: 10,
  //         fontWeight: "normal",
  //       }}
  //     >
  //       Edit Members
  //       <img src={edit} className="edit_member_icon" alt="edit-members" />
  //     </span>
  //     {openEditSidebar && (
  //       <div
  //         style={{ width: "610px" }}
  //         id="sidebar-menu"
  //         className={`eCommerce-side-menu ${
  //           openEditSidebar ? "show-menu" : ""
  //         }`}
  //       >
  //         <EditMembers setOpenEditSidebar={setOpenEditSidebar} />
  //       </div>
  //     )}
  //   </Col>
  // );
}
