import httpClient from "../../api/httpClient";

export const searchNetworkHospital = ({ hospitalName, city }) =>
  httpClient(
    `recommendation-network-hospitals?hospital=${hospitalName}&city=${city}`,
    // `recommendation-network-hospitals-pincode?pincode=${pincode}`,
    {
      method: "GET",
    },
  );

export const getRecommendationQuestions = ({ groupCode }) =>
  httpClient(`recommendation-question?group=${groupCode}`, { method: "GET" });

export const getRecommendedQuotesApi = ({ groupCode, answers }) =>
  httpClient(`recommend-plan?group=${groupCode}`, {
    method: "PATCH",
    data: answers,
  });

export const getRecommendedQuotes = ({ groupCode }) =>
  httpClient(`recommendation-quotes?group=${groupCode}`, { method: "GET" });

export const getRecommendData = () => httpClient(`recommend-plan`);

export const getCityList = () => httpClient(`recommendation-network-hospital-city`)