import React from "react";
import EligibilityCard from "./EligibilityCard/EligibilityCard";
import EligibilitySideCard from "./EligibilitySideCard";
import { Col, Row } from "react-bootstrap";
const EligibilityPage = () => {
  return (
    <div className="container-fluid mt-20 pb-100">
      <div className="element-section mb-150">
        <p className="go_back_prposal_p">
          <i className="icon flaticon-back" style={{ width: "27px" }}></i>
          Go back
        </p>
        <div className="element-tile-two">
          Kindly help us know more with the following details about Insured
          persons
        </div>
        <div className="row margin_top_tab_proposal">
          <div className="col-lg-9 aos-init aos-animate eligibilty_card_oops">
            <EligibilityCard />
          </div>
          <div className="col-lg-2 col-md-3 col-sm-8 col-12 box-shadow_plan_box_p_s_s_proposal_form_grey aos-init aos-animate">
            <EligibilitySideCard />
          </div>
        </div>
      </div>
    </div>
  );
};

export default EligibilityPage;
