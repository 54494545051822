import React, { useState } from "react";
import "./EligibilitySideCard.scss";
import cong from "../../assets/images/cong.png";
import oops from "../../assets/images/oops.png";

const EligibilitySideCard = ({}) => {
  const [eligible, setEligible] = useState(true);
  return (
    <>
      <div className="row">
        <div className="col-md-12">
          <img src={eligible ? cong : oops} className="img_oops" />
          <p className="font_17 plan_right_member_e_c_proposal_form_g">
            {" "}
            <span className="span_proposal_form_i_g">
              {eligible ? "Congratulation!" : "Oops!"}
            </span>
          </p>
          <p className="plan_right_member_e_c_proposal_form_g mb-15">
            {eligible
              ? " You have met the above conditions for being clearly being healthy,you may proceed!"
              : "Unfortunately based on your declaration you will not be able to proceed further with the policy due to your medical history. However you can Continue to buy policy for Other Members."}
          </p>
          <a
            className="btn btn_start_proposal_el"
            style={{
              marginTop: "10px",
              marginBottom: "24px",
            }}
          >
            Continue <i className="flaticon-next"></i>
          </a>

          {!eligible && (
            <a
              //  href="proposal_summary.html"
              class="btn btn_start_proposal_el_go"
            >
              <i class="flaticon-back" aria-hidden="true"></i> Go Back To Quote
              Page
            </a>
          )}
        </div>
      </div>
    </>
  );
};

export default EligibilitySideCard;
