import { useSelector } from "react-redux";
import { selectCompany } from "../../../../FrontendBoot/reducer/frontendBoot.slice";
import { amount } from "../../../../utils/helper";
import SpeedoMeterIcon from "../../../../assets/images/speedometer.png";
import { useRef, useState } from "react";
import { useEffect } from "react";
import { RecommendDetails } from "../";
import { Accordion } from "react-bootstrap";
import "styled-components/macro";
import { useCartProduct } from "../../../Cart";
import { useParams } from "react-router";
import BuyNowModal from "../../../QuotesPage/components/BuyNowModal/BuyNowModal";
import * as mq from "../../../../utils/mediaQueries";
import ScoreMeter from "../../../../assets/svg/ScoreMeter";
import SeeDetails from "../../../SeeDetails/SeeDetails";
import { ScoreSvgs } from "../ScoreSvg/ScoreSvg";
import CheckGreen from "../../../../assets/images/check_green.png";

function RecommendedQuoteCard({
  recommendedQuote,
  handleRecommendDetails,
  recommendDetails,
}) {
  const {
    total_premium,
    gross_premium,
    sum_insured,
    score,
    total_score,
    important_features,
    logos,
    product: {
      name,
      company: { alias },
    },
  } = recommendedQuote;

  const { groupCode } = useParams();

  const { addProduct, isCartProductLoading } = useCartProduct(groupCode);

  const recommendedQuoteCardRef = useRef(null);
  const recommendedQuoteCardMobileRef = useRef(null);

  const [showSeeDetails, setShowSeeDetails] = useState(false);
  const [showProductDetails, setShowProductDetails] = useState(false);

  const handleSeeDetailsClick = () => {
    setShowSeeDetails(!showSeeDetails);
    handleRecommendDetails(!showSeeDetails ? recommendedQuote : null);
  };

  useEffect(() => {
    const currentCard = recommendDetails
      ? recommendDetails.product.id === recommendedQuote.product.id
      : false;
    setShowSeeDetails(currentCard);

    if (currentCard && recommendedQuoteCardRef.current) {
      setTimeout(() => {
        recommendedQuoteCardRef.current.scrollIntoView({ behavior: "smooth" });
        if (recommendedQuoteCardMobileRef.current) {
          recommendedQuoteCardMobileRef.current.scrollIntoView({
            behavior: "smooth",
          });
        }
      }, 350);
    }
  }, [recommendDetails, recommendedQuote.product.id]);

  const [showCart, setShowCart] = useState(false);

  const handleBuyNow = recommendedQuote => {
    const {
      total_premium,
      gross_premium,
      premium,
      sum_insured,
      tax_amount,
      tenure,
      product: { id: product_id, name },
    } = recommendedQuote;
    addProduct({
      total_premium: total_premium || gross_premium,
      sum_insured,
      tax_amount,
      tenure,
      product_id,
      premium,
      group_id: parseInt(groupCode),
      service_tax: tax_amount,
      product: { id: product_id, name },
    }).then(status => {
      if (!status) {
        alert("Error buying quote!");
        return;
      }
      setShowCart(true);
    });
  };

  const company = useSelector(selectCompany(alias));
  const logoSrc = company?.logo;
  const handleBuyNowClick = () => {
    handleBuyNow(recommendedQuote);
  };

  return (
    <>
      {showCart ? (
        <BuyNowModal showBuyNow={showCart} setShowBuyNow={setShowCart} />
      ) : null}
      <div
        css={`
          ${mq.mobile} {
            display: none;
          }
        `}
      >
        <div
          ref={recommendedQuoteCardRef}
          css={`
            /* box-shadow: 0px 20px 70px 0px rgb(4 7 18 / 6%); */
            box-shadow: 0px 10px 20px rgb(134 156 213 / 25%);
            padding: 18px 30px;
            border-radius: 16px;
            background: #fff;
            max-width: 1000px;
            margin: 0 auto;
            margin-top: 30px;
            display: flex;
            position: relative;
            margin-bottom: 3em;

            ${mq.mobile} {
              /* padding: 15px; */
              border: 1px solid;
              border-radius: 10px;
              padding: 0;
            }
          `}
        >
          <Accordion.Toggle
            onClick={handleSeeDetailsClick}
            eventKey={recommendedQuote.product.id}
            css={`
              position: absolute;
              top: 100%;
              left: 50%;
              transform: translate(-50%);
              background: #efefef;
              padding: 0.2em 1em;
              border-radius: 0 0 1.6em 1.6em;
              display: flex;
              align-items: center;
              font-weight: 900;
            `}
          >
            {showSeeDetails ? "Hide" : "Show"} Details
            <div
              css={`
                background-color: var(--abc-red);
                border-radius: 50%;
                height: 20px;
                width: 20px;
                margin-left: 0.6em;
              `}
            >
              <i
                className="fa fa-angle-down"
                css={`
                  color: #fff;
                  transform: rotate(${showSeeDetails ? "180deg" : "0"});
                `}
              />
            </div>
          </Accordion.Toggle>
          <div
            css={`
              flex: 1;
              display: flex;
              flex-direction: column;
              justify-content: space-between;
            `}
          >
            <div
              css={`
                display: flex;
                align-items: center;
                flex: 1;
              `}
            >
              <div
                css={`
                  width: 80px;
                  margin-right: 1rem;

                  ${mq.mobile} {
                    width: 3em;
                    margin-right: 0em;
                  }

                  /* ${mq.mobile} {
                    width: 47px;
                    margin-right: 10px;
                  }

                  ${mq.small} {
                    width: 30px;
                    margin-right: 7px;
                  } */
                `}
              >
                <img src={logoSrc} alt="" />
              </div>
              <p
                css={`
                  font-size: 18px;
                  ${mq.mobile} {
                    font-size: 21px;
                  }
                  ${mq.small} {
                    font-size: 16px;
                  }
                `}
              >
                {name}
              </p>
            </div>
            <div
              css={`
                display: flex;
              `}
            >
              <div
                css={`
                  width: 66px;
                  display: flex;
                  align-items: center;
                  justify-content: center;
                  margin-bottom: -16px;
                `}
              >
                <img
                  src={ScoreSvgs[Math.floor(score)]}
                  alt="score"
                  css={`
                    width: 100%;
                  `}
                />
              </div>
              <p
                css={`
                  font-size: 34px;
                  line-height: normal;
                  margin-left: 1rem;
                  /* margin-top: 6px; */
                  width: 120px;

                  ${mq.mobile} {
                    margin-left: 3px;
                    font-size: 30px;
                  }

                  ${mq.small} {
                    font-size: 27px;
                  }
                `}
              >
                {score} / {total_score}
              </p>
            </div>
          </div>
          <div
            css={`
              flex: 2;
              border-left: 1px solid #ddd;
              border-right: 1px solid #ddd;

              ${mq.mobile} {
                display: none;
              }
            `}
          >
            {Object.keys(important_features)
              .filter(ife => {
                const logoAvailable = logos.some(
                  logo => logo.feature_code === ife,
                );
                return (
                  important_features[ife].pro_con === "pro" && logoAvailable
                );
              })
              .slice(0, 4)
              .map(feature => (
                <p
                  css={`
                    display: flex;
                    align-items: center;
                    margin-bottom: 1px;
                  `}
                >
                  <img
                    src={CheckGreen}
                    alt={feature.feature_name}
                    css={`
                      width: 1em;
                      height: 1em;
                      margin: 0 1em;
                    `}
                  />
                  {important_features[feature].brief}
                </p>
              ))}
          </div>
          <div
            css={`
              flex: 1;
              display: flex;
              flex-direction: column;
              align-items: center;
              justify-content: space-between;
            `}
          >
            <button
              css={`
                background: #efefef;
                padding: 0.2em 1em;
                border-radius: 30px;
                font-weight: 900;

                ${mq.mobile} {
                  font-size: 12px;
                }
              `}
              onClick={() => setShowProductDetails(true)}
            >
              Product Details
              <i
                css={`
                  margin-left: 0.3em;
                  color: var(--abc-red);
                  font-weight: 900;
                `}
                className="fa fa-angle-right"
              />
            </button>
            <p
              css={`
                font-weight: normal;
                margin-top: 0.3em;
                font-weight: 900;

                ${mq.mobile} {
                  display: none;
                }
              `}
            >
              Cover: <span>{amount(sum_insured)}</span>
            </p>
            <button
              css={`
                background-color: var(--abc-red);
                width: 120px;
                padding: 6px;
                color: #fff;
                border-radius: 2px;
                margin-top: 0.3em;
                border-radius: 3px;
                font-weight: 900;

                ${mq.mobile} {
                  width: 70px;
                  font-size: 12px;
                }
              `}
              onClick={handleBuyNowClick}
            >
              {amount(total_premium || gross_premium)}{" "}
              <span
                css={`
                  ${mq.mobile} {
                    display: none;
                  }
                `}
              >
                / year
              </span>
            </button>
          </div>
        </div>
        <Accordion.Collapse eventKey={recommendedQuote.product.id}>
          <RecommendDetails recommendedQuote={recommendedQuote} />
        </Accordion.Collapse>
      </div>
      {/* MOBILE QUOTE CARD */}
      <div
        css={`
          display: none;
          ${mq.mobile} {
            display: block;
          }
        `}
      >
        <div
          css={`
            font-size: min(calc(10 * 100vw / 360), 12px);
            box-shadow: 0px 10px 20px rgb(134 156 213 / 25%);
            border-radius: 0.6em;
            padding: 1em;
            margin-bottom: 3.3em;
            position: relative;
            background-color: #fff;
          `}
          ref={recommendedQuoteCardMobileRef}
        >
          <Accordion.Toggle
            onClick={handleSeeDetailsClick}
            eventKey={recommendedQuote.product.id}
            css={`
              position: absolute;
              top: 100%;
              left: 50%;
              transform: translate(-50%);
              background: #efefef;
              padding: 0.2em 1em;
              border-radius: 0 0 1.6em 1.6em;
              display: flex;
              align-items: center;
              font-weight: 900;
              font-size: 1.2em;
            `}
          >
            {showSeeDetails ? "Hide" : "Show"} Details
            <div
              css={`
                background-color: var(--abc-red);
                border-radius: 50%;
                height: 1.6em;
                width: 1.6em;
                margin-left: 0.6em;
                display: flex;
                align-items: center;
                justify-content: center;
              `}
            >
              <i
                className="fa fa-angle-down"
                css={`
                  color: #fff;
                  font-size: 1.2em;
                  transform: rotate(${showSeeDetails ? "180deg" : "0"});
                `}
              />
            </div>
          </Accordion.Toggle>
          <div
            css={`
              display: flex;
              justify-content: space-between;
              align-items: center;
            `}
          >
            <div
              css={`
                display: flex;
                align-items: center;
              `}
            >
              <div
                css={`
                  width: 6em;
                `}
              >
                <img
                  src={logoSrc}
                  alt={alias}
                  css={`
                    max-width: 100%;
                  `}
                />
              </div>
              <div
                css={`
                  font-size: 1.6em;
                `}
              >
                {name}
              </div>
            </div>
            <button
              css={`
                background: #efefef;
                font-size: 1.37em;
                padding: 0.2em 0.7em;
                border-radius: 30px;
                font-weight: 900;
              `}
              onClick={() => setShowProductDetails(true)}
            >
              Product Details
              <i
                css={`
                  margin-left: 0.3em;
                  color: var(--abc-red);
                  font-weight: 900;
                `}
                className="fa fa-angle-right"
              />
            </button>
          </div>
          <div
            css={`
              display: flex;
              align-items: center;
              justify-content: space-between;
              margin: 1em 0;
              border-bottom: 1px solid #ddd;
              font-size: inherit;
            `}
          >
            <div
              css={`
                display: flex;
                align-items: center;
              `}
            >
              <div
                css={`
                  display: flex;
                  align-items: center;
                `}
              >
                <img
                  src={ScoreSvgs[Math.floor(score)]}
                  alt="score"
                  css={`
                    width: 4.97em;
                    margin-top: 1em;
                  `}
                />
                <div
                  css={`
                    font-size: 2em;
                    margin-left: 0.6em;
                    font-weight: 900;
                  `}
                >
                  {score}/ {total_score}
                </div>
              </div>
              <div
                css={`
                  border-left: 1px solid #ddd;
                  padding-left: 1em;
                  margin-left: 1em;
                `}
              >
                <div
                  css={`
                    font-size: 1.37em;
                  `}
                >
                  Cover:
                </div>
                <div
                  css={`
                    font-size: 1.6em;
                    font-weight: 900;
                  `}
                >
                  {amount(sum_insured)}
                </div>
              </div>
            </div>
            <button
              css={`
                font-size: 1.6em;
                font-weight: 900;
                background-color: var(--abc-red);
                color: #fff;
                padding: 0.7em;
                border-radius: 0.2em;
                line-height: 1;
              `}
            >
              {amount(total_premium || gross_premium)}
            </button>
          </div>
          <div
            css={`
              display: flex;
              align-items: center;
              justify-content: space-between;
              flex-wrap: wrap;
            `}
          >
            {Object.keys(important_features)
              .filter(ife => {
                const logoAvailable = logos.some(
                  logo => logo.feature_code === ife,
                );
                return (
                  important_features[ife].pro_con === "pro" && logoAvailable
                );
              })
              .slice(0, 4)
              .map(feature => (
                <div
                  css={`
                    display: flex;
                    align-items: center;
                    flex: 0 1 50%;
                  `}
                >
                  <img
                    src={CheckGreen}
                    alt={feature.feature_name}
                    css={`
                      width: 1.2em;
                      height: 1.2em;
                      margin-right: 1em;
                    `}
                  />
                  <div
                    css={`
                      font-size: 1.2em;
                    `}
                  >
                    {important_features[feature].brief}
                  </div>
                </div>
              ))}
          </div>
        </div>
        <Accordion.Collapse eventKey={recommendedQuote.product.id}>
          <RecommendDetails recommendedQuote={recommendedQuote} />
        </Accordion.Collapse>
      </div>
      {showProductDetails && (
        <SeeDetails
          show={showProductDetails}
          handleClose={() => setShowProductDetails(false)}
          quote={{
            ...recommendedQuote,
            tenure: [recommendedQuote.tenure],
            sum_insured: [sum_insured],
            total_premium: [total_premium || gross_premium],
          }}
          sum_insured={sum_insured}
          tenure={recommendedQuote.tenure}
          product={recommendedQuote.product}
        />
      )}
    </>
  );

  return (
    <>
      {showCart ? (
        <BuyNowModal showBuyNow={showCart} setShowBuyNow={setShowCart} />
      ) : null}
      <div ref={recommendedQuoteCardRef}>
        <div
          css={`
            /* box-shadow: 0px 20px 70px 0px rgb(4 7 18 / 6%); */
            box-shadow: 0px 10px 20px rgb(134 156 213 / 25%);
            padding: 18px 30px;
            border-radius: 16px;
            background: #fff;
            max-width: 1000px;
            margin: 0 auto;
            margin-top: 30px;

            ${mq.mobile} {
              padding: 15px;
            }
          `}
        >
          <div
            css={`
              border-bottom: 1px solid #ddd;
              display: flex;
              align-items: center;
              justify-content: space-between;
              padding-bottom: 1rem;
              margin-bottom: 1rem;

              ${mq.mobile} {
                padding-bottom: 10px;
                margin-bottom: 10px;
              }
            `}
          >
            <div
              css={`
                display: flex;
                align-items: center;
                flex: 1;
              `}
            >
              <div
                css={`
                  width: 80px;
                  margin-right: 1rem;

                  ${mq.mobile} {
                    width: 47px;
                    margin-right: 10px;
                  }

                  ${mq.small} {
                    width: 30px;
                    margin-right: 7px;
                  }
                `}
              >
                <img src={logoSrc} alt="" />
              </div>
              <p
                css={`
                  font-size: 25px;
                  ${mq.mobile} {
                    font-size: 21px;
                  }
                  ${mq.small} {
                    font-size: 16px;
                  }
                `}
              >
                {name}
              </p>
            </div>
            <div
              css={`
                width: 30%;
                display: flex;
                justify-content: center;
                flex: 1;

                ${mq.mobile} {
                  display: none;
                }
              `}
            >
              <Detail label="Cover" value={amount(sum_insured)} />
              <Detail
                label="Premium"
                value={`${amount(total_premium || gross_premium)} / year`}
              />
            </div>
            <div
              css={`
                flex: 1;
              `}
            >
              <button
                css={`
                  background-color: var(--abc-red);
                  width: 189px;
                  padding: 12px 11px;
                  color: #fff;
                  border-radius: 2px;
                  margin-left: auto;

                  ${mq.mobile} {
                    width: 120px;
                    font-size: 16px;
                  }

                  ${mq.small} {
                    width: 80px;
                    padding: 6px;
                  }
                `}
                onClick={handleBuyNowClick}
              >
                Buy Now
                {isCartProductLoading ? (
                  <i
                    className="fa fa-circle-notch rotate"
                    css={`
                      margin-left: 1rem;
                    `}
                  />
                ) : null}
              </button>
            </div>
          </div>
          <div
            css={`
              display: flex;
              justify-content: center;
              flex: 1;
              display: none;

              ${mq.mobile} {
                display: flex;
              }
            `}
          >
            <Detail label="Cover" value={amount(sum_insured)} />
            <Detail
              label="Premium"
              value={`${amount(total_premium || gross_premium)} / year`}
            />
          </div>
          <div
            css={`
              display: flex;
              align-items: center;
              justify-content: space-between;
              margin-top: 10px;
            `}
          >
            <div
              css={`
                display: flex;
                align-items: center;
                flex: 1;

                ${mq.mobile} {
                  flex: 0 auto;
                }
              `}
            >
              <div
                css={`
                  width: 66px;
                  display: flex;
                  align-items: center;
                  justify-content: center;
                `}
              >
                <img
                  src={ScoreSvgs[Math.floor(score)]}
                  alt="score"
                  css={`
                    width: 100%;
                  `}
                />
              </div>
              <p
                css={`
                  font-size: 34px;
                  line-height: normal;
                  margin-left: 1rem;
                  /* margin-top: 6px; */
                  width: 120px;

                  ${mq.mobile} {
                    margin-left: 3px;
                    font-size: 30px;
                  }

                  ${mq.small} {
                    font-size: 27px;
                  }
                `}
              >
                {score} / {total_score}
              </p>
              <Accordion.Toggle
                css={`
                  display: flex;
                  align-items: center;
                  margin: auto;
                  margin-left: 2rem;

                  ${mq.mobile} {
                    font-weight: 900;
                  }

                  ${mq.small} {
                    font-size: 14px;
                  }
                `}
                onClick={handleSeeDetailsClick}
                eventKey={recommendedQuote.product.id}
              >
                See Details
                <AngleRight showSeeDetails={showSeeDetails} />
              </Accordion.Toggle>
            </div>
            <button
              css={`
                display: flex;
                align-items: center;
              `}
              onClick={() => setShowProductDetails(true)}
            >
              Product Details <AngleRight />
            </button>
          </div>
        </div>
        <Accordion.Collapse eventKey={recommendedQuote.product.id}>
          <RecommendDetails recommendedQuote={recommendedQuote} />
        </Accordion.Collapse>
      </div>
      {showProductDetails && (
        <SeeDetails
          show={showProductDetails}
          handleClose={() => setShowProductDetails(false)}
          quote={{
            ...recommendedQuote,
            tenure: [recommendedQuote.tenure],
            sum_insured: [sum_insured],
            total_premium: [total_premium || gross_premium],
          }}
          sum_insured={sum_insured}
          tenure={recommendedQuote.tenure}
          product={recommendedQuote.product}
        />
      )}
    </>
  );
}

function Detail({ label, value }) {
  return (
    <div
      css={`
        flex: 1;
        display: flex;
        justify-content: center;
        font-size: 18px;
        font-weight: 900;

        &:not(:last-child) {
          border-right: 1px solid #ddd;
        }

        ${mq.mobile} {
          font-size: 16px;
        }
      `}
    >
      <div>
        <div
          css={`
            font-weight: normal;
          `}
        >
          {label}
        </div>
        <div
          css={`
            color: var(--abc-red);
          `}
        >
          {value}
        </div>
      </div>
    </div>
  );
}

export default RecommendedQuoteCard;

function AngleRight({ showSeeDetails }) {
  return (
    <div
      css={`
        background-color: var(--abc-red);
        display: flex;
        align-items: center;
        justify-content: center;
        color: #fff;
        border-radius: 50%;
        height: 30px;
        width: 30px;
        padding: 10px;
        font-size: 20px;
        margin-left: 1rem;
        padding-left: ${showSeeDetails ? "11px" : "12px"};
        padding-top: ${showSeeDetails ? "12px" : "9px"};

        ${mq.small} {
          height: 26px;
          width: 26px;
          font-size: 18px;
        }
      `}
    >
      <i
        className="fa fa-angle-right"
        css={`
          transform: rotate(${showSeeDetails ? "90deg" : "0deg"});
        `}
      />
    </div>
  );
}
