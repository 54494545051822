import styled from "styled-components/macro";
import { Col, OverlayTrigger, Tooltip } from "react-bootstrap";
import {
  copay,
  roomrent,
  cashless,
  waiting,
  renewal,
} from "../../../../../assets/index";
import arrow from "../../../../../assets/images/back.png";

export const RadioInput = styled.input`
  & label:before {
    content: "";
    width: 20px;
    height: 20px;
    line-height: 19px;
    border-radius: 50px;
    border: 1px solid #ecc6c8;
    font-size: 11px;
    text-align: center;
    position: absolute;
    left: 0;
    top: -9px;
    transition: all 0.1s ease-in-out;
  }
  &:checked + label:before {
    content: "";
    font-family: "font-awesome";
    background: #c72229;
    color: #fff;
    border-color: #c72229;
  }
`;
export const RadioLabel = styled.label`
  position: relative;
  font-size: 17px;
  font-weight: 900;
  color: #000;
  cursor: pointer;
  padding-left: 30px;
  /* margin: 30px 0 35px; */
  transition: all 0.1s ease-in-out;
  // font-family: PFEncoreSansProblck;
  top: -2px;
  margin-top: 26px;
  &:before {
    content: "";
    width: 20px;
    height: 20px;
    line-height: 19px;
    border-radius: 2px;
    border: 1px solid #ecc6c8;
    font-size: 11px;
    text-align: center;
    position: absolute;
    left: 0;
    top: -9px;
    transition: all 0.1s ease-in-out;
  }
  @media (max-width: 1023px) {
    left: 33px;
    padding: unset !important;
    &:before {
      left: -28px !important;
    }
  }
`;

export const ICName = styled.p`
  //	white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;

  ${props =>
    props.morePlan
      ? "   position: absolute; height: 62px;  display: flex; flex-direction: column;  justify-content: center;   align-content: flex-end; white-space: unset;  padding-top: 9px !important;font-size: 15px !important; font-weight: 900;line-height: 20px !important; width: 100%; position: absolute; "
      : "white-space: unset;font-size: 15px !important;  margin-left: -8px !important; width: 80%; font-weight: 900; text-align: left; line-height: 20px !important; margin: 5px -23px 0;  "}
`;

export const ProductCover = styled.div`
  &:after {
    content: "";
    position: absolute;
    top: -5px;
    left: 21px;
    z-index: -8;
    width: calc(100% - 12px);
    height: calc(100% - 12px);
    border-right: 1px solid #ccc;
    @media (max-width: 1023px) {
      left: 19px;
    }
  }
  width: 149px;
  height: 46px;
`;

export const quoteCardDataset = (
  { name, icon, value, description },
  index,
  handleSeeDetailsClick,
) => {

  const renderTooltip = props => (
    <Tooltip
      css={`
        left: ${index !== 2 && "8px !important"};
        top: 2px !important;
      `}
      {...props}
    >
      {description}
    </Tooltip>
  );

  return (
    <Col md={4} key={index}>
      <a
        onClick={() => name === "Cashless Hospital" && handleSeeDetailsClick(4)}
      >
        <div
          className="border_box font_l_box mar_left_12"
          // style={{ border: index === 2 && "unset" }}
          style={{
            border:
              (name === "Pre Existing Disease" ||
                name === "Cashless Hospital") &&
              "unset",
          }}
        >
          <img
            src={icon}
            className="margin_img_inline"
            onError={<i className="fas fa-circle-notch rotate" />}
          />

          <span className="text-left font_feature_p_card">
            <OverlayTrigger
              placement={index === 2 ? "left" : "right"}
              overlay={renderTooltip}
            >
              <b>{name}</b>
            </OverlayTrigger>
            <span
              css={`
                ${name === "Cashless Hospital" &&
                `:after { content: ""; border: solid #253858;  border-width: 0 1.5px 1.5px 0;  display: inline-block;     padding: 2px;            top: -1px;  transform: rotate(-45deg); margin-left: 3px; vertical-align: middle; position: relative; }`}
              `}
              className="display_block mar_color_red"
            >
              {value}
            </span>
          </span>
        </div>
      </a>
    </Col>
  );
};

// import styled from "styled-components";
// import { Col, OverlayTrigger, Tooltip } from "react-bootstrap";
// import {
//   copay,
//   roomrent,
//   cashless,
//   waiting,
//   renewal,
// } from "../../../../../assets/index";

// export const RadioInput = styled.input`
//   & label:before {
//     content: "";
//     width: 20px;
//     height: 20px;
//     line-height: 19px;
//     border-radius: 50px;
//     border: 1px solid #ecc6c8;
//     font-size: 11px;
//     text-align: center;
//     position: absolute;
//     left: 0;
//     top: -9px;
//     transition: all 0.1s ease-in-out;
//   }
//   &:checked + label:before {
//     content: "";
//     font-family: "font-awesome";
//     background: #c72229;
//     color: #fff;
//     border-color: #c72229;
//   }
// `;
// export const RadioLabel = styled.label`
//   position: relative;
//   font-size: 18px;
//   font-weight: 900;
//   color: #000;
//   cursor: pointer;
//   padding-left: 30px;
//   /* margin: 30px 0 35px; */
//   transition: all 0.1s ease-in-out;
//   // font-family: PFEncoreSansProblck;
//   top: -2px;
//   margin-top: 26px;
//   &:before {
//     content: "";
//     width: 20px;
//     height: 20px;
//     line-height: 19px;
//     border-radius: 50px;
//     border: 1px solid #ecc6c8;
//     font-size: 11px;
//     text-align: center;
//     position: absolute;
//     left: 0;
//     top: -9px;
//     transition: all 0.1s ease-in-out;
//   }
//   @media (max-width: 1023px) {
//     left: 33px;
//     padding: unset !important;
//     &:before {
//       left: -28px !important;
//     }
//   }
// `;

// export const ICName = styled.p`
//   //	white-space: nowrap;
//   overflow: hidden;
//   text-overflow: ellipsis;

//   ${props =>
//     props.morePlan
//       ? "   position: absolute; height: 62px;  display: flex; flex-direction: column;  justify-content: center;   align-content: flex-end; white-space: unset;  padding-top: 9px !important;font-size: 15px !important; font-weight: 900;line-height: 20px !important; width: 100%; position: absolute; "
//       : "white-space: unset;font-size: 15px !important;  margin-left: -8px !important; width: 80%; font-weight: 900; text-align: left; line-height: 20px !important; margin: 5px -23px 0;  "}
// `;

// export const ProductCover = styled.div`
//   border-right: 1px solid #ccc;
//   width: 149px;
//   height: 46px;
// `;

// export const quoteCardDataset = ({ name, icon, value, description }, index) => {
//   const renderTooltip = props => <Tooltip {...props}>{description}</Tooltip>;

//   return (
//     <Col md={4} key={index}>
//       <a href="#">
//         <OverlayTrigger placement="right" overlay={renderTooltip}>
//           <div
//             className="border_box font_l_box mar_left_12"
//             style={{ border: name === "Cashless Hospital" && "unset" }}
//           >
//             <img
//               src={icon}
//               className="margin_img_inline"
//               onError={<i className="fas fa-circle-notch rotate" />}
//             />

//             <span className="text-left font_feature_p_card">
//               <b>{name}</b>
//               <span className="display_block mar_color_red">{value}</span>
//             </span>
//           </div>
//         </OverlayTrigger>
//       </a>
//     </Col>
//   );
// };
