import { useState } from "react";
import useUrlQuery from "../../customHooks/useUrlQuery";
import Fieldset1 from "./Pages/FieldsetData/Fieldset1";
import Fieldsets from "./Pages/Fieldsets";
import MainPage from "./Pages/MainPage";
import "./Scss/Recommended.scss";

const RecommendedPage = () => {
	const urlQueries = useUrlQuery();
	const qno = urlQueries.get("qno")
	const [currentForm, setCurrentForm] = useState(!qno ? "form1" : "form2");

	return (
		<span className="recommended">
			{currentForm === "form1" ? (
				<MainPage
					currentForm={currentForm}
					handleChange={(value) => setCurrentForm(value)}
					value={"form1"}
				/>
			) : (
				<div
					style={{
						position: "absolute",
						width: "100%",
						height: "100%",
						background:
							"linear-gradient(176deg, rgb(250, 250, 252) 0%, rgb(250, 250, 252) 60%, rgb(255, 245, 245) 100%)",
					}}
				>
					<Fieldsets />
				</div>
			)}
		</span>
	);
};

export default RecommendedPage;
