import styled from "styled-components";

export const SingleBlock = styled.div`
  position: relative;
  z-index: 1;

  border-radius: 12px;

  padding: 28px 0px 0px 0px;

  background-color: #fff;
  box-shadow: 0px 10px 20px rgb(134 156 213 / 25%);
  margin-bottom: -30px;
  margin-top: 28px;
  &:before {
    content: "";
    position: absolute;
    /* background: #fff; */
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    z-index: -1;
    border-radius: 7px;
    transform: scaleY(0);
    transform-origin: 50% 100%;
    transition: transform 0.4s ease-out;
  }
  &:hover {
    position: relative;
    z-index: 1;
    /* border: 1px solid rgba(250,250,250,0.15); */
    border-radius: 7px;
    /* padding: 22px 30px 23px 115px; */
    /* padding: 28px 0px 0px 0px; */
    /* margin-bottom: 40px; */
    /* background-color: #fbf8f1; */
    box-shadow: 0 2px 20px 5px #e2e3ed;
    /* margin-bottom: 20px; */
  }
  & .icon {
    position: absolute;
    top: 0px;
    left: 30px;
  }
  & p {
    line-height: 30px;
    padding: 2px 18px 10px;
    color: #202026;
    font-size: 12px;
    margin-bottom: 0px;
  }
`;
