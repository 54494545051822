import styled from "styled-components";

export const UnorderedList = styled.ul`
  list-style: none;
  position: relative;
  display: block;
  overflow: hidden;
  margin: 0;
  padding: 0;
  border: 0;
  outline: none;
  font-size: 13px;

  text-align: left;

  border-radius: 5px;

  height: 130px;
  overflow: hidden;

  & li {
    min-height: 130px;
    overflow: hidden;
    position: absolute;
    width: 100%;
    overflow: auto;
    padding: 8px 18px 2px;
    box-sizing: border-box;
    -moz-box-sizing: border-box;
    opacity: 0;
    transition: all 0.5s;
    -o-transition: all 0.5s;
    -ms-transition: all 0.5s;
    -moz-transition: all 0.5s;
    -webkit-transition: all 0.5s;
    -webkit-transform: scale(0.1, 0.1);
    @media (max-width: 767px) {
      overflow: unset;
    }
  }
  & .active {
    z-index: 1;
    top: 0;
    left: 0;
    opacity: 1;
    -webkit-transform: scale(1, 1);
    -webkit-transform: rotate(0deg);
  }

  & .typography2 {
    color: #000;
    text-align: left !important;
    margin-bottom: 10px;
    padding-bottom: 9px;
    padding-top: 4px;
    display: inline-block;
    font-size: 23px;
    font-weight: 900;
    line-height: 44px;
  }
  & .typography {
    color: #666;
    & h1 {
      font-size: 25px;
      line-height: 60px;
      /* text-shadow: 3px 3px rgb(0 0 0 / 10%); */
      font-weight: 900;
      margin: 0 27px 12px;
      color: #000 !important;
      @media (max-width: 767px) {
        font-size: 20px;
        line-height: 22px;
        margin-left: 32px;
        margin-bottom: 12px;
      }
    }

    & p {
      margin: -1px 0px 0 28px;
      font-weight: 100 !important;
      padding: 0;
      line-height: 20px;
      text-align: left;
      font-size: 16px;
      color: #3e3737;
      @media (max-width: 767px) {
        margin: -3px 0px 0 32px;
        padding: 0;
        line-height: 20px;
        text-align: left;
        font-size: 14px;
        color: #7b7b7b;
        width: 100%;
      }
    }
  }

  & .cbx__title {
    float: left;
    border: 1px solid #e8e9f6;
    margin: 0 12px;
    padding: 8px 15px 8px 18px !important;
    border-radius: 50px;
    box-shadow: 0 12px 12px -11px #004b8347;
    line-height: 21px;
    font-size: 16px !important;
  }
  & .cbx span:first-child {
    margin-top: 10px;
  }
  height: 130px;
  & .medical-radioButtons__column {
    min-width: max-content;
  }

  @media (max-width: 767px) {
    height: 203px;
  }
  // @media (max-width: 1300px) {
  // 	& .medical-radioButtons__column {
  // 		flex: 0 0 50%;
  // 		min-width: max-content;
  // 	}
  // }
`;
