import styled from "styled-components";

const TextInput = styled.input`
  outline: none;
  display: block;
  width: 94%;
  margin: 0 0 20px;
  padding: 21px 50px;
  border: ${props =>
    props.redBorder ? "2px solid red !important" : "2px solid #d9d9d9"};

  background-color: ${props => props.redBorder && "#fff6f7"};

  color: ${props => (props.redBorder ? "#c7222a !important" : "#837e7e")};

  border-radius: 2px;
  -webkti-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  font-size: 14px;
  font-weight: 400;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  -webkit-transition: all 0.3s linear 0s;
  -moz-transition: all 0.3s linear 0s;
  -ms-transition: all 0.3s linear 0s;
  -o-transition: all 0.3s linear 0s;
  transition: all 0.3s linear 0s;
  border-radius: 12px;
  border: 1.2px solid #d6d9e0;
  &:focus-within {
    color: #000;
  }
  @media (max-width: 767px) {
    width: 100% !important;
  }
`;

const InputLabel = styled.label`
  color: #000;
  text-align: left !important;
  font-size: 15px;
  font-weight: 200;
  padding-bottom: 9px;
  padding-top: 4px;
  display: inline-block;
  position: absolute;
  margin: -69px -55px 0 8px;
  font-size: 33px;
  font-weight: 900;
  line-height: 44px;

  //boldfont font-family: "PFEncoreSansProblck";
  @media (max-width: 767px) {
    top: 50px;
    font-size: 13px !important;
    left: 16px !important;
    font-family: "PFEncoreSansProblck";
    line-height: 0;
    height: 0px;
  }
`;

const ErrorMessage = styled.span`
  position: relative;
  margin-top: 1px;
  top: -20px;
  display: block;
  z-index: -1;
  color: #d43d3d;
  font-size: 17px;
  @media only screen and (max-width: 767px) {
    position: relative;
    padding-left: 0;
    top: -13px;
    font-size: 16px;
  }
`;

export { TextInput, InputLabel, ErrorMessage };
